import { Accordion, AccordionDetails, AccordionSummary, Grid, Hidden, Menu } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useState } from 'react';
import FilterImg from '../../../assets/img/filter.svg';
import ButtonGreyOutlined from './../../UI/Buttons/ButtonGreyOutlined/ButtonGreyOutlined';
import { useStyles } from './FiltersSelectStyle';

const FiltersSelect = props => {
	const classes = useStyles();
	const [filtersMenu, setFiltersMenu] = useState(null);

	const handleFiltersMenuClick = (event) => {
		setFiltersMenu(event.currentTarget);
	};
	
	const handleFiltersMenuClose = () => {
		setFiltersMenu(null);
	};

	return (<>
		<Hidden smDown>
			<Grid item xs={12}>
				<ButtonGreyOutlined aria-controls="filter-menu" aria-haspopup="true" onClick={handleFiltersMenuClick}>
					<img src={FilterImg} alt="Filters" />&nbsp;&nbsp;&nbsp;FILTERS
				</ButtonGreyOutlined>
				<Menu
					id="filter-menu"
					className={classes.filters}
					anchorEl={filtersMenu}
					open={Boolean(filtersMenu)}
					onClose={handleFiltersMenuClose}
					keepMounted={false}
					PaperProps={{ style: { transform: 'translateX(0) translateY(50px)' } }}
				>
					{props.children}	
				</Menu>
			</Grid>
		</Hidden>
		<Hidden mdUp>
			<Accordion className={classes.filtersAccordion}>
				<AccordionSummary
					expandIcon={<ExpandMoreIcon />}
					aria-controls="filter-menu"
					id="filter-menu"
				>
					<img src={FilterImg} alt="Filters" />&nbsp;&nbsp;&nbsp;FILTERS
				</AccordionSummary>
				<AccordionDetails>
					{props.children}
				</AccordionDetails>
			</Accordion>
		</Hidden>
	</>);
};

export default FiltersSelect;