import { Link } from "react-router-dom";
import ButtonPrimary from "../../UI/Buttons/ButtonPrimary/ButtonPrimary";
import TickInsideCircleImg from '../../../assets/img/tick-inside-circle.svg';
import { useStyles } from "./ConfirmBuyPackDialogContentStyle";
import ButtonYellow from './../../UI/Buttons/ButtonYellow/ButtonYellow';

const ConfirmBuyPackDialogContent = (props) => {
	const classes = useStyles();

    return (
        <div className={classes.confirmBuyPackDialogContent}>
			<br />
			<img src={TickInsideCircleImg} alt="OK" />
			<p className={classes.description}><span className={classes.cool}>Cool!</span> The package has been added to your library, go to the library and open the package!</p>
			<p>When someone buys your item, you'll receive a notification and the proceeds of the sale go to your wallet</p>
			<br />
			<ButtonPrimary component={Link} to={`/open-pack/${props.packId}`} className={classes.openNowButton}>OPEN NOW</ButtonPrimary>
			<ButtonYellow component={Link} to="/library">GO TO LIBRARY</ButtonYellow>
		</div>
    );
}

export default ConfirmBuyPackDialogContent;