import { Button } from "@material-ui/core";
import clsx from 'clsx';
import { useStyles } from "./ButtonDangerOutlineStyle";

const ButtonDangerOutline = (props) => {
	const classes = useStyles();

	return (
		<Button
			{...props}
			variant="outlined"
			className={clsx(classes.buttonDanger, props.className)}
		></Button>
	);
}

export default ButtonDangerOutline;