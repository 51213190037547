import { Grid } from '@material-ui/core';
import { useStyles } from './OrganizationBoxStyle';
import HandImg from '../../../assets/img/hand.svg';
import { ReactComponent as CardsImg } from '../../../assets/img/card.svg';
import PunchdownOrganizationImg from '../../../assets/img/punchdown-organization.webp';

const OrganizationBox = props => {
	const classes = useStyles(props);

	return (
		<Grid container item xs={12} className={classes.organizationBox} alignItems="center" onClick={props.handleClick}>
			<img src={PunchdownOrganizationImg} alt="Background img" className={classes.backgroundImg} />
			<div className={classes.imgGradient}></div>
			<Grid item xs={4}>
				<div className={classes.imgBox}>
					<img src={HandImg} alt="Organization img" className={classes.organizationImg} />
				</div>
			</Grid>
			<Grid item xs={8}>
				<p className={classes.name}>{props.organization?.name || 'Comming Soon'}</p>
				<p className={classes.description}>{props.organization?.description || 'Your organization could be here'}</p>
			</Grid>
			<div className={classes.cardAmount}>
				<CardsImg />
				<span className={classes.myCardsAmount}>3</span>
				/
				<span>100</span>
			</div>
		</Grid>
	);
}

export default OrganizationBox;