import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../../../shared/axios-client';

export const fetchCardOffers = createAsyncThunk(
	'cardOffers/fetch',
	async ({cardId}) => {
		const response = await axios.get(`generated_cards`, { params: {
			'byCardIds[]': cardId,
			forSale: true,
			view: 'extended'
		}});
		return response.data.data;
	}
);

export const CardOffersSlice = createSlice({
	name: 'cardOffers',
	initialState: {
		status: "init",
		error: null,
		data: []
	},
	reducers: {},
	extraReducers: {
		[fetchCardOffers.pending.type]: (state, action) => {
			state.status = "pending";
			state.error = null;
			state.data = [];
		},
		[fetchCardOffers.fulfilled.type]: (state, action) => {
			state.status = "fulfilled";
			state.error = null;
			state.data = action.payload;
		},
		[fetchCardOffers.rejected.type]: (state, action) => {
			state.status = "rejected";
			state.error = action.error.message;
			state.data = [];
		}
	}
});

export default CardOffersSlice.reducer;