import { Button, CircularProgress, Grid, Hidden } from '@material-ui/core';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import clsx from 'clsx';
import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { setSelectedCollection } from '../../../store/slices/library/LibraryCollectionsSlice';
import { setSelectedCard } from '../../../store/slices/library/LibraryYourCardsSlice';
import CardBox from '../../CardBox/CardBox';
import CollectionInfoBox from '../CollectionInfoBox/CollectionInfoBox';
import { useStyles } from './CardsViewStyle';

const CardsView = props => {
	const classes= useStyles();
	const history = useHistory();
	const dispatch = useDispatch();
	const libraryYourCardsStatus = useSelector(state => state.libraryYourCards.status);
	const libraryCardsStatus = useSelector(state => state.libraryCards.status);
	const libraryYourCards = useSelector(state => state.libraryYourCards.data);
	const libraryCards = useSelector(state => state.libraryCards.data);
	let cards = useRef([]);

	useEffect(() => {
		if (libraryYourCardsStatus === 'fulfilled' && libraryYourCards.length && libraryCardsStatus === 'fulfilled') {
			const yourCardIds = libraryYourCards.map(card => card?.card?.id);
			cards.current = libraryCards.filter(card => !yourCardIds.includes(card.id));
		}
	}, [libraryYourCardsStatus, libraryCardsStatus, libraryYourCards, libraryCards]);

	const handleCardClick = card => {
		dispatch(setSelectedCard(card));
		window.scrollTo(0, 0);
	}

	return (
		<Grid container item xs={8} className="flex-grow-full max-width-100">
			<Hidden lgUp>
				<div className={classes.backButtonBox}>
					<Button onClick={() => dispatch(setSelectedCollection(null))}>
						<NavigateBeforeIcon />BACK
					</Button>
				</div>
			</Hidden>
			<CollectionInfoBox organizationName={props.organizationName} collection={props.collection} />
			<Grid container item xs={12} justifyContent="center" className={classes.cardsBox}>
				{libraryYourCardsStatus === 'fulfilled' && (
					libraryYourCards.map(card => (
						<CardBox 
							key={card.id}
							card={card}
							shortInfo 
							minWidth={190}
							maxWidth={210}
							quantity={card?.quantity}
							cardSize="xs"
							className="cursor-pointer"
							handleClick={() => handleCardClick(card?.card)} />
				)))}
				{(
					libraryYourCardsStatus === 'init' || 
					libraryYourCardsStatus === 'pending' || 
					libraryCardsStatus === 'init' || 
					libraryCardsStatus === 'pending'
				) && <div className="text-align-center"><CircularProgress /></div>}
				{libraryCardsStatus === 'fulfilled' && (
				// {libraryCardsStatus === 'fulfilled' && !!cards.current.length && (
					// cards.current.map((card, index) => (
					libraryCards.map((card, index) => (
						<CardBox 
							key={index}
							card={card}
							shortInfo 
							minWidth={190}
							maxWidth={210}
							cardSize="xs"
							className={clsx(classes.cardOpacity, "cursor-pointer")}
							handleClick={() => history.push("/marketplace/" + card.id)} />
				)))}
			</Grid>
		</Grid>
	);
}

export default CardsView;