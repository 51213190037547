import { makeStyles } from '@material-ui/core/styles';
import DiamondCardBackgroundImg from '../../../assets/img/diamond-card-background.svg';

const useStyles = makeStyles((theme) => ({
	diamondPackBox: {
		background: `linear-gradient(169.5deg, rgba(10, 15, 29, 0) -33.62%, ${theme.palette.border.panel} 125.58%)`,
		border: `1px solid ${theme.palette.border.main}`,
		borderRadius: '3px',
		fontFamily: 'Klavika',
		height: '100%',

		[theme.breakpoints.down('xs')]: {
			marginTop: theme.spacing(1),
		},
		[theme.breakpoints.down('md')]: {
			'& button': {
				width: '100%'
			}
		},
		[theme.breakpoints.up("lg")]: {
			maxHeight: '220px'
		},
		[theme.breakpoints.up("sm")]: {
			marginLeft: theme.spacing(1)
		}
	},
	title: {
		fontWeight: 500,
		fontSize: '24px',
		lineHeight: '29px',
		letterSpacing: '0.04em',
		margin: 0
	},
	subtitle: {
		fontSize: '15px',
		lineHeight: '20px',
		color: theme.palette.text.lightOn
	},
	diamondPackBackground: {
		textAlign: 'center',
		backgroundImage: `url(${DiamondCardBackgroundImg})`,
		backgroundRepeat: 'no-repeat',
		backgroundSize: '100% 100%',
		maxHeight: '220px',

		[theme.breakpoints.down('md')]: {
			maxHeight: '205px'
		},
		'& img': {
			maxHeight: '220px'
		}
	},
	infoBox: {
		padding: theme.spacing(2)
	},
	displayFlex: {
		display: 'flex'
	},
	alignItemsCenter: {
		alignItems: 'center'
	}
}));

export { useStyles };