import { Button, Grid, Hidden, useMediaQuery, useTheme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ArrowBackIosTwoToneIcon from '@material-ui/icons/ArrowBackIosTwoTone';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import clsx from 'clsx';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Carousel } from 'react-responsive-carousel';
import { setSignUpDialogOpen } from '../../store/slices/dialogs/DialogsSlice';
import { useWindowSize } from './../../hooks/useWindowSize';
import CollectionCardBox from './CollectionCardBox';

const useStyles = makeStyles((theme) => ({
	collection: {
		maxWidth: 1410,
		margin: 'auto',

		[theme.breakpoints.down('md')]: {
			padding: theme.spacing(2),
		},
		[theme.breakpoints.up('md')]: {
			height: 'calc(100vh - 100px)'
		}
	},
	title: {
		fontSize: 48,
		fontWeight: 800,
		lineHeight: '59px',
		margin: 0,

		[theme.breakpoints.down('md')]: {
			fontSize: 30,
			lineHeight: '37px',
		}
	},
	description: {
		fontSize: 16,
		letterSpacing: '0.15px',
		color: '#DCDDDD',
		lineHeight: '28px'
	},
	descriptionBox: {
		[theme.breakpoints.up('md')]: {
			maxWidth: 469
		}
	},
	arrowButton: {
		borderRadius: '50%',
		width: 68,
		height: 68,
		border: `3px solid ${theme.palette.icons.main}`,
		color: theme.palette.text.trueWhite,
		margin: '20px 0',

		'&:hover': {
			border: `3px solid ${theme.palette.primary.main}`,
			background: theme.palette.primary.main,
			color: '#12192E',
		}
	},
	carouselBox: {
		[theme.breakpoints.up('md')]: {
			width: '90%',
			float: 'left',
		},
		[theme.breakpoints.down('md')]: {
			margin: `${theme.spacing(2)}px -${theme.spacing(2)}px`
		}
	},
	buttonsBox: {
		width: '10%',
		float: 'left',
		position: 'absolute',
		top: '50%',
		right: 0,
		
		[theme.breakpoints.only('lg')]: {
			transform: 'translate(0, -50%)',
		},
		[theme.breakpoints.only('xl')]: {
			transform: 'translate(-50%, -50%)',
		}
	},
	cardBox: {
		height: '100%',

		[theme.breakpoints.only('xs')]: {
			width: 210
		},
	}
}));

const Collection = () => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const theme = useTheme();
	const [width] = useWindowSize();
	const [sliderDesktopMarginLeft, setSliderDesktopMarginLeft] = useState(0);
	const [currentSliderIndex, setSlide] = useState(2);
	const [centerSlidePercentage, setCenterSlidePercentage] = useState(100 / 3);
	const isXsWidth = useMediaQuery(theme.breakpoints.only('xs'));
	const isSmWidth = useMediaQuery(theme.breakpoints.only('sm'));
	const isMdWidth = useMediaQuery(theme.breakpoints.only('md'));
	const cards = useSelector(state => state.landingPageCards.data);

	useEffect(() => {
		if (width > 1410) {
			setSliderDesktopMarginLeft((width - 1410) / 2);
		}
	}, [width]);

	useEffect(() => {
		if (isXsWidth) {
			setCenterSlidePercentage(100 / 1.7);
		};
		if (isSmWidth) {
			setCenterSlidePercentage(100 / 2.6);
		};
		if (isMdWidth) {
			setCenterSlidePercentage(100 / 2.3);
		};
	}, [isXsWidth, isSmWidth, isMdWidth]);


	const prevButton = () => {
		if (currentSliderIndex === 0) {
			setSlide(cards.length);
		} else {
			setSlide(currentSliderIndex - 1);
		}
	};
	
	const nextButton = () => {
		if (currentSliderIndex === cards.length) {
			setSlide(0);
		} else {
			setSlide(currentSliderIndex + 1);
		}
	};

	return (
		<Grid container className={classes.collection}>
			<Grid container item xs={12} className="margin-auto">
				<Grid item xs={12} md={7} className="position-relative">
					<Hidden mdUp>
						<div style={{ overflowX: 'hidden' }}>
							<p className={classes.title} data-aos="fade-left">
								Collect hundreds of unique cards
							</p>
						</div>
					</Hidden>
					<div style={{ marginLeft: `-${sliderDesktopMarginLeft}px` }}>
						<div className={classes.carouselBox}>
							<Carousel
								centerMode
								infiniteLoop
								centerSlidePercentage={centerSlidePercentage}
								selectedItem={currentSliderIndex}
								showArrows={false}
								showThumbs={false}
								showStatus={false}
								showIndicators={false}
								autoPlay
								onChange={i => setSlide(i)}
							>
								{cards.map(card => <div key={card.id} className={classes.cardBox}>
									<CollectionCardBox card={card?.card} cardSize={isXsWidth ? "xs" : "sm"} />
								</div>)}
							</Carousel>
						</div>
						<Hidden mdDown>
							<div className={classes.buttonsBox}>
								<Button 
									className={clsx(classes.arrowButton, classes.rightArrowButton, "mt-0")} 
									onClick={nextButton}>
									<ArrowForwardIosIcon />
								</Button>
								<Button 
									className={clsx(classes.arrowButton, classes.leftArrowButton)} 
									onClick={prevButton}>
									<ArrowBackIosTwoToneIcon />
								</Button>
							</div>
						</Hidden>
					</div>
				</Grid>
				<Grid item xs={12} md={5} style={{ overflowX: 'hidden' }}>
					<Hidden smDown>
						<p className={classes.title} data-aos="fade-left">
							Collect hundreds<br />of unique cards
						</p>
					</Hidden>
					<div className={classes.descriptionBox}>
						<p className={classes.description} data-aos="fade-left">
							Have you ever dreamed of owning your own esports team? Now you can! Collect the best stars, build your roster and battle in realistic match simulations.
						</p>
						<p className={classes.description} data-aos="fade-left">
							Rank up through the core season circuit and play in tournaments that coincide with big events in the scene. Get to the top of the leaderboards to win awesome prizes!
						</p>
					</div>
					<Button color="primary" variant="contained" data-aos="fade-left" onClick={() => dispatch(setSignUpDialogOpen(true))}>HOW TO COLLECTION CARD</Button>
				</Grid>
			</Grid>
		</Grid>
	)
}

export default Collection;