import { IconButton, Snackbar } from "@material-ui/core";
import { useDispatch, useSelector } from 'react-redux';
import { setMessage, setOpen } from "../../store/slices/alert/AlertSlice";
import CloseIcon from '@material-ui/icons/Close';

const Alert = () => {
	const dispatch = useDispatch();
	const open = useSelector((state) => state.alert.open);
	const message = useSelector((state) => state.alert.message);

	const handleClose = () => {
		dispatch(setOpen(false));
		dispatch(setMessage(''));
	};

	return <Snackbar
			anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
			open={open}
			onClose={handleClose}
			message={message}
			autoHideDuration={3000}
			action={<IconButton
				size="small"
				aria-label="close"
				color="inherit"
				onClick={handleClose}
			>
				<CloseIcon fontSize="small" />
			</IconButton>}
		/>;
}

export default Alert;