import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../../shared/axios-client';

export const fetchYourPacks = createAsyncThunk(
	'yourPacks/fetch',
	async () => {
		const response = await axios.get('generated_booster_packs', { params: {
			'byOwnerIds[]': 3, // TODO logged user id
			view: 'extended'
		}});
		return response.data.data;
	}
);

export const YourPacksSlice = createSlice({
	name: 'yourPacks',
	initialState: {
		status: "init",
		error: null,
		data: [],
	},
	reducers: {},
	extraReducers: {
		[fetchYourPacks.pending.type]: (state, action) => {
			state.status = "pending";
			state.error = null;
			state.data = [];
		},
		[fetchYourPacks.fulfilled.type]: (state, action) => {
			state.status = "fulfilled";
			state.error = null;
			state.data = action.payload;
		},
		[fetchYourPacks.rejected.type]: (state, action) => {
			state.status = "rejected";
			state.error = action.error.message;
			state.data = [];
		},
	}
});

export default YourPacksSlice.reducer;