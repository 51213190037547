import PropTypes from 'prop-types';
import Navbar from '../../components/UI/Navbar/Navbar';
import { useStyles } from './LayoutStyle';

const Layout = (props) => {
  const classes = useStyles();

  return (
    <>
		<Navbar />
		<main className={classes.content}>
			<div className={classes.flexGrow}>
				{props.children}
			</div>
		</main>
    </>
  );
}

Layout.propTypes = {
  	children: PropTypes.node.isRequired
}

export default Layout;