import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    circlePrimary: {
        background: theme.palette.primary.main,
        color: theme.palette.text.trueWhite,
        width: '37px',
        height: '37px',
        borderRadius: '50%',
        fontFamily: 'Klavika',
        fontSize: '14px',
        paddingTop: '8px'
    }
}));

export { useStyles };