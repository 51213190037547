import { CircularProgress, Grid, Hidden, Slide } from '@material-ui/core';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedCollection } from '../../../store/slices/library/LibraryCollectionsSlice';
import { setSelectedOrganization } from '../../../store/slices/library/LibraryOrganizationsSlice';
import { setSelectedCard } from '../../../store/slices/library/LibraryYourCardsSlice';
import CollectionBox from '../CollectionBox/CollectionBox';
import OrganizationBox from '../OrganizationBox/OrganizationBox';
import { useStyles } from './LibrarySliderStyle';

const LibrarySlider = props => {
    const classes = useStyles();
	const dispatch = useDispatch();
    const organizationsStatus = useSelector((state) => state.libraryOrganizations.status);
	const organizations = useSelector(state => state.libraryOrganizations.data);
	const selectedOrganization = useSelector(state => state.libraryOrganizations.selectedOrganization);
	const collectionsStatus = useSelector((state) => state.libraryCollections.status);
	const selectedCollection = useSelector(state => state.libraryCollections.selectedCollection);

	useEffect(() => {
		// dispatch(setSelectedCollection(props.collectionsPerOrganization[0])); // TODO
		dispatch(setSelectedCollection(props.collectionsPerOrganization.find(collection => collection.name === 'Punchdown2')));
	}, [props.collectionsPerOrganization, dispatch]);

	const handleOrganizationBoxClick = organization => {
		dispatch(setSelectedOrganization(organization));
	};

    const handleCollectionBoxClick = collection => {
		dispatch(setSelectedCollection(collection));
		dispatch(setSelectedCard(null));
		window.scrollTo(0, 0);
	};

    const handleChangeDigitalSet = () => {
		dispatch(setSelectedOrganization(null));
		dispatch(setSelectedCollection(null));
		dispatch(setSelectedCard(null));
	};

	const organizationsBox = (
		<div className={classes.slide}>
			<p className={classes.setName}>DIGITAL SET</p>
			{(organizationsStatus === 'init' || organizationsStatus === 'pending') && <div className="text-align-center"><CircularProgress /></div>}
			{organizationsStatus === 'fulfilled' && (
				organizations.map(organization => (
					<OrganizationBox 
						key={organization.id} 
						organization={organization}
						handleClick={() => handleOrganizationBoxClick(organization)} />
			)))}
			{[0, 1, 2, 3].map(x => <OrganizationBox key={x} onlyDisplay />)}
		</div>
	);

	const collectionsBox = (
		<div className={classes.slide}>
			<p className={classes.setName}>DIGITAL SET</p>
			<OrganizationBox organization={selectedOrganization} onlyDisplay />
			<p className={classes.changeSet} onClick={() => handleChangeDigitalSet()}>CHANGE DIGITAL SET</p>
			<p className={classes.setName}>{selectedOrganization?.name} SET</p>
			{(collectionsStatus === 'init' || collectionsStatus === 'pending') && <div className="text-align-center"><CircularProgress /></div>}
			{collectionsStatus === 'fulfilled' && (
				props.collectionsPerOrganization.map(collection => (
					<CollectionBox 
						key={collection.id} 
						collection={collection}
						organizationName={selectedOrganization?.name}
						handleClick={() => handleCollectionBoxClick(collection)} />
			)))}
		</div>
	);

    return (
		<>
			<Hidden mdDown>
				<Grid item xs={4} style={{ maxWidth: 350 }}>
					{!selectedOrganization && (
						<Slide direction="right" in={true}>{organizationsBox}</Slide>
					)}
					{selectedOrganization && (
						<Slide direction="right" in={Boolean(selectedOrganization?.id)}>{collectionsBox}</Slide>
					)}
				</Grid>
			</Hidden>
			<Hidden lgUp>
				{!selectedOrganization && !selectedCollection && organizationsBox}
				{selectedOrganization && !selectedCollection && collectionsBox}
			</Hidden>
		</>
    );
}

export default LibrarySlider;