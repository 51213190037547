import { makeStyles } from "@material-ui/core";
import { getRarityColor } from "../../utils/rarityColor";
import AnimatedCard from './../../components/AnimatedCard/AnimatedCard';

const useStyles = makeStyles((theme) => ({
	cardBox: {
		background: theme.palette.background.panel,
		border: `1px solid ${theme.palette.border.main}`,
		borderRadius: '3px',
		textAlign: 'center',
		fontFamily: 'Klavika',
		display: 'flex',
		flexDirection: 'column',
		maxWidth: 260,
		height: '100%'
	},
	colorLine: {
		margin: '0px auto !important',
		marginTop: '0 !important',
		width: '108px',
		background: props => getRarityColor(props.card?.cardType?.name),
		boxShadow: '0px 5px 12px rgb(214 179 19 / 25%)',
		borderRadius: '0px 0px 12px 12px',
		height: '5px'
	},
	cardRarity: {
		color: props => getRarityColor(props.card?.cardType?.name),
		margin: '10px 0',
		textTransform: 'uppercase'
	},
	nameBox: {
		background: theme.palette.background.main,
		borderRadius: '0px 0px 3px 3px',
		fontSize: 18,
		color: theme.palette.text.trueWhite,
		flexGrow: 1,
		textTransform: 'uppercase'
	}
}));

const CollectionCardBox = (props) => {
	const classes = useStyles(props);

	return (
		<div className={classes.cardBox}>
			<hr className={classes.colorLine} />
			<div className={classes.cardDiv}>
				<AnimatedCard 
					card={props.card}
					size={props.cardSize}
					noRotate noShadow />
			</div>
			<p className={classes.cardRarity}>{props.card?.cardType?.name}</p>
			<div className={classes.nameBox}>
				<p>{props.card?.name}</p>
			</div>
		</div>
	);
};

export default CollectionCardBox;