import { makeStyles } from '@material-ui/core/styles';
import { getRarityColor } from '../../utils/rarityColor';

const useStyles = makeStyles((theme) => ({
	cardBox: {
		background: theme.palette.background.panel,
		border: `1px solid ${theme.palette.border.main}`,
		borderRadius: '3px',
		textAlign: 'center',
		fontFamily: 'Klavika',
		margin: '10px',
		minWidth: props => props.minWidth || 230,
		maxWidth: props => props.maxWidth || 280,
		display: 'flex',
		flexDirection: 'column'
	},
	cardInfo: {
		fontSize: '12px',
		lineHeight: '14px',
		textTransform: 'uppercase'
	},
	cardRarity: {
		color: props => getRarityColor(props.card?.card?.cardType?.name || props.card?.cardType?.name)
	},
	collection: {
		color: theme.palette.text.lightOn
	},
	name: {
		fontSize: '18px',
		lineHeight: '22px',
		marginTop: 0,
		textTransform: 'uppercase'
	},
	priceBox: {
		background: theme.palette.background.main,
		borderRadius: '0px 0px 3px 3px',
		display: 'flex',
		paddingLeft: theme.spacing(2),
		paddingRight: theme.spacing(2),
		fontSize: '13px'
	},
	viewOnMarketLink: {
		fontSize: '12px',
		lineHeight: '14px',
		paddingTop: '3px',
		
		'& a': {
			color: theme.palette.icons.main,
			textDecoration: 'none',
			'&:hover': {
				color: theme.palette.primary.main
			}
		}
	},
	flexGrow: {
		flexGrow: 1
	},
	colorLine: {
		margin: '1rem auto !important',
		marginTop: '0 !important',
		width: '108px',
		background: props => getRarityColor(props.card?.card?.cardType?.name || props.card?.cardType?.name),
		boxShadow: '0px 5px 12px rgb(214 179 19 / 25%)',
		borderRadius: '0px 0px 12px 12px',
		height: '5px'
	},
	cardDiv: {
		position: 'relative',
		maxWidth: '266px',
		margin: 'auto',
		marginBottom: props => props.shortInfo && theme.spacing(2)
	},
	tag: {
		position: 'absolute',
		top: 0,
		right: 0,
		zIndex: 2
	},
	quantity: {
		position: 'absolute',
		top: '-10px',
		right: '-10px',
		zIndex: 2
	}
}));

export { useStyles };