import { Grid } from '@material-ui/core';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import CirclePrimary from '../../UI/Badges/CirclePrimary/CirclePrimary';
import ButtonPrimary from '../../UI/Buttons/ButtonPrimary/ButtonPrimary';
import { useStyles } from './YourPackBoxStyle';

const YourPackBox = props => {
	const classes = useStyles();
	return (
		<Grid container item className={clsx(classes.yourPackBox, "panel-fill")}>
			<div className="position-relative margin-auto">
				{props.quantity > 1 && <CirclePrimary className={classes.quantity}>{props.quantity}</CirclePrimary>}
				<img src={props.pack?.boosterPack?.image?.url} alt="Pack img" className={classes.packImg} />
			</div>
			<h3 className={classes.title}>{props.pack?.boosterPack?.name}</h3>
			<p className={classes.subtitle}>{props.pack?.boosterPack?.description}</p>
			<ButtonPrimary fullWidth component={Link} to={`open-pack/${props.pack?.id}`}>OPEN PACK</ButtonPrimary>
		</Grid>
	);
};

export default YourPackBox;