import { Button, Grid, Hidden, makeStyles, useMediaQuery, useTheme } from "@material-ui/core";
import clsx from "clsx";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as CardIcon } from '../../assets/img/card.svg';
import { ReactComponent as ClockIcon } from '../../assets/img/clockwise.svg';
import CraftingBgMobile from '../../assets/img/crafting_bg_mobile.webp';
import { ReactComponent as MarketplaceIcon } from '../../assets/img/marketplace.svg';
import { ReactComponent as CupIcon } from '../../assets/img/quest.svg';
import AnimatedCard from "../../components/AnimatedCard/AnimatedCard";
import { setSignUpDialogOpen } from "../../store/slices/dialogs/DialogsSlice";

const useStyles = makeStyles((theme) => ({
	crafting: {
		maxWidth: 1410,
		margin: 'auto',

		[theme.breakpoints.down('md')]: {
			padding: theme.spacing(2),
		},
		[theme.breakpoints.up('md')]: {
			height: 'calc(100vh - 100px)'
		}
	},
	craftingGrid: {
		[theme.breakpoints.down('sm')]: {
			flexDirection: 'column-reverse !important'
		}
	},
	title: {
		fontSize: 48,
		fontWeight: 800,
		lineHeight: '59px',
		margin: 0,

		[theme.breakpoints.down('md')]: {
			fontSize: 30,
			lineHeight: '37px',
		}
	},
	description: {
		fontSize: 16,
		letterSpacing: '0.15px',
		color: '#DCDDDD',
		lineHeight: '28px'
	},
	descriptionBox: {
		[theme.breakpoints.up('md')]: {
			maxWidth: 469
		}
	},
	craftingBgMobileBg: {
		maxWidth: '100%',
		maxHeight: '100%',
	},
	craftStepBox: {
		background: 'linear-gradient(169.5deg, rgba(10, 15, 29, 0) -33.62%, #1F2431 125.58%)',
		border: `1px solid ${theme.palette.border.main}`,
		borderRadius: '4px',
		fontWeight: 600,
		fontSize: 18,
		lineHeight: '22px',
		padding: '20px 30px',

		'& svg': {
			marginRight: 20,
			width: 42,
			height: 42,
			fill: theme.palette.primary.main
		},
		[theme.breakpoints.only('xs')]: {
			margin: '7.5px 0 !important',
		},
		[theme.breakpoints.up('sm')]: {
			margin: '7.5px !important',
			marginLeft: '0 !important',
			marginRight: '15px !important',

			'& p': {
				marginBottom: '0 !important'
			}
		},
		[theme.breakpoints.up('xl')]: {
			margin: '15px !important',
			marginLeft: '0 !important',
			marginRight: '30px !important',
		},
		[theme.breakpoints.down('md')]: {
			fontSize: 16,
			lineHeight: '20px',
			padding: '20px 30px',
			display: 'flex',
			alignItems: 'center',
		},
		[theme.breakpoints.only('md')]: {
			display: 'block',
    		padding: theme.spacing(1)
		}
	},
	cupIcon: {
		fill: '#D6B313 !important'
	},
	buttonCraft: {
		marginTop: '7.5px !important',
		[theme.breakpoints.only('xs')]: {
			width: '100%'
		},
		[theme.breakpoints.up('md')]: {
			marginTop: '15px !important',
		},
	},
	bigCardBox: {
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
	},
	smallCardBox: {
		background: theme.palette.background.panel,
		border: `1px solid ${theme.palette.border.main}`,
		borderRadius: '3px',
		padding: 10,
		height: 135,
		maxWidth: 95,
		marginBottom: 15,
		marginRight: 15,

		'& .animated-card': {
			transform: 'scale(0.5)',
			transformOrigin: '0 -15px'
		},
		[theme.breakpoints.only('md')]: {
			marginRight: '0 !important'
		}
	},
	smallCardsBox: {
		marginTop: '-30px',
		zIndex: 2
	},
	bigCardDesktop: {
		[theme.breakpoints.only('md')]: {
			marginLeft: '-70px',
		},
		[theme.breakpoints.only('lg')]: {
			marginLeft: '-70px',
			marginTop: 30
		},
		[theme.breakpoints.only('xl')]: {
			marginBottom: 80
		}
	}
}));

const Crafting = () => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const theme = useTheme();
	const isXlWidth = useMediaQuery(theme.breakpoints.only('xl'));
	const cards = useSelector(state => state.landingPageCards.data);

	return (
		<Grid container className={classes.crafting}>
			<Grid container item xs={12} className={clsx("margin-auto", classes.craftingGrid)}>
				<Grid container item xs={12} md={5}>
					<p className={classes.title} data-aos="fade-right">
						Crafting cards
					</p>
					<div className={classes.descriptionBox}>
						<p className={classes.description} data-aos="fade-right">
							Choose the cards that are unfavorable for you and exchange it for another card with a better rarity! You can do it in simple steps, try it yourself
						</p>
					</div>
					<Grid item xs={12} sm={5} className={classes.craftStepBox} data-aos="fade-right">
						<CardIcon />
						<p>Select six random cards</p>
					</Grid>
					<Grid item xs={12} sm={5} className={classes.craftStepBox} data-aos="fade-right" data-aos-delay="50">
						<ClockIcon />
						<p>Wait a moment</p>
					</Grid>
					<Grid item xs={12} sm={5} className={classes.craftStepBox} data-aos="fade-right" data-aos-delay="100">
						<CupIcon className={classes.cupIcon} />
						<p>Keep the winning card</p>
					</Grid>
					<Grid item xs={12} sm={5} className={classes.craftStepBox} data-aos="fade-right" data-aos-delay="150">
						<MarketplaceIcon />
						<p>or sell card on the market</p>
					</Grid>
					<Button color="primary" variant="contained" data-aos="fade-right" className={classes.buttonCraft} onClick={() => dispatch(setSignUpDialogOpen(true))}>
						LET’S GO CRAFT!
					</Button>
				</Grid>
				<Grid container item xs={12} md={7}>
					<Hidden smDown>
						<Grid item xs={12} className={classes.bigCardDesktop} data-aos="fade-down">
							<AnimatedCard card={cards[0]?.card} size={isXlWidth ? "lg" : "sm"} noShadow />
						</Grid>
					</Hidden>
					<Grid container item xs={12} justifyContent="center" alignItems="flex-end" className={classes.smallCardsBox}>
						{/* TODO delete slice */}
						{cards.slice(1, 7).map((card, i) => 
							<Grid 
								key={card.id} 
								item xs={4} md={3} lg={2} 
								className={classes.smallCardBox} 
								data-aos="fade-up"
								data-aos-delay={50 * i}>
									<AnimatedCard card={card?.card} size={"xs"} noRotate noShadow />
							</Grid>
						)}
					</Grid>
				</Grid>
				<Hidden mdUp>
					<Grid item xs={12} md={7} className="text-align-center position-relative" data-aos="fade-down">
							<img src={CraftingBgMobile} className={classes.craftingBgMobileBg} alt="bg" />
							<div className={classes.bigCardBox}>
								<AnimatedCard card={cards[0]?.card} size={"sm"} noShadow />
							</div>
					</Grid>
				</Hidden>
			</Grid>
		</Grid>
	)
}

export default Crafting;