import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
	breadcrumbsText: {
		fontFamily: 'Klavika',
		fontWeight: 500,
		fontSize: '13px',
		lineHeight: '16px',
		letterSpacing: '0.4px',
		textTransform: 'capitalize',
	},
	link: {
		color: theme.palette.text.lightOn,
		textDecoration: 'none',
		'&:hover': {
			textDecoration: 'underline'
		}
	},
	nextIcon: {
		color: theme.palette.text.lightOn,
		fontSize: '10px'
	}
}));

export { useStyles };