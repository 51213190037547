import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
	collectionBox: {
		border: `1px solid ${theme.palette.border.main}`,
		borderLeft: `3px solid ${theme.palette.background.panel}`,
		height: 120,
		padding: theme.spacing(2),
		position: 'relative',
		
		'&:hover': {
			borderLeft: `3px solid ${theme.palette.primary.main}`,
			background: 'linear-gradient(90.36deg, rgba(0, 231, 174, 0.1) 0.29%, rgba(196, 196, 196, 0) 98.86%)',
			cursor: 'pointer'
		}
	},
	selectedCollection: {
		borderLeft: `3px solid ${theme.palette.primary.main}`,
		background: 'linear-gradient(90.36deg, rgba(0, 231, 174, 0.1) 0.29%, rgba(196, 196, 196, 0) 98.86%)',

		'&:hover': {
			cursor: 'default'
		}
	},
	backgroundImg: {
		position: 'absolute',
		left: 0,
		top: 0,
		opacity: 0.1,
		objectFit: 'cover',
		width: '100%',
		height: 120,
	},
	imgGradient: {
		background: 'radial-gradient(52.1% 53.23% at 88.89% 11.45%, rgba(255, 255, 255, 0.065) 0%, rgba(255, 255, 255, 0) 98.1%), linear-gradient(90deg, rgba(12, 17, 33, 0.5) 0%, rgba(12, 17, 33, 0.1) 93.93%)',
		position: 'absolute',
		left: 0,
		top: 0,
		width: '100%',
		height: 120,
	},
	name: {
		margin: `${theme.spacing(1)}px 0`,
		color: theme.palette.text.trueWhite,
		fontSize: 16
	},
	cardAmount: {
		color: theme.palette.text.lightOn,
		display: 'flex',
		alignItems: 'center',

		'& svg': {
			fill: theme.palette.primary.main,
			height: 17,
			width: 17,
			marginRight: theme.spacing(1)
		}
	},
	myCardsAmount: {
		color: theme.palette.text.trueWhite,
	}
}));

export { useStyles };