import { Grid, InputAdornment, TextField } from '@material-ui/core';
import Search from '@material-ui/icons/Search';
import { useState } from 'react';
import ButtonPrimary from './../../../UI/Buttons/ButtonPrimary/ButtonPrimary';
import FiltersSelect from './../../../UI/FiltersSelect/FiltersSelect';
import AllYourCardsFilters from './../AllYourCardsFilters/AllYourCardsFilters';
import { useStyles } from './AllYourCardsFiltersBoxStyle';

const AllYourCardsFiltersBox = props => {
    const classes = useStyles();
	const [searchInputValue, setSearchInputValue] = useState('');

    return (
        <Grid container className={classes.filterBox}>
			<Grid container item xs={12} md={8} lg={6} className="display-flex align-items-center">
				<Grid item xs={12} md={6} className="display-flex">
					<TextField
						className={classes.searchInput}
						value={searchInputValue}
						onChange={(event) => setSearchInputValue(event.target.value)}
						variant="outlined" 
						placeholder="Search"
						InputProps={{
							endAdornment: <InputAdornment position="end"><Search /></InputAdornment>
						}}
					/>
					<ButtonPrimary className={classes.searchButton} onClick={() => props.setName(searchInputValue)}>
						SEARCH
					</ButtonPrimary>
				</Grid>
				<Grid item xs={12} md={6} className="display-flex">
					<FiltersSelect>
						<AllYourCardsFilters 
							rarity={props.rarity}
							setRarity={props.setRarity}
							organization={props.organization}
							setOrganization={props.setOrganization}
							priceSort={props.priceSort}
							setPriceSort={props.setPriceSort}
							popularitySort={props.popularitySort}
							setPopularitySort={props.setPopularitySort} />
					</FiltersSelect>
				</Grid>
			</Grid>
			<Grid container item xs={12} md={4} lg={6} className={classes.sellButtonBox}>
				<ButtonPrimary onClick={props.handlSellOnMarketplaceClick}>
					SELL ON MARKETPLACE {`(${props.selectedCards.length})`}
				</ButtonPrimary>
			</Grid>
		</Grid>
    );
}

export default AllYourCardsFiltersBox;