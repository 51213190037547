import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
	buyPackDialog: {
		padding: theme.spacing(3),
		textAlign: 'center',
		color: theme.palette.text.trueWhite,
		fontSize: '24px'
	},
	cancelButton: {
		marginRight: theme.spacing(2),
		
		[theme.breakpoints.down("xs")]: {
			marginBottom: theme.spacing(2)
		}
	},
	name: {
		marginTop: 0,
		
		[theme.breakpoints.down("xs")]: {
			marginBottom: theme.spacing(1)
		}
	},
	question: {
		marginBottom: theme.spacing(1)
	},
	description: {
		fontSize: '16px',
		margin: 0
	},
	packImg: {
		height: '100%',
		width: 'auto',
		maxHeight: '250px',
		borderRadius: '5px',

		[theme.breakpoints.down("xs")]: {
			maxHeight: '200px'
		}
	},
	divider: {
		border: `0.5px solid ${theme.palette.border.main} !important`,
		margin: '0 !important',
		width: '100%'
	},
	quantityBox: {
		textAlign: 'left',
		margin: 'auto 0',

		[theme.breakpoints.down("xs")]: {
			textAlign: 'center',
			marginBottom: theme.spacing(2)
		}
	},
	quantityButtons: {
		border: `1px solid ${theme.palette.border.main}`,

		'& button, button:disabled': {
			color: theme.palette.text.lightOn
		},
		'& .plus-button': {
			color: theme.palette.primary.main
		}
	}
}));

export { useStyles };