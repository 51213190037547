import { yupResolver } from '@hookform/resolvers/yup';
import { Grid, TextField } from '@material-ui/core';
import { forwardRef, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { ReactComponent as CardsImg } from '../../../assets/img/card.svg';
import { sellPriceValidationSchema } from '../../../utils/validations/sellCardValidation';
import ButtonPrimary from '../../UI/Buttons/ButtonPrimary/ButtonPrimary';
import IncrementDecrementButtonGroup from '../../UI/Buttons/IncrementDecrementButtonGroup/IncrementDecrementButtonGroup';
import DecimalFormat from '../../UI/DecimalFormat/DecimalFormat';
import { useStyles } from './SellCardDialogContentStyle';
import AnimatedCard from './../../AnimatedCard/AnimatedCard';

const SellCardDialogContent = forwardRef((props, ref) => {
	const classes = useStyles();
	const { control, handleSubmit, setValue, formState: { errors } } = useForm({
		resolver: yupResolver(sellPriceValidationSchema)
	});
	
	useEffect(() => {
		setValue('sellQuantity', 1);
	}, [setValue]);

	return (
		<form onSubmit={handleSubmit(props.handleSell)} className={classes.sellCardDialog}>
			<Grid container item xs={12}>
				<Grid item xs={12} sm={5} container justifyContent="center" alignItems="center">
					{/* <img src={props.img} alt="Card img" className={classes.cardImg} /> */}
					<AnimatedCard card={props.card} size="sm" />
				</Grid>
				<Grid item xs={12} sm={7} className={classes.sellBox}>
					<p className={classes.nameDescription}>CARD NAME</p>
					<p className={classes.name}>{props.name}</p>
					<div className={classes.cardStack}>
						<CardsImg />
						<span>Stack: {props.stack}</span>
					</div>
					<p className={classes.description}>Selling price</p>
					<Controller
						name="sellPrice"
						control={control}
						render={({ field }) => (
							<TextField
								{ ...field }
								className={errors?.sellPrice ? "error-border-input" : ""}
								variant="outlined" 
								placeholder="Price"
								fullWidth
								InputProps={{
									inputComponent: DecimalFormat,
								}}
							/>
						)}
					/>
					<p className={classes.description}>Quantity</p>
					<IncrementDecrementButtonGroup
						quantity={1}
						minusButtonClickHandle={quantity => setValue('sellQuantity', quantity)}
						plusButtonClickHandle={quantity => setValue('sellQuantity', quantity)}
						minusButtonDisabled={1}
						plusButtonDisabled={props.stack}
					/>
					<Grid item>
						<ButtonPrimary type="submit" className={classes.sellButton}>PUT IT UP FOR SALE</ButtonPrimary>
					</Grid>
				</Grid>
			</Grid>
		</form>
	);
});

export default SellCardDialogContent;