import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
	slide: {
		background: theme.palette.background.panel,
		width: '100%',
		
		[theme.breakpoints.up("lg")]: {
			overflowY: 'auto',
			height: `calc(100vh - 94px - 85px)`,
			position: 'fixed',
			width: 350,
			maxWidth: 350,
		}
	},
	setName: {
		borderTop: `1px solid ${theme.palette.border.main}`,
		borderRight: `1px solid ${theme.palette.border.main}`,
		paddingLeft: theme.spacing(2),
		paddingTop: '1em',
		paddingBottom: '1em',
		margin: 0,
		textDecoration: 'uppdercase'
	},
	changeSet: {
		borderRight: `1px solid ${theme.palette.border.main}`,
		paddingLeft: theme.spacing(2),
		paddingTop: '1em',
		paddingBottom: '1em',
		margin: 0,
		textAlign: 'center',
		background: theme.palette.primary.main,
		color: theme.palette.background.panel,
		fontSize: 13,
		cursor: 'pointer'
	}
}));

export { useStyles };