import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
	productBox: {
		border: `1px solid ${theme.palette.border.main}`,
		borderRadius: '3px',
		fontFamily: 'Klavika',
		textAlign: 'center',
		filter: 'drop-shadow(0px 6px 30px #13161F)'
	},
	product: {
		background: 'radial-gradient(77.33% 77.33% at 50% 50%, #1E222D 35.13%, #070A14 90.52%)',
		padding: theme.spacing(2),
		position: 'relative',
	},
	bedgeGreen: {
		position: 'absolute',
		left: theme.spacing(2),
		top: theme.spacing(2)
	},
	coinsImg: {
		margin: `${theme.spacing(2)}px 0`
	},
	name: {
		fontSize: '18px',
		marginBottom: 0
	},
	price: {
		fontSize: '15px',
		marginTop: 0
	},
	buyNow: {
		background: theme.palette.background.main,
		padding: theme.spacing(1),
		color: theme.palette.primary.main,
		fontSize: '14px',

		'& svg': {
			fill: theme.palette.primary.main,
			marginRight: '10px'
		},
		'& p': {
			marginTop: '3px',
			marginBottom: 0
		}
	}
}));

export { useStyles };