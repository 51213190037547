import WalletGreenImg from '../../../../assets/img/wallet-green.svg';
import { useStyles } from './WalletGreenIconStyle';
import clsx from 'clsx';

const WalletGreenIcon = props => {
	const classes = useStyles();
	const isSafari = navigator.userAgent.toLowerCase().match(/(ipad|iphone)/);
	
	return <img 
			src={WalletGreenImg} 
			className={clsx(classes.walletGreenImg, props.className)} 
			style={{ marginTop: isSafari ? '-3px' : '0' }} 
			alt="Wallet" 
		/>;
}

export default WalletGreenIcon;