import { CircularProgress } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/core/styles';
import AOS from 'aos';
import { Suspense, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Switch, withRouter } from 'react-router-dom';
import Alert from './hoc/Alert/Alert';
import Layout from './hoc/Layout/Layout';
import SignInDialog from './LandingPage/Dialogs/SignInDialog';
import SignUpDialog from './LandingPage/Dialogs/SignUpDialog';
import LandingPage from './LandingPage/LandingPage';
import Register from './LandingPage/Register/Register';
import customTheme from './shared/custom-mui-theme';
import lpTheme from './shared/landing-page-theme';
import routes from './shared/routes';
import { fetchCardTypes } from './store/slices/dicts/CardTypesSlice';
import { fetchCollections } from './store/slices/dicts/CollectionsSlice';
import { fetchOrganizations } from './store/slices/dicts/OrganizationsSlice';
import { fetchUser } from './store/slices/user/UserSlice';

const App = () => {
	AOS.init();
	const dispatch = useDispatch();
	const cardTypesStatus = useSelector((state) => state.cardTypes.status);
	const collectionsStatus = useSelector((state) => state.collections.status);
	const organizationsStatus = useSelector((state) => state.organizations.status);

	useEffect(() => {
		if (cardTypesStatus === 'init') {
			dispatch(fetchCardTypes());
		}
	}, [cardTypesStatus, dispatch]);

	useEffect(() => {
		if (collectionsStatus === 'init') {
			dispatch(fetchCollections());
		}
	}, [collectionsStatus, dispatch]);

	useEffect(() => {
		if (organizationsStatus === 'init') {
			dispatch(fetchOrganizations());
		}
	}, [organizationsStatus, dispatch]);

	useEffect(() => {
		dispatch(fetchUser());
	}, [dispatch]);

	return (
		<>
			{localStorage.getItem('token') && <ThemeProvider theme={customTheme}>
				<Layout>
					<Suspense fallback={<CircularProgress />}>{routes}</Suspense>
					<Alert />
				</Layout>
			</ThemeProvider>}
			{!localStorage.getItem('token') && <ThemeProvider theme={lpTheme}>
				<Switch>
					<Route path="/register" component={Register} />
					<Route path="/" exact component={LandingPage} />
				</Switch>
				<SignUpDialog />
				<SignInDialog />
			</ThemeProvider>}
		</>
	);
}

export default withRouter(App);
