import { createTheme } from '@material-ui/core/styles';

const customTheme = createTheme({
	palette: {
		primary: {
			main: '#00E7AE'
		},
		secondary: {
			main: '#13161F'
		},
		background: {
			main: '#070A14',
			panel: '#0C1120',
			hoverMain: '#13161F'
		},
		border: {
			main: '#1E222D',
			panel: '#1F2431',
		},
		icons: {
			main: '#3E414F'
		},
		auxiliary: {
			success: '#39C700',
			danger: '#E11501',
			warning: '#D6B313',
			grey: '#12192E'
		},
		text: {
			trueWhite: '#FFFFFF',
			greyStuned: '#414553',
			greyDetails: '#434654',
			lightOn: '#767D92',
		},
		dropdown: {
			background: 'rgb(17, 24, 33)',
			text: '#626A70'
		}
	},
	typography: {
    	fontFamily: 'Klavika'
	}
});

customTheme.overrides = {
	MuiTypography: {
		root: {
			fontFamily: 'Klavika'
		},
		caption: {
			fontFamily: 'Klavika'
		}
	},
	MuiMenu: {
		paper: {
			border: `1px solid ${customTheme.palette.border.main}`,
			backgroundColor: customTheme.palette.dropdown.background,
			color: customTheme.palette.dropdown.text,
			fontFamily: 'Klavika !important',
		}
	},
	MuiMenuItem: {
		root: {
			fontFamily: 'Klavika',
			backgroundColor: customTheme.palette.dropdown.background,
			color: customTheme.palette.dropdown.text,

			'&:hover': {
				backgroundColor: customTheme.palette.dropdown.background,
				color: customTheme.palette.text.trueWhite,
			},
			'&$selected': {
				backgroundColor: customTheme.palette.dropdown.background,
				color: customTheme.palette.text.trueWhite,
				'&:hover': {
					backgroundColor: customTheme.palette.dropdown.background,
					color: customTheme.palette.text.trueWhite,
				},
			},
		}
	},
	MuiTextField: {
		root: {
			border: `1px solid ${customTheme.palette.border.main}`,
			borderRadius: '3px',
			height: '44px',
			fontFamily: 'Klavika',
			fontSize: '14px',
			lineHeight: '17px',

			'&:hover': {
				border: `1px solid ${customTheme.palette.border.main}`,
			}
		}
	},
	MuiInputBase: {
		root: {
   			height: '44px',
		},
		input: {
			fontFamily: 'Klavika',
			color: customTheme.palette.text.lightOn,
			padding: '0 8px',
   			height: '44px',
		}
	},
	MuiFormLabel: {
		root: {
			color: customTheme.palette.text.lightOn,
		}
	},
	MuiInputAdornment: {
		root: {
			color: customTheme.palette.icons.main
		}
	},
	MuiButton: {
		root: {
			color: customTheme.palette.text.trueWhite
		}
	},
	MuiAccordion: {
		root: {
			background: customTheme.palette.background.main,
			color: customTheme.palette.text.lightOn,
			fontFamily: 'Klavika',
			border: `1px solid ${customTheme.palette.border.main}`
		}
	},
	MuiAccordionSummary: {
		expandIcon: {
			color: customTheme.palette.text.lightOn,
		},
		content: {
			alignItems: 'center'
		}
	},
	MuiSnackbar: {
		root: {
			boxShadow: '0px 6px 10px rgb(0 231 174 / 25%)'
		}
	},
	MuiSnackbarContent: {
		root: {
			backgroundColor: customTheme.palette.background.panel,
			border: `1px solid ${customTheme.palette.border.main}`

		}
	},
	MuiTooltip: {
		tooltip: {
			backgroundColor: customTheme.palette.background.main,
			padding: '15px 20px',
			border: `1px solid ${customTheme.palette.border.main}`,
			fontSize: '14px',
			fontFamily: 'Klavika',
		},
		arrow: {
			color: customTheme.palette.background.main,

			'&::before': {
				border: `1px solid ${customTheme.palette.border.main}`,
			}
		},
		tooltipPlacementRight: {
			margin: '0 22px !important'
		}
	}
};

customTheme.props = {
	MuiSelect: {
		MenuProps: {
			anchorOrigin: {
				vertical: "bottom",
				horizontal: "left"
			},
			getContentAnchorEl: null
		}
	}
};

export default customTheme;