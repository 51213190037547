import { Button, ButtonGroup, Divider, Grid } from '@material-ui/core';
import ButtonPrimary from '../../UI/Buttons/ButtonPrimary/ButtonPrimary';
import ButtonLightOnOutlined from './../../UI/Buttons/ButtonLightOnOutlined/ButtonLightOnOutlined';
import CoinIcon from './../../UI/Icons/CoinIcon/CoinIcon';
import { useStyles } from './BuyPackDialogContentStyle';

const BuyPackDialogContent = (props) => {
	const classes = useStyles();

	return (
		<div className={classes.buyPackDialog}>
			<Grid container item xs={12}>
				<Grid item xs={12} sm={6}>
					<img src={props.img} alt="Pack img" className={classes.packImg} />
				</Grid>
				<Grid item xs={12} sm={6} className={classes.quantityBox}>
					<p className={classes.name}>{props.name}</p>
					<p className={classes.description}>Quantity</p>
					<ButtonGroup size="large" className={classes.quantityButtons} aria-label="small outlined button group">
						<Button disabled={props.quantity === 1} onClick={() => props.setQuantity(props.quantity-1)}>-</Button>
						<Button disabled>{props.quantity}</Button>
						<Button className="plus-button" onClick={() => props.setQuantity(props.quantity+1)}>+</Button>
					</ButtonGroup>
				</Grid>
			</Grid>
			<Divider flexItem className={classes.divider} />
			<p className={classes.question}>Are you sure you want to buy this item?</p>
			<p className={classes.description}>The amount will be debited from your balance:</p>
			<p><CoinIcon />&nbsp;{props.price}</p>
			<ButtonLightOnOutlined className={classes.cancelButton} onClick={props.onClose}>CANCEL</ButtonLightOnOutlined>
			<ButtonPrimary onClick={props.handleBuyButtonClick}>BUY NOW</ButtonPrimary>
		</div>
	);
}

export default BuyPackDialogContent;