import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
	paymentMethodItem: {
		padding: theme.spacing(2),
		minHeight: '150px',
		display: 'flex',
		justifyContent: 'center',
		position: 'relative',

		[theme.breakpoints.down("xs")]: {
    		minHeight: '110px',
        }
	},
	paymentMethodItemActive: {
		background: 'linear-gradient(180deg, rgba(0, 231, 174, 0) -66.56%, rgba(0, 231, 174, 0.13) 127.91%)',
	},
	paymentMethodActiveLine: {
		height: '4px',
		width: '50%',
		background: theme.palette.primary.main,
		margin: '0 !important',
		position: 'absolute',
		bottom: 0,

		[theme.breakpoints.down("xs")]: {
			left: 0,
			width: '4px',
			height: '100%',
		}
	},
	divider: {
		border: `0.5px solid ${theme.palette.border.main} !important`,
		margin: '0 !important',

		[theme.breakpoints.down("xs")]: {
			width: '100%'
		}
	}
}));

export { useStyles };