import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
	packsBox: {
        marginLeft: `-${theme.spacing(3)}px`,
        marginRight: `-${theme.spacing(3)}px`,
		padding: theme.spacing(2),
		background: theme.palette.background.panel,
		borderTop: `1px solid ${theme.palette.border.main}`,
		borderBottom: `1px solid ${theme.palette.border.main}`
	},
	sectionsBox: {
		[theme.breakpoints.down("md")]: {
			flexDirection: 'column-reverse'
		}
	}
}));

export { useStyles };