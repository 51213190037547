import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Grid } from '@material-ui/core';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { ReactComponent as BackpackIcon } from '../../../assets/img/backpack.svg';
import { fetchAllYourCards } from '../../../store/slices/library/AllYourCardsSlice';
import { sellCardValidationSchema } from "../../../utils/validations/sellCardValidation";
import ButtonPrimary from '../../UI/Buttons/ButtonPrimary/ButtonPrimary';
import ButtonPrimaryOutlined from '../../UI/Buttons/ButtonPrimaryOutlined/ButtonPrimaryOutlined';
import PageHeader from '../../UI/PageHeader/PageHeader';
import AllYourCardsFiltersBox from './AllYourCardsFiltersBox/AllYourCardsFiltersBox';
import { useStyles } from './AllYourCardsStyle';
import AllYourCardsView from './AllYourCardsView/AllYourCardsView';
import YourCardsTableSell from './YourCardsTableSell/YourCardsTableSell';

const AllYourCards = props => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const [name, setName] = useState('');
	const [rarity, setRarity] = useState('');
	const [organization, setOrganization] = useState('');
	const [priceSort, setPriceSort] = useState('');
	const [popularitySort, setPopularitySort] = useState('');
	const [selectedCards, setSelectedCards] = useState([]);
	const [showSellTable, setShowSellTable] = useState();
	const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
	const sellCardsForm = useForm({
		resolver: yupResolver(sellCardValidationSchema)
	});

	useEffect(() => {
		const filters = {
			name: name,
			priceSort: priceSort,
			popularitySort: popularitySort,
			rarity: rarity,
			organization: organization
		};
		dispatch(fetchAllYourCards(filters));
	}, [name, priceSort, popularitySort, rarity, organization, dispatch]);

	const createOffer = (data) => {
		// TODO POST sell cards
		console.log(data, data.sell.map(card => ({
			id: card.id,
			price: card.sellPrice,
			quantity: card.sellQuantity
		})));
		setOpenConfirmationDialog(true);
	};

	const handleCloseConfirmationDialog = () => {
		setOpenConfirmationDialog(false);
		setSelectedCards([]);
		setShowSellTable(false);
	};

	const handlSellOnMarketplaceClick = () => {
		if (selectedCards.length) {
			sellCardsForm.setValue("sell", selectedCards);
			setShowSellTable(true);
		}
	}

	return (
		<>
			<PageHeader title="all your items" icon={<BackpackIcon />} >
				<ButtonPrimaryOutlined className={classes.backButton} component={Link} to="/library">BACK TO LIBRARY</ButtonPrimaryOutlined>
			</PageHeader>
			{!showSellTable && (<>
				<AllYourCardsFiltersBox
					setName={setName}
					rarity={rarity}
					setRarity={setRarity}
					organization={organization}
					setOrganization={setOrganization}
					priceSort={priceSort}
					setPriceSort={setPriceSort}
					popularitySort={popularitySort}
					setPopularitySort={setPopularitySort}
					handlSellOnMarketplaceClick={handlSellOnMarketplaceClick}
					selectedCards={selectedCards}
				/>
				<AllYourCardsView
					selectedCards={selectedCards}
					setSelectedCards={setSelectedCards}
				/>
			</>)}
			{showSellTable && (
				<FormProvider {...sellCardsForm}>
					<form onSubmit={sellCardsForm.handleSubmit(createOffer)}>
						<Grid item xs={12} className={classes.createOfferButtonBox}>
							<div className="display-flex">
								<Button onClick={() => setShowSellTable(false)}>
									<NavigateBeforeIcon />BACK
								</Button>
							</div>
							<div className="flex-grow-full" />
							<p>If you are ready, create an offer on the marketplace</p>
							<ButtonPrimary type="submit">CREATE AN OFFER</ButtonPrimary>
						</Grid>
						<YourCardsTableSell
							openConfirmationDialog={openConfirmationDialog}
							handleCloseConfirmationDialog={handleCloseConfirmationDialog}
						/>
					</form>
				</FormProvider>
			)}
		</>
	);
}

export default AllYourCards;