import TickInsideCircleImg from '../../../../assets/img/tick-inside-circle.svg';
import ButtonPrimary from '../../../UI/Buttons/ButtonPrimary/ButtonPrimary';
import { useStyles } from './ConfirmBuyCardDialogContentStyle';

const ConfirmBuyCardDialogContent = (props) => {
	const classes = useStyles();

    return (
        <div className={classes.confirmBuyCardDialogContent}>
			<br />
			<img src={TickInsideCircleImg} alt="OK" />
			<p className={classes.description}><span className={classes.cool}>Success!</span> The item has been added to your library</p>
			<br />
			<ButtonPrimary onClick={props.onClose}>OK</ButtonPrimary>
		</div>
    );
}

export default ConfirmBuyCardDialogContent;