import { Grid } from "@material-ui/core";
import { NavLink } from "react-router-dom";
import BadgeRed from './../UI/Badges/BadgeRed/BadgeRed';
import { useStyles } from "./CardBoxStyle";
import CirclePrimary from './../UI/Badges/CirclePrimary/CirclePrimary';
import clsx from 'clsx';
import AnimatedCard from "../AnimatedCard/AnimatedCard";

const CardBox = (props) => {
	const classes = useStyles(props);

	return (
		<Grid item xs className={clsx(classes.cardBox, props.className)} onClick={props.handleClick}>
			<hr className={classes.colorLine} />
			<div className={classes.cardDiv}>
				<AnimatedCard 
					card={props.card?.card || props.card}
					size={props.cardSize ? props.cardSize : "sm" }
					noRotate />
				{props.tag && <BadgeRed className={classes.tag}>{props.tag}</BadgeRed>}
				{props.quantity > 1 && <CirclePrimary className={classes.quantity}>{props.quantity}</CirclePrimary>}
			</div>
			{!props.shortInfo && (<>
				<p className={classes.cardInfo}>
					<span className={classes.cardRarity}>{props.card?.card?.cardType?.name}</span>&nbsp;&nbsp;&nbsp;
					<span className={classes.collection}>{props.card?.card?.cardCollection?.name}</span>
				</p>
				<h3 className={classes.name}>{props.card?.card?.name}</h3>
				<div className={classes.flexGrow}></div>
				<div className={classes.priceBox}>
					<p>FROM {Number(props.card?.price).toFixed(2)} PLN</p>
					<div className={classes.flexGrow}></div>
					<p className={classes.viewOnMarketLink}>
						<NavLink to={"/marketplace/" + props.card?.card?.id}>VIEW LISTINGS</NavLink>
					</p>
				</div>
			</>)}
		</Grid>
	);
}

export default CardBox;