import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
	showMyCardsButton: {
		marginLeft: theme.spacing(3)
	},
	library: {
		marginLeft: `-${theme.spacing(3)}px`,
		marginRight: `-${theme.spacing(3)}px`,
		marginBottom: `-${theme.spacing(3)}px`,
		width: 'auto',
		fontFamily: 'Klavika',
		
		[theme.breakpoints.up("md")]: {
			marginTop: 70,
		}
	},
	headerFixed: {
		borderBottom: `1px solid ${theme.palette.border.main}`,

		[theme.breakpoints.up("md")]: {
			position: 'fixed',
			zIndex: 9,
			width: 'calc(100% - 123px)',
		}
	}
}));

export { useStyles };