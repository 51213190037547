import { FormControl, MenuItem, Select } from '@material-ui/core';
import clsx from 'clsx';
import { forwardRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setFilterOrganization, setFilterRarity, setPopularitySort, setPriceSort } from '../../../store/slices/cards/CardsSlice';
import { underscoreToSpace, upperCase } from '../../../utils/text-format';
import { useStyles } from './MarketplaceFilterItemsStyle';

const MarketplaceFilterItems = forwardRef((props, ref) => {
	const classes = useStyles();

	const dispatch = useDispatch();
	const cardTypes = useSelector((state) => state.cardTypes.data);
	const organizations = useSelector((state) => state.organizations.data);
	const rarity = useSelector((state) => state.cards.filter.rarity);
	const organization = useSelector((state) => state.cards.filter.organization);
	const priceSort = useSelector((state) => state.cards.sort.price);
	const popularitySort = useSelector((state) => state.cards.sort.popularity);

	return (<>
		<p className={classes.label}>PRICE:</p>
		<FormControl variant="outlined" className={classes.formControl}>
			<Select
				defaultValue=""
				className={clsx(classes.select, classes.font)}
				value={priceSort}
				onChange={event => dispatch(setPriceSort(event.target.value))}
			>
				<MenuItem value="">&nbsp;</MenuItem>
				<MenuItem value="asc">MIN-MAX</MenuItem>
				<MenuItem value="desc">MAX-MIN</MenuItem>
			</Select>
		</FormControl>
		<p className={classes.label}>POPULARITY:</p>
		<FormControl variant="outlined" className={classes.formControl}>
			<Select
				defaultValue=""
				className={clsx(classes.select, classes.font)}
				value={popularitySort}
				onChange={event => dispatch(setPopularitySort(event.target.value))}
			>
				<MenuItem value="">&nbsp;</MenuItem>
				<MenuItem value="asc">MIN-MAX</MenuItem>
				<MenuItem value="desc">MAX-MIN</MenuItem>
			</Select>
		</FormControl>
		<p className={classes.label}>ORGANIZATION:</p>
		<FormControl variant="outlined" className={classes.formControl}>
			<Select
				defaultValue=""
				className={clsx(classes.select, classes.font)}
				value={organization}
				onChange={event => dispatch(setFilterOrganization(event.target.value))}
			>
				<MenuItem value="">&nbsp;</MenuItem>
				{organizations?.map(organization => (
					<MenuItem key={organization.id} value={organization.id}>
						{upperCase(underscoreToSpace(organization.name))}
					</MenuItem>
				))}
			</Select>
		</FormControl>
		<p className={classes.label}>RARITY:</p>
		<FormControl variant="outlined" className={classes.formControl}>
			<Select
				defaultValue=""
				className={clsx(classes.select, classes.font)}
				value={rarity}
				onChange={event => dispatch(setFilterRarity(event.target.value))}
			>
				<MenuItem value="">&nbsp;</MenuItem>
				{cardTypes?.map(cardType => (
					<MenuItem key={cardType.id} value={cardType.id}>
						{upperCase(underscoreToSpace(cardType.name))}
					</MenuItem>
				))}
			</Select>
		</FormControl>
	</>);
});

export default MarketplaceFilterItems;