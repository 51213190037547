import { useStyles } from "./UserProfileDeleteAccountStyle";
import { Grid } from '@material-ui/core';
import ButtonDanger from "../../UI/Buttons/ButtonDanger/ButtonDanger";
import clsx from 'clsx';

const UserProfileDeleteAccount = props => {
    const classes = useStyles();

    return (
        <Grid item xs={12} className={clsx("panel-fill", classes.deleteAccountBox)}>
			<p className={classes.deleteAccountTitle}>Do you want to remove your account from the site?</p>
			<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Id et, hendrerit aliquet odio. Nulla mattis morbi amet quis nunc, ante feugiat in. Pellentesque arcu euismod placerat odio in libero ipsum. Viverra sollicitudin ornare et imperdiet egestas sit. Amet purus diam sit enim amet augue consectetur. Purus, aliquam tempor praesent duis ut in.</p>
			<ButtonDanger>DELETE ACCOUNT</ButtonDanger>
		</Grid>
    );
};

export default UserProfileDeleteAccount;