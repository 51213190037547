import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../../../shared/axios-client';

export const fetchOrganizations = createAsyncThunk(
	'organizations/fetch',
	async () => {
		const response = await axios.get('organisations');
		return response.data.data;
	}
);

export const OrganizationsSlice = createSlice({
	name: 'organizations',
	initialState: {
		status: "init",
		data: [],
		error: null,
	},
	reducers: {},
	extraReducers: {
		[fetchOrganizations.pending.type]: (state, action) => {
			state.status = "pending";
			state.data = [];
			state.error = null;
		},
		[fetchOrganizations.fulfilled.type]: (state, action) => {
			state.status = "fulfilled";
			state.data = action.payload;
			state.error = null;
		},
		[fetchOrganizations.rejected.type]: (state, action) => {
			state.status = "rejected";
			state.data = [];
			state.error = action.error.message;
		},
	}
});

export default OrganizationsSlice.reducer;