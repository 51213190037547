import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
	backButton: {
		marginLeft: theme.spacing(3),

		'& .MuiButton-label': {
			whiteSpace: 'nowrap'
		}
	},
	createOfferButtonBox: {
		display: 'flex',
		margin: `${theme.spacing(2)}px 0`,

		[theme.breakpoints.only("xs")]: {
			display: 'block',
			textAlign: 'center'
		},
		'& p': {
			[theme.breakpoints.up("sm")]: {
				marginRight: theme.spacing(2)
			}
		}
	}
}));

export { useStyles };