import { CircularProgress, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AboutGameBg from '../assets/img/about_game_bg.webp';
import CollectionBg from '../assets/img/collection_bg.webp';
import CraftingBg from '../assets/img/crafting_bg.webp';
import HomeBg from '../assets/img/home_bg.webp';
import MarketplaceBg from '../assets/img/marketplace_bg.webp';
import { fetchLandingPageCards } from '../store/slices/landing-page/LandingPageSlice';
import AboutGame from './AboutGame/AboutGame';
import Collection from './Collection/Collection';
import Crafting from './Crafting/Crafting';
import Footer from './Footer/Footer';
import GoToTop from './GoToTop/GoToTop';
import Header from './Header/Header';
import Home from './Home/Home';
import Marketplace from './Marketplace/Marketplace';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: '#070A14 !important',
	    color: '#ffffff',
        margin: 0,
	    fontFamily: 'Montserrat',

		'& .MuiButton-root': {
		    fontFamily: 'Montserrat !important',
			fontWeight: 600,
		}
    },
	homeBox: {
		backgroundImage: `url(${HomeBg})`,
		backgroundSize: '100% 100%',

		[theme.breakpoints.up('md')]: {
			height: '100vh'
		}
	},
	heightFullScreen: {
		[theme.breakpoints.up('md')]: {
			height: 'calc(100vh - 100px)'
		}
	},
	aboutGame: {
		backgroundImage: `url(${AboutGameBg})`,
		backgroundSize: '100% 100%',
	},
	collection: {
		backgroundImage: `linear-gradient(282.43deg, #0C1120 5.85%, rgba(12, 17, 32, 0.45) 49.08%, #0C1120 85.66%), url(${CollectionBg})`,
		backgroundSize: '100% 100%',
		backgroundBlendMode: 'luminosity',
		backgroundColor: theme.palette.background.main,
	},
	crafting: {
		[theme.breakpoints.up('md')]: {
			backgroundImage: `url(${CraftingBg})`,
			backgroundSize: '100% 100%',
		}
	},
	marketplace: {
		backgroundImage: `url(${MarketplaceBg})`,
		backgroundSize: '100% 100%',
	}
}));

const LandingPage = () => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const landingPageCardsStatus = useSelector(state => state.landingPageCards.status);

	console.log('test');
	
	useEffect(() => {
		if (landingPageCardsStatus === 'init') {
			dispatch(fetchLandingPageCards());
		}
	}, [landingPageCardsStatus, dispatch]);

	return (
		<div className={classes.root}>
			{(landingPageCardsStatus === 'init' || landingPageCardsStatus === 'pending') && <div className="text-align-center"><CircularProgress /></div>}
			{landingPageCardsStatus === 'fulfilled' && <>
				<div id="home" className={classes.homeBox}>
					<Header />
					<Home />
				</div>
				<div id="about-game" className={clsx(classes.aboutGame, classes.heightFullScreen)}>
					<AboutGame />
				</div>
				<div id="collection" className={clsx(classes.collection, classes.heightFullScreen)}>
					<Collection />
				</div>
				<div id="crafting" className={clsx(classes.crafting, classes.heightFullScreen)}>
					<Crafting />
				</div>
				<div id="marketplace" className={clsx(classes.marketplace, classes.heightFullScreen)}>
					<Marketplace />
				</div>
				<div className="position-relative">
					<Footer />
					<GoToTop />
				</div>
			</>}
		</div>
	);
}

export default LandingPage;
