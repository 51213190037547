import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../../shared/axios-client';

export const fetchCards = createAsyncThunk(
	'cards/fetch',
	async ({name = null, tag = null, priceSort = null, popularitySort = null, rarity = null, organization = null}) => {
		const response = await axios.get('generated_cards', { params: {
			forSale: true, 
			nameIncludes: name,
			tag: tag,
			priceSort: priceSort,
			popularitySort: popularitySort,
			'byCardTypeIds[]': rarity,
			'byOrganisationIds[]': organization,
			view: 'full_nested',
			limit: 100 // TODO paginacja
		 }});
		return response.data.data;
	}
);

export const CardsSlice = createSlice({
	name: 'cards',
	initialState: {
		status: "init",
		error: null,
		data: [],
		filter: {
			name: '',
			need: false,
			rarity: '',
			organization: ''
		},
		sort: {
			price: '',
			popularity: '',
		}
	},
	reducers: {
		setFilterName: (state, action) => {
			state.filter.name = action.payload;
		},
		setFilterNeed: (state, action) => {
			state.filter.need = action.payload;
		},
		setPriceSort: (state, action) => {
			state.sort.price = action.payload;
		},
		setPopularitySort: (state, action) => {
			state.sort.popularity = action.payload;
		},
		setFilterRarity: (state, action) => {
			state.filter.rarity = action.payload;
		},
		setFilterOrganization: (state, action) => {
			state.filter.organization = action.payload;
		}
	},
	extraReducers: {
		[fetchCards.pending.type]: (state, action) => {
			state.status = "pending";
			state.error = null;
			state.data = [];
		},
		[fetchCards.fulfilled.type]: (state, action) => {
			state.status = "fulfilled";
			state.error = null;
			state.data = action.payload;
		},
		[fetchCards.rejected.type]: (state, action) => {
			state.status = "rejected";
			state.error = action.error.message;
			state.data = [];
		},
	}
});

export const { setFilterName, setFilterNeed, setPriceSort, setPopularitySort, setFilterRarity, setFilterOrganization } = CardsSlice.actions;

export default CardsSlice.reducer;