import { makeStyles } from '@material-ui/core';
import { ReactComponent as ArrowIcon } from '../../assets/img/arrow.svg';
import { scrollToElement } from './../../utils/scrollToElement';

const useStyles = makeStyles((theme) => ({
	goToTop: {
		position: 'absolute',
		bottom: 0,
		top: 0,
		right: 0,
		width: 77,
		height: '100%',
		backgroundColor: theme.palette.border.main,
		cursor: 'pointer',

		[theme.breakpoints.down('md')]: {
			height: 77,
			width: '100%',
		}
	},
	text: {
		fontWeight: 600,
		fontSize: '14px',
		lineHeight: '17px',
		letterSpacing: '1.25px',
		color: '#DCDDDD',
		whiteSpace: 'nowrap',
		position: 'relative',

		[theme.breakpoints.up('lg')]: {
			transform: 'rotate(-90deg)',
			marginTop: 115
		},
		[theme.breakpoints.down('md')]: {
			textAlign: 'center',
			margin: '29px auto'
		}
	},
	arrow: {
		width: 26,
		height: 26,
		position: 'absolute',
		top: 23,
		left: 25,
		animation: 'pulse 2s infinite',

		[theme.breakpoints.down('md')]: {
			top: 25,
			left: 0,
			right: -143,
			margin: 'auto',
		},
	}
}));

const GoToTop = () => {
	const classes = useStyles();

	return (
		<div className={classes.goToTop} onClick={() => scrollToElement('home')}>
			<p className={classes.text}>GO TO TOP</p>
			<ArrowIcon className={classes.arrow} />
		</div>
	);
}

export default GoToTop;