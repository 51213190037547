import { makeStyles } from '@material-ui/core/styles';
import KSWBackgroundMD from '../../../assets/img/ksw-poster-md.svg';
import KSWBackgroundSM from '../../../assets/img/ksw-poster-sm.svg';

const useStyles = makeStyles((theme) => ({
    eventBox: {
		backgroundImage: `url(${KSWBackgroundSM}), linear-gradient(270deg, #0C1121 -2.37%, rgba(11, 255, 34, 0.26) 49.88%, #0C3021 100%)`,
		backgroundPosition: 'center',
		backgroundRepeat: 'no-repeat',
		backgroundSize: 'cover',
        borderRadius: '3px',
        border: '1px solid',
        borderImageSource: 'linear-gradient(90deg, #50BC1D -2.08%, rgba(64, 173, 13, 0.51) 100.49%)',
        borderImageSlice: 1,
        display: 'flex',
        flexDirection: 'row',
		padding: theme.spacing(1),
		textAlign: 'center',
		fontFamily: 'Klavika',
		position: 'relative',

        [theme.breakpoints.up("md")]: {
			backgroundImage: `url(${KSWBackgroundMD}), linear-gradient(270deg, #0C1121 -2.37%, rgba(11, 255, 34, 0.26) 49.88%, #0C3021 100%)`,
			paddingLeft: theme.spacing(3),
			paddingRight: theme.spacing(3),
		}
    },
	punchdownTotalbetImgBox: {
		margin: 'auto',

		[theme.breakpoints.up('lg')]: {
			textAlign: 'left'
		}
	},
    punchdownTotalbetImg: {
        height: '31px'
    },
	eventStartsText: {
        [theme.breakpoints.down("md")]: {
			marginBottom: 0,
			marginRight: theme.spacing(1)
		}
	},
	closeButton: {
		position: 'absolute',
		top: '0px',
		right: '0px',
	},
	ppvBox: {
		[theme.breakpoints.up('md')]: {
			justifyContent: 'flex-end'
		}
	},
	eventStartsBox: {
		[theme.breakpoints.up('md')]: {
			justifyContent: 'flex-start'
		}
	},
	timeCountdown: {
		[theme.breakpoints.only("xs")]: {
			width: '100%',
			'& div div': {
				justifyContent: 'center'
			}
		}
	}
}));

export { useStyles };