import { Divider, Grid } from '@material-ui/core';
import { ReactComponent as CardsImg } from '../../../assets/img/card.svg';
import CircleProgressBar from '../../UI/ProgressBars/CircleProgressBar/CircleProgressBar';
import { useStyles } from './CollectionInfoBoxStyle';
import PunchdownCollectionImg from '../../../assets/img/punchdown-collection.jpg';

const CollectionInfoBox = props => {
	const classes=  useStyles();

	return (
		<div className={classes.box}>
			<Grid container item xs={12} className={classes.collectionInfoBox}>
				<img src={PunchdownCollectionImg} alt="Background img" className={classes.backgroundImg} />
				<div className={classes.linearGradient}></div>
				<div className={classes.collectionInfo}>
					<p className={classes.name}>{props.organizationName}: {props.collection.name}</p>
					<div className={classes.cardAmount}>
						<CardsImg />
						<span className={classes.myCardsAmount}>3</span>
						/
						<span>100</span>
					</div>
				</div>
				<div className="flex-grow-full" />
				<div className={classes.circularProgresBox}>
					<div className={classes.circularProgres}>
						<CircleProgressBar value={40} size={54} thickness={4} />
					</div>
				</div>
			</Grid>
			<Grid item xs={12} className={classes.bottomInfo}>
				<span>{props.collection.name}</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
				<div className={classes.cardAmount}>
					<CardsImg />
					<span className={classes.myCardsAmount}>3</span>
					/
					<span>100</span>
				</div>
				<Divider orientation="vertical" className={classes.divider} />
				<span className={classes.collectionText}>COLLECTION: </span>
				<span className={classes.collectionPercentage}>10%</span>
			</Grid>
		</div>
	);
}

export default CollectionInfoBox;