import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import moment from "moment";

const newDate = (days) => {
	return moment().add(days, 'M').format('MM-YYYY');
}

export const fetchCardStatistics = createAsyncThunk(
	'cardStatistics/fetch',
	async ({cardId}) => {
		// TODO 
		// const response = await axios.get(`card/${cardId}/statistics`);
		// return response.data.data;

		const cardStatistics = {
			date: [newDate(5), newDate(6), newDate(7), newDate(8), newDate(9), newDate(10), newDate(11), newDate(12), newDate(13), newDate(14), newDate(15), newDate(16), newDate(17), newDate(18), newDate(19), newDate(20), newDate(21), newDate(22), newDate(23), newDate(24)],
			priceAvg: [12, 19, 13, 15, 12, 13, 11, 21, 13, 14, 15.5, 16, 17, 20, 13, 16, 11, 12, 13, 14]
		}
		return cardStatistics;
	}
);

export const CardStatisticsSlice = createSlice({
    name: 'cardStatistics',
    initialState: {
        status: "init",
		error: null,
		data: {}
    },
    reducers: {},
    extraReducers: {
        [fetchCardStatistics.pending.type]: (state, action) => {
			state.status = "pending";
			state.error = null;
			state.data = {};
		},
		[fetchCardStatistics.fulfilled.type]: (state, action) => {
			state.status = "fulfilled";
			state.error = null;
			state.data = action.payload;
		},
		[fetchCardStatistics.rejected.type]: (state, action) => {
			state.status = "rejected";
			state.error = action.error.message;
			state.data = {};
		}
    }
});

export default CardStatisticsSlice.reducer;