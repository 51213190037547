import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    arrowRight: {
		transform: 'rotate(-90deg)',
	},
	arrowLeft: {
		transform: 'rotate(90deg)',
	},
	arrowButton: {
		margin: '8px',
		padding: '5px 10px',
		minWidth: 'auto',
		width: '39px',
		height: '39px',
	}
}));

export { useStyles };