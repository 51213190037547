import { Accordion, AccordionDetails, AccordionSummary, Divider, Grid, Hidden, makeStyles, Typography } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import clsx from 'clsx';
import { useDispatch } from 'react-redux';
import { ReactComponent as GooglePayIcon } from '../../assets/img/GooglePay.svg';
import { ReactComponent as LogoIcon } from '../../assets/img/logo.svg';
import { ReactComponent as PayPalIcon } from '../../assets/img/Paypal.svg';
import { ReactComponent as PaySafeCardIcon } from '../../assets/img/paysafecard.svg';
import { ReactComponent as Przelewy24Icon } from '../../assets/img/Przelewy24.svg';
import { setSignInDialogOpen, setSignUpDialogOpen } from '../../store/slices/dialogs/DialogsSlice';
import { scrollToElement } from './../../utils/scrollToElement';

const useStyles = makeStyles((theme) => ({
	footer: {
		maxWidth: 1410,
		margin: 'auto',
		fontSize: 14,
		letterSpacing: '0.25px',
		lineHeight: '17px',
		color: theme.palette.text.trueWhite,

		[theme.breakpoints.down('md')]: {
			padding: theme.spacing(2),
			paddingTop: 103
		},
		[theme.breakpoints.up('lg')]: {
			paddingTop: 82.5,
		},
	},
	logo: {
		width: 80,
		height: 85,
	},
	descriptionBox: {
		color: '#DCDDDD',
		lineHeight: '24px',
	},
	description: {
		maxWidth: '350px !important',

		"& a": {
			color: '#DCDDDD',
			textDecoration: 'none'
		}
	},
	brandIconsBox: {
		display: 'flex',
		color: theme.palette.icons.main
	},
	brandIcon: {
		border: `2px solid ${theme.palette.icons.main}`,
		borderRadius: '50%',
		width: 38,
		height: 38,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		marginRight: theme.spacing(2),
		marginBottom: theme.spacing(2),

		'&:hover': {
			border: `2px solid ${theme.palette.primary.main}`,
			
			'& svg': {
				fill: theme.palette.primary.main,
			}
		},
		'& svg': {
			width: 16,
			height: 16,
			fill: theme.palette.icons.main
		}
	},
	divider: {
		width: '100%',
		height: 1,
		backgroundColor: theme.palette.border.main,
		margin: '21px 0 !important'
	},
	title: {
		fontSize: 16,
		fontWeight: 'bold',

		[theme.breakpoints.only('md')]: {
			fontSize: 14
		},
	},
	accordion: {
		width: '100%'
	},
	paymentIcons: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-end',
		
		[theme.breakpoints.down('sm')]: {
			justifyContent: 'space-around'
		},
		'& svg': {
			width: 84,
			height: 58,

			[theme.breakpoints.up('md')]: {
				margin: '0 17px'
			},
			[theme.breakpoints.down('md')]: {
				width: 55,
				height: 36,
			}
		},
	},
	footerBottom: {
		[theme.breakpoints.only('xs')]: {
			textAlign: 'center'
		},
		[theme.breakpoints.up('lg')]: {
			marginBottom: 20
		}
	},
	signUp: {
		fontSize: 16,
		fontWeight: 'bold',
		color: theme.palette.primary.main
	}
}));

const Footer = () => {
	const classes = useStyles();
	const dispatch = useDispatch();

	return (
		<Grid container className={classes.footer}>
			<Grid item xs={12} md={5} className={classes.descriptionBox}>
				<LogoIcon className={classes.logo} />
				<div className={classes.description}>
					<p>
						Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam ut adipiscing gravida sit nulla consequat.
					</p>
					<p>
						<b>MartialCards</b><br />
						ul. Example 30<br />
						00-300 Warszawa<br />
						<a href="mailto:kontakt@martialcards.pl">kontakt@martialcards.pl</a>
					</p>
				</div>
				<div className={classes.brandIconsBox}>
					<a href="https://www.facebook.com/mcards.official" target="_blank" rel="noopener noreferrer" className={classes.brandIcon}>
						<FacebookIcon />
					</a>
					<div className={classes.brandIcon}>
						<InstagramIcon />
					</div>
				</div>
			</Grid>
			<Grid container item xs={12} md={7}>
				<Grid item xs={12} md={3}>
					<p className={classes.title}>MENU</p>
					<p onClick={() => scrollToElement('home')} className="cursor-pointer">Home</p>
					<p onClick={() => scrollToElement('about-game')} className="cursor-pointer">About game</p>
					<p onClick={() => scrollToElement('collection')} className="cursor-pointer">Collection</p>
					<p onClick={() => scrollToElement('crafting')} className="cursor-pointer">Crafting</p>
					<p onClick={() => scrollToElement('marketplace')} className="cursor-pointer">Marketplace</p>
				</Grid>
				<Hidden smDown>
					<Grid item xs={3}>
						<p className={classes.title}>ORGANIZATIONS</p>
						<p>Punchdown 4</p>
						<p>KSW</p>
						<p>FAME MMA</p>
						<p>Influencers</p>
					</Grid>
					<Grid item xs={3}>
						<p className={classes.title}>HELPFUL LINKS</p>
						<p>Statute</p>
						<p>Privacy policy</p>
					</Grid>
					<Grid item xs={3}>
						<p className={classes.title}>JOIN US</p>
						<p className={clsx(classes.signUp, "cursor-pointer")} onClick={() => dispatch(setSignUpDialogOpen(true))}>Sign up</p>
						<p className="cursor-pointer" onClick={() => dispatch(setSignInDialogOpen(true))}>Sign in</p>
						<p className="cursor-pointer">Remind password</p>
					</Grid>
				</Hidden>
				<Hidden mdUp>
					<Accordion className={classes.accordion}>
						<AccordionSummary
							expandIcon={<ExpandMoreIcon />}
							aria-controls="panel1a-content"
							id="panel1a-header"
						>
							<Typography>ORGANIZATIONS</Typography>
						</AccordionSummary>
						<AccordionDetails>
							<p>Punchdown 4</p>
							<p>KSW</p>
							<p>FAME MMA</p>
							<p>Influencers</p>
						</AccordionDetails>
					</Accordion>
					<Accordion className={classes.accordion}>
						<AccordionSummary
							expandIcon={<ExpandMoreIcon />}
							aria-controls="panel1a-content"
							id="panel1a-header"
						>
							<Typography>HELPFUL LINKS</Typography>
						</AccordionSummary>
						<AccordionDetails>
							<p>Statute</p>
							<p>Privacy policy</p>
						</AccordionDetails>
					</Accordion>
					<Accordion className={classes.accordion}>
						<AccordionSummary
							expandIcon={<ExpandMoreIcon />}
							aria-controls="panel1a-content"
							id="panel1a-header"
						>
							<Typography>JOIN US</Typography>
						</AccordionSummary>
						<AccordionDetails>
							<p className={classes.signUp} onClick={() => dispatch(setSignUpDialogOpen(true))}>Sign up</p>
							<p onClick={() => dispatch(setSignInDialogOpen(true))}>Sign in</p>
							<p>Remind password</p>
						</AccordionDetails>
					</Accordion>
				</Hidden>
			</Grid>
			<Divider className={classes.divider} />
			<Grid container item xs={12} className={classes.footerBottom}>
				<Grid item xs={12} sm={6}>
					<p>ⓒ 2021 MartialCards. All Rights Reserved.</p>
				</Grid>
				<Grid item xs={12} sm={6} className={classes.paymentIcons}>
					<GooglePayIcon />
					<PayPalIcon />
					<PaySafeCardIcon />
					<Przelewy24Icon />
				</Grid>
			</Grid>
		</Grid>
	);
}

export default Footer;