import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../../../shared/axios-client';

export const fetchLibraryCollections = createAsyncThunk(
	'libraryCollections/fetch',
	async () => {
		const response = await axios.get('card_collections', { params: { 
			userCardsSort: true,
			view: 'extended'
		}});
		return response.data.data;
	}
);

export const LibraryCollectionsSlice = createSlice({
	name: 'libraryCollections',
	initialState: {
		status: "init",
		data: [],
		error: null,
		selectedCollection: ''
	},
	reducers: {
		setSelectedCollection: (state, action) => {
			state.selectedCollection = action.payload;
		}
	},
	extraReducers: {
		[fetchLibraryCollections.pending.type]: (state, action) => {
			state.status = "pending";
			state.data = [];
			state.error = null;
		},
		[fetchLibraryCollections.fulfilled.type]: (state, action) => {
			state.status = "fulfilled";
			state.data = action.payload;
			state.error = null;
			state.selectedCollection = action.payload[0]
		},
		[fetchLibraryCollections.rejected.type]: (state, action) => {
			state.status = "rejected";
			state.data = [];
			state.error = action.error.message;
		},
	}
});

export const { setSelectedCollection } = LibraryCollectionsSlice.actions;

export default LibraryCollectionsSlice.reducer;