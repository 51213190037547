import { Button, Grid, Hidden, makeStyles, useMediaQuery, useTheme } from "@material-ui/core";
import ArrowBackIosTwoToneIcon from '@material-ui/icons/ArrowBackIosTwoTone';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import clsx from 'clsx';
import { useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Carousel } from 'react-responsive-carousel';
import { ReactComponent as MouseIcon } from '../../assets/img/mouse.svg';
import { setSignUpDialogOpen } from "../../store/slices/dialogs/DialogsSlice";
import AnimatedCard from './../../components/AnimatedCard/AnimatedCard';

const useStyles = makeStyles((theme) => ({
	home: {
		paddingTop: 100,

		[theme.breakpoints.down('md')]: {
			paddingTop: 80
		},
	},
	carousel: {
		'& .carousel-root': {
			position: 'absolute',
			left: 0,
			top: 120,
			width: '100%',
			height: '100%',
			maxHeight: 550,

			'& .selected + .slide + .slide + .slide': {
				filter: 'brightness(0.2)'
			}
		},
		'& .slide': {
			height: 'calc(100vh - 200px)'
		},
		'& .slide  .animated-card': {
			margin: 'auto 0 !important',
			position: 'absolute',
			top: '50%',
			left: '50%',
			transform: 'translate(-50%, -50%)',
		},
		'& .slide:not(.slide.selected ~ .slide):not(.slide.selected)': {
			filter: 'brightness(0.2)',
		},
		[theme.breakpoints.only('xl')]: {
			'& .slide': {
				transform: `scale(1.2)`,
			},
			'& .selected + .slide': {
				transform: `scale(1.7)`,
			}
		},
		[theme.breakpoints.only('lg')]: {
			'& .slide': {
				transform: `scale(0.9)`,
			},
			'& .selected + .slide': {
				transform: `scale(1.3)`,
			}
		},
		[theme.breakpoints.only('md')]: {
			'& .slide': {
				transform: `scale(0.8)`,
			},
			'& .selected + .slide': {
				transform: `scale(1.2)`,
			},
			'& .selected + .slide + .slide': {
				filter: 'brightness(0.2)'
			},
		},
	},
	carouselSmall: {
		margin: `${theme.spacing(1)}px -${theme.spacing(2)}px`,
		
		[theme.breakpoints.only('xs')]: {
			height: 260,
			
			'& .slide': {
				transform: `scale(0.7)`,
			},
			'& .slide.selected': {
				transform: `scale(1)`,
			},
		},
		[theme.breakpoints.only('sm')]: {
			height: 300,
			
			'& .slide  .animated-card': {
				margin: 'auto 0 !important',
				position: 'absolute',
				top: '50%',
				left: '50%',
				transform: 'translate(-50%, -50%)',
			},
			'& .slide': {
				transform: `scale(0.9)`,
				height: 300
			},
			'& .slide.selected': {
				transform: `scale(1.2)`,
			},
		}
	},
	content: {
		maxWidth: 1410,
		margin: '0 auto',

		[theme.breakpoints.down('md')]: {
			padding: theme.spacing(2)
		},
		[theme.breakpoints.up('md')]: {
			flexDirection: 'column !important',
			justifyContent: 'center',
			minHeight: 'calc(100vh - 200px)'
		}
	},
	title: {
		fontSize: 48,
		fontWeight: 800,
		margin: 0,
		marginBottom: 22,
		position: 'relative',

		[theme.breakpoints.down('md')]: {
			marginBottom: 10,
			fontSize: 28
		}
	},
	subtitle: {
		fontSize: 24,
		marginTop: 0,
		marginBottom: 22,
		position: 'relative',

		[theme.breakpoints.down('md')]: {
			marginBottom: 10,
		}
	},
	buttonsBox: {
		display: 'flex',
		alignItems: 'center',
		padding: `${theme.spacing(2)}px 0`,

		[theme.breakpoints.down('sm')]: {
			flexDirection: 'column',
			textAlign: 'center',
			alignItems: 'normal'
		}
	},
	mouseBox: {
		position: 'absolute',
		bottom: '5px',
		left: 0,
		right: 0,
		textAlign: 'center',
		animation: 'pulse 2s infinite',

		[theme.breakpoints.down('md')]: {
			display: 'none'
		}
	},
	mouseText: {
		fontWeight: 'bold',
		fontSize: 14,
		lineHeight: '17px'
	},
	orText: {
		margin: '10px 15px',
		fontSize: 14,
		zIndex: 1
	},
	arrowButton: {
		borderRadius: '50%',
		width: 68,
		height: 68,
		border: `3px solid ${theme.palette.icons.main}`,
		color: theme.palette.text.trueWhite,

		'&:hover': {
			border: `3px solid ${theme.palette.primary.main}`,
			background: theme.palette.primary.main,
			color: '#12192E',
		}
	},
	leftArrowButton: {
		position: 'absolute',
		top: '50%',
		left: 80,
		transform: 'translate(-50%, -50%)',

		'& .MuiButton-label': {
			marginRight: 3
		}
	},
	rightArrowButton: {
		position: 'absolute',
		top: '50%',
		right: 0,
		transform: 'translate(-50%, -50%)',
	},
	openFreePackButton: {
		backgroundColor: '#D6B313',

		'&:hover': {
			backgroundColor: '#D6B313',
		}
	}
}));

const Home = () => {
	const classes = useStyles();
    const dispatch = useDispatch();
	const theme = useTheme();
	const isSmWidth = useMediaQuery(theme.breakpoints.only('sm'));
	const isMdWidth = useMediaQuery(theme.breakpoints.only('md'));
	const cards = useSelector(state => state.landingPageCards.data);
	const cardSlides = [...cards, ...cards, ...cards];
	const [currentIndex, setSlide] = useState(0);

	const prevButton = () => {
		if (currentIndex === 0) {
			setSlide(cardSlides.length - 3);
		} else {
			setSlide(currentIndex - 1);
		}
	};
	
	const nextButton = () => {
		if (currentIndex === cardSlides.length - 3) {
			setSlide(0);
		} else {
			setSlide(currentIndex + 1);
		}
	};

	const onChangeCarousel = index => {
		if (index === cardSlides.length - 3) {
			setSlide(0);
		} else {
			setSlide(index);
		}
	};

	return (
		<div className={classes.home}>
			<Grid container className={classes.content}>
				<Grid item xs={12}>
					<Hidden smDown>
						<div className={classes.carousel}>
							<Carousel
								centerMode
								infiniteLoop
								centerSlidePercentage={isMdWidth ? 100 / 4 : 100 / 6}
								selectedItem={currentIndex}
								showArrows={false}
								showThumbs={false}
								showStatus={false}
								showIndicators={false}
								autoPlay
								onChange={index => onChangeCarousel(index)}
							>
								{cardSlides.map(card => <AnimatedCard 
									key={card.id}
									card={card?.card}
									size="sm" 
									noRotate noShadow />
								)}
							</Carousel>
						</div>
					</Hidden>
					<p className={classes.title} data-aos="fade-right">COLLECT YOUR<br /> FAVORITE FIGHTER</p>
					<p className={classes.subtitle} data-aos="fade-right">Build your collection warriors</p>
					<Hidden mdUp>
						<div className={classes.carouselSmall}>
							<Carousel
								centerMode
								infiniteLoop
								centerSlidePercentage={isSmWidth ? 100 / 4 : 100 / 2}
								selectedItem={3}
								showArrows={false}
								showThumbs={false}
								showStatus={false}
								showIndicators={false}
								autoPlay
							>
								{cardSlides.map(card => <AnimatedCard 
									key={card.id}
									card={card?.card}
									size="xs" 
									noRotate noShadow />
								)}
							</Carousel>
						</div>
					</Hidden>
					<div className={classes.buttonsBox}>
						<Button color="primary" variant="contained" onClick={() => dispatch(setSignUpDialogOpen(true))}>SIGN UP</Button>
						<span className={classes.orText}>OR</span>
						<Button variant="contained" onClick={() => dispatch(setSignUpDialogOpen(true))} className={classes.openFreePackButton}>OPEN FREE PACK</Button>
					</div>
					<Hidden lgDown>
						<Button className={clsx(classes.arrowButton, classes.leftArrowButton)} onClick={prevButton}>
							<ArrowBackIosTwoToneIcon />
						</Button>
						<Button className={clsx(classes.arrowButton, classes.rightArrowButton)} onClick={nextButton}>
							<ArrowForwardIosIcon />
						</Button>
					</Hidden>
				</Grid>
			</Grid>
			<div className={classes.mouseBox}>
				<MouseIcon />
				<p className={classes.mouseText}>EXPLORE</p>
			</div>
		</div>
	)
}

export default Home;