import { AppBar, Avatar, Button, ButtonGroup, Divider, Menu, Toolbar } from "@material-ui/core";
import clsx from 'clsx';
import { useState } from "react";
import { useSelector } from 'react-redux';
import { Link, NavLink } from "react-router-dom";
import DropdownImg from '../../../../assets/img/dropdown.svg';
import ButtonGreyOutlined from '../../Buttons/ButtonGreyOutlined/ButtonGreyOutlined';
import ButtonPrimary from '../../Buttons/ButtonPrimary/ButtonPrimary';
import NotificationsButton from "../NavbarSharedElements/NotificationsButton/NotificationsButton";
import UserMenuItems from "../NavbarSharedElements/UserMenuItems/UserMenuItems";
import CoinIcon from './../../Icons/CoinIcon/CoinIcon';
import WalletGreenIcon from './../../Icons/WalletGreenIcon/WalletGreenIcon';
import { useStyles } from "./NavbarTopStyle";

const NavbarTop = () => {
	const classes = useStyles();

	const user = useSelector((state) => state.user.data);

	const [userMenu, setUserMenu] = useState(null);

	const handleUserMenuClick = (event) => {
		setUserMenu(event.currentTarget);
	};
	
	const handleUserMenuClose = () => {
		setUserMenu(null);
	};

	return (
		<AppBar position="fixed">
			<Toolbar className={classes.appBar}>
				<div className={classes.flexGrow}></div>
				<ButtonGroup>
					<Button className={clsx(classes.balanceButton, classes.fontWhite)}>
						<CoinIcon />&nbsp;{user?.userWallet?.coins || 0}
					</Button>
					<ButtonPrimary component={Link} to="/buy-credit">Buy balance</ButtonPrimary>
				</ButtonGroup>
				<Divider orientation="vertical"></Divider>
				<NavLink to="/user-profile" className={classes.navLink}>
					<Avatar src={user?.avatar?.url} className={classes.avatar} alt="Avatar" />
					<p className={clsx(classes.avatarName, classes.fontWhite)}>{user?.nickname}</p>
				</NavLink>
				<WalletGreenIcon />
				<div className={classes.walletInfo}>
					<p className={clsx(classes.fontWhite, classes.walletAmount)}>&nbsp;{Number(user?.userWallet?.cash || 0).toFixed(2)} PLN</p>
					<Link to="/buy-credit" className={classes.walletLink}>BUY BALANCE</Link>
				</div>
				<Divider orientation="vertical"></Divider>
				<NavLink to="/notifications">
					<NotificationsButton />
				</NavLink>
				<div>
					<ButtonGreyOutlined aria-controls="user-menu" aria-haspopup="true" onClick={handleUserMenuClick}>
						<img src={DropdownImg} alt="Dropdown" />
					</ButtonGreyOutlined>
					<Menu
						id="user-menu"
						anchorEl={userMenu}
						open={Boolean(userMenu)}
						onClose={handleUserMenuClose}
						keepMounted={false}
						PaperProps={{ style: { transform: 'translateX(0) translateY(50px)' } }}
					>
						<UserMenuItems handleUserMenuClose={handleUserMenuClose} />
					</Menu>
				</div>
			</Toolbar>
		</AppBar>
	);
}

export default NavbarTop;