import { Button } from "@material-ui/core";
import clsx from 'clsx';
import { useStyles } from "./ButtonYellowStyle";

const ButtonYellow = (props) => {
	const classes = useStyles();

	return (
		<Button
			{...props}
			variant="contained"
			className={clsx(classes.yellow, props.className)}
		></Button>
	);
}

export default ButtonYellow;