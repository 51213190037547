import { Avatar, Divider, Grid, Hidden, List, ListItem, ListItemIcon } from '@material-ui/core';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { Link, NavLink } from 'react-router-dom';
import { ReactComponent as LibraryIcon } from '../../../assets/img/card.svg';
import DoneImg from '../../../assets/img/done.svg';
import DropdownImg from '../../../assets/img/dropdown.svg';
import ButtonPrimary from '../../UI/Buttons/ButtonPrimary/ButtonPrimary';
import ButtonPrimaryOutlined from '../../UI/Buttons/ButtonPrimaryOutlined/ButtonPrimaryOutlined';
import CoinIcon from './../../UI/Icons/CoinIcon/CoinIcon';
import WalletGreenIcon from './../../UI/Icons/WalletGreenIcon/WalletGreenIcon';
import ProgressBar from './ProgressBar';
import { useStyles } from './UserBoxStyle';

const UserBox = () => {
	const classes = useStyles();

	const user = useSelector((state) => state.user.data);

	return (
		<Grid item className={classes.userBox} xs={12}>
			<Grid item container spacing={2}>
				<Grid item container xs={12}>
					<NavLink to="/user-profile">
						<Avatar src={user?.avatar?.url} className={classes.avatar} alt="Avatar" />
					</NavLink>
					<div style={{ margin: '10px' }}>
						<p className={classes.userName}>{user?.nickname}</p>
						<Grid className={classes.displayFlex} container alignItems="center">
							<CoinIcon />&nbsp;<p>{user?.userWallet?.coins || 0}</p>&nbsp;&nbsp;&nbsp;
							<WalletGreenIcon />&nbsp;<p>{Number(user?.userWallet?.cash || 0).toFixed(2)} PLN</p>
						</Grid>					
					</div>
				</Grid>
				<Grid item xs={12} sm={6}>
					<ButtonPrimary fullWidth component={Link} to="/buy-credit">Buy balance</ButtonPrimary>
				</Grid>
				<Grid item xs={12} sm={6}>
					<ButtonPrimaryOutlined fullWidth component={NavLink} to="/user-profile#withdraw">Withdraw</ButtonPrimaryOutlined>
				</Grid>
				<Divider />
				<Grid item xs={12}>
					<div className={classes.displayFlex}>
						<p className={classes.fontMontserrat}>Active Quest</p>
						<div className={classes.flexGrow}></div>
						<p className={clsx(classes.fontMontserrat, classes.viewAll)}>
							<NavLink to="/quest">VIEW ALL</NavLink>
						</p>
					</div>
					<Grid item container>
						<Grid item xs={2}>
							<div className={classes.checkCircleIcon}>
								<img src={DoneImg} alt="Done" />
							</div>
						</Grid>
						<Grid item xs={10}>
							<div className={clsx(classes.displayFlex, classes.alignItemsCenter)}>
								<p  className={classes.fontMontserrat} style={{ margin: '5px', fontSize: '11px' }}>Daily login</p>
								<div className={classes.flexGrow}></div>
								<CoinIcon />&nbsp;<p style={{ margin: '5px' }}>{30000}</p>
							</div>
							<ProgressBar variant="determinate" value={50} />
						</Grid>
					</Grid>
				</Grid>
				<Grid item xs={12}>
					<List component="nav" className={classes.list}>
						<Divider />
						<ListItem button>
							<NavLink to="/buy-credit" className={classes.navlink}>
								<ListItemIcon className={clsx(classes.listItemIcon, classes.buyCoinsIcon)}>
									<LibraryIcon />
								</ListItemIcon>
								<Grid item container>
									<Grid item xs={12} sm={6}>
										<p className={classes.listItemText}>BUY COINS</p>
									</Grid>
									<Grid item xs={12} sm={6}>
										<div className={classes.displayFlex}>
											<Hidden mdDown><div className={classes.flexGrow}></div></Hidden>
											<p className={classes.collectibles}>Your collectibles</p>
											<img src={DropdownImg} className={classes.dropdownRight} alt="Dropdown" />
										</div>
									</Grid>
								</Grid>
							</NavLink>
						</ListItem>
						<Divider />
						<ListItem button>
							<NavLink to="/store" className={classes.navlink}>
								<ListItemIcon className={clsx(classes.listItemIcon, classes.packStoreIcon)}>
									<LibraryIcon />
								</ListItemIcon>
								<Grid item container>
									<Grid item xs={12} sm={6}>
										<p className={classes.listItemText}>PACK STORE</p>
									</Grid>
									<Grid item xs={12} sm={6}>
										<div className={classes.displayFlex}>
											<Hidden mdDown><div className={classes.flexGrow}></div></Hidden>
											<p className={classes.collectibles}>Your collectibles</p>
											<img src={DropdownImg} className={classes.dropdownRight} alt="Dropdown" />
										</div>
									</Grid>
								</Grid>
							</NavLink>
						</ListItem>
						<Divider />
						<ListItem button>
							<NavLink to="/library" className={classes.navlink}>
								<ListItemIcon className={clsx(classes.listItemIcon, classes.libraryIcon)}>
									<LibraryIcon />
								</ListItemIcon>
								<Grid item container>
									<Grid item xs={12} sm={6}>
										<p className={classes.listItemText}>LIBRARY</p>
									</Grid>
									<Grid item xs={12} sm={6}>
										<div className={classes.displayFlex}>
											<Hidden mdDown><div className={classes.flexGrow}></div></Hidden>
											<p className={classes.collectibles}>Your collectibles</p>
											<img src={DropdownImg} className={classes.dropdownRight} alt="Dropdown" />
										</div>
									</Grid>
								</Grid>
							</NavLink>
						</ListItem>
					</List>
				</Grid>
			</Grid>
		</Grid>
	);
}
  
export default UserBox;