import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
	tableRow: {
		height: 80
	},
	nameCell: {
		height: 80
	},
	cardBox: {
		transform: 'scale(0.25)',
		width: 100,
		marginLeft: '-30px'
	},
	whiteSpaceNoWrap: {
		whiteSpace: 'nowrap'
	},
	priceInput: {
		minWidth: 150,
		width: '100%'
	}
}));

export { useStyles };