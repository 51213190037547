import { TableCell, withStyles } from '@material-ui/core';

export const StyledTableCell = withStyles((theme) => ({
	head: {
		backgroundColor: theme.palette.background.main,
		color: theme.palette.text.trueWhite,
		borderBottom: `1px solid ${theme.palette.border.main}`,
		fontFamily: 'Klavika',
		fontSize: '15px',
	},
	body: {
		fontFamily: 'Klavika',
		fontSize: '15px',
		color: theme.palette.text.trueWhite,
		borderBottom: `1px solid ${theme.palette.border.main}`
	},
}))(TableCell);