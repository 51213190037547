import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../../../shared/axios-client';

export const fetchLibraryOrganizations = createAsyncThunk(
	'libraryOrganizations/fetch',
	async () => {
		const response = await axios.get('organisations', { params: { 
			userCardsSort: true,
			view: 'extended' 
	}});
		return response.data.data;
	}
);

export const LibraryOrganizationsSlice = createSlice({
	name: 'libraryOrganizations',
	initialState: {
		status: "init",
		data: [],
		error: null,
		selectedOrganization: ''
	},
	reducers: {
		setSelectedOrganization: (state, action) => {
			state.selectedOrganization = action.payload;
		}
	},
	extraReducers: {
		[fetchLibraryOrganizations.pending.type]: (state, action) => {
			state.status = "pending";
			state.data = [];
			state.error = null;
		},
		[fetchLibraryOrganizations.fulfilled.type]: (state, action) => {
			state.status = "fulfilled";
			state.data = action.payload;
			state.error = null;
			// state.selectedOrganization = action.payload[0] // TODO
			state.selectedOrganization = action.payload.find(organisation => organisation.name === 'Punchdown');
		},
		[fetchLibraryOrganizations.rejected.type]: (state, action) => {
			state.status = "rejected";
			state.data = [];
			state.error = action.error.message;
		},
	}
});

export const { setSelectedOrganization } = LibraryOrganizationsSlice.actions;

export default LibraryOrganizationsSlice.reducer;