import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../../../shared/axios-client';

export const fetchCardsOtherPopular = createAsyncThunk(
	'cardsOtherPopular/fetch',
	async ({cardId}) => {
		// TODO 
		// const response = await axios.get(`card/${cardId}/other-cards`);
		// return response.data.data;

		const response1 = await axios.get(`generated_cards/23`);
		const response2 = await axios.get(`generated_cards/33`);
		const response3 = await axios.get(`generated_cards/40`);
		const response4 = await axios.get(`generated_cards/43`);

		return [response1.data.data, response2.data.data, response3.data.data, response4.data.data];
	}
);

export const CardsOtherPopularSlice = createSlice({
    name: 'cardsOtherPopular',
    initialState: {
        status: "init",
		error: null,
		data: []
    },
    reducers: {},
    extraReducers: {
        [fetchCardsOtherPopular.pending.type]: (state, action) => {
			state.status = "pending";
			state.error = null;
			state.data = [];
		},
		[fetchCardsOtherPopular.fulfilled.type]: (state, action) => {
			state.status = "fulfilled";
			state.error = null;
			state.data = action.payload;
		},
		[fetchCardsOtherPopular.rejected.type]: (state, action) => {
			state.status = "rejected";
			state.error = action.error.message;
			state.data = [];
		}
    }
});

export default CardsOtherPopularSlice.reducer;