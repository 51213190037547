import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
	yourPackBox: {
		background: `linear-gradient(169.5deg, rgba(10, 15, 29, 0) -33.62%, #1F2431 125.58%)`,
		fontFamily: 'Klavika',
        minWidth: '320px',
        width: '320px',
        textAlign: 'center',
        padding: theme.spacing(2),
        paddingTop: 0,
	},
	packImg: {
		height: '100%',
		width: 'auto',
		maxHeight: '250px',
		margin: 'auto',
		padding: theme.spacing(2),
		borderRadius: '20px',
	},
	title: {
		fontWeight: 500,
		fontSize: '24px',
		lineHeight: '29px',
		letterSpacing: '0.04em',
        margin: '0 auto',
	},
	subtitle: {
        fontSize: '15px',
		lineHeight: '20px',
		color: theme.palette.text.lightOn
	},
    quantity: {
        position: 'absolute',
        top: 10,
        right: 10
    }
}));

export { useStyles };