import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../../shared/axios-client';

export const fetchLandingPageCards = createAsyncThunk(
	'landingPageCards/fetch',
	async () => {
        // TODO get landing page cards

		const response1 = await axios.get(`generated_cards/43`);
		const response2 = await axios.get(`generated_cards/33`);
		const response3 = await axios.get(`generated_cards/40`);
		const response4 = await axios.get(`generated_cards/23`);
		const response5 = await axios.get(`generated_cards/44`);
        const response6 = await axios.get(`generated_cards/35`);
		const response7 = await axios.get(`generated_cards/29`);
		const response8 = await axios.get(`generated_cards/37`);
		const response9 = await axios.get(`generated_cards/19`);
		const response10 = await axios.get(`generated_cards/31`);

		return [response1.data.data, response2.data.data, response3.data.data, response4.data.data, response5.data.data, response6.data.data, response7.data.data, response8.data.data, response9.data.data, response10.data.data];
	}
);

export const LandingPageCardsSlice = createSlice({
	name: 'landingPageCards',
	initialState: {
		status: "init",
		error: null,
		data: {},
	},
	reducers: {},
	extraReducers: {
		[fetchLandingPageCards.pending.type]: (state, action) => {
			state.status = "pending";
			state.error = null;
			state.data = {};
		},
		[fetchLandingPageCards.fulfilled.type]: (state, action) => {
			state.status = "fulfilled";
			state.error = null;
			state.data = action.payload;
		},
		[fetchLandingPageCards.rejected.type]: (state, action) => {
			state.status = "rejected";
			state.error = action.error.message;
			state.data = {};
		},
	}
});

export default LandingPageCardsSlice.reducer;