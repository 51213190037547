import * as yup from "yup";

export const sellPriceValidationSchema = yup
	.object().shape({
		sellPrice: yup.number().required().min(0.01)
	});

export const sellCardValidationSchema = yup
	.object().shape({
		sell: yup
			.array()
			.of(sellPriceValidationSchema)
			.required()
	});