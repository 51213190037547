import { drawerWidth } from '../../shared/vars'
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  	content: {
		backgroundColor: theme.palette.background.main,
		minHeight      : '100vh',
		display         : 'flex',
		color           : 'white',
		paddingTop     : '60px',
		
		[theme.breakpoints.up("md")]: {
			marginLeft: drawerWidth,
			paddingTop     : '85px',
		}
  	},
	flexGrow: {
		flexGrow: 1,
		padding: theme.spacing(3)
	}
}));

export { useStyles };