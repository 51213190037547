import './SectionHeader.scss'

const SectionHeader = (props) => {
    return (
        <div className="section-header">
            <hr />
            <h4>{props.title}</h4>
            <p>{props.subtitle}</p>
        </div>
    );
}

export default SectionHeader;