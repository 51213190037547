import { useStyles } from './BuyProductDialogContentStyle';
import { ReactComponent as CreditCardIcon } from '../../../assets/img/credit-card.svg';
import ButtonPrimary from '../../UI/Buttons/ButtonPrimary/ButtonPrimary';
import clsx from 'clsx';
import ButtonDangerOutline from '../../UI/Buttons/ButtonDangerOutline/ButtonDangerOutline';

const BuyProductDialogContent = (props) => {
	const classes = useStyles();

	return (
		<div className={classes.buyProductDialog}>
			<CreditCardIcon className={classes.creditCardIcon} />
			<p className={classes.question}>Are you sure you want to buy this item?</p>
			<p className={classes.description}>If so, you will be taken to the payment page to complete the purchase of the item</p>
			<div className={clsx(classes.priceBox, "panel-fill")}>
				<p>TO PAY: <span className={classes.price}>45.00 PLN</span></p>
			</div>
			<br />
			<ButtonDangerOutline className={classes.cancelButton} onClick={props.onClose}>CANCEL</ButtonDangerOutline>
			<ButtonPrimary>GO TO PAYMENT</ButtonPrimary>
		</div>
	);
}

export default BuyProductDialogContent;