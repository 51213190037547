import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
	connectBox: {
		display: 'flex',
		alignItems: 'center',
		padding: theme.spacing(2),
		fontSize: 13,
		color: theme.palette.text.lightOn,
		letterSpacing: '0.25px',

		[theme.breakpoints.only("xs")]: {
			display: 'block',
			paddingTop: 0,

			'& button': {
				width: '100%'
			}
		}
	},
	icon: {
		minWidth: 63,
		width: 63,
		height: 63,
		borderRadius: '50%',
		border: `1px solid ${theme.palette.icons.main}`,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		marginRight: theme.spacing(2),

		'& svg': {
			width: 20,
			height: 20
		}
	},
	textBox: {
		[theme.breakpoints.up("sm")]: {
			width: '50%'
		}
	},
	title: {
		fontSize: 16,
		color: theme.palette.text.trueWhite,
		marginBottom: 0
	},
	description: {
		marginTop: theme.spacing(1)
	}
}));

export { useStyles };