import { makeStyles } from '@material-ui/core/styles';
import CardsBackgroundImg from '../../../assets/img/cards-background.svg';

const useStyles = makeStyles((theme) => ({
	freePackBox: {
		background: theme.palette.background.panel,
		border: `1px solid ${theme.palette.border.main}`,
		borderRadius: '3px',
		padding: theme.spacing(2),
		fontFamily: 'Klavika',
		height: '100%',

		[theme.breakpoints.up("lg")]: {
			maxHeight: '220px',
		},
		[theme.breakpoints.up("sm")]: {
			marginRight: theme.spacing(1)
		},
		[theme.breakpoints.down("xs")]: {
			marginBottom: theme.spacing(1)
		}
	},
	cardsImg: {
		maxWidth: '110%',
    	maxHeight: '110%',
	},
	title: {
		fontWeight: 500,
		fontSize: '19px',
		lineHeight: '23px',
		marginBottom: 0,
	},
	subtitle: {
		fontSize: '13px',
		lineHeight: '16px',
		color: theme.palette.icons.main,
		marginTop: 0,
	},
	countdown: {
		[theme.breakpoints.up("md")]: {
			marginBottom: theme.spacing(2),
			marginTop: theme.spacing(2),
		},
		[theme.breakpoints.down("md")]: {
			marginBottom: theme.spacing(1),
		},

		'& div div': {
			[theme.breakpoints.down("md")]: {
				justifyContent: 'center',
			}
		}
	},
	cardsBackground: {
		backgroundImage: `url(${CardsBackgroundImg})`,
		backgroundPosition: 'right',
		backgroundRepeat: 'no-repeat',
		backgroundSize: '70% 100%',

		[theme.breakpoints.down("md")]: {
			backgroundPosition: 'center top',
			backgroundSize: 'auto',	
		}
	},
	columnReverse: {
		[theme.breakpoints.down("md")]: {
			flexDirection: 'column-reverse',
			textAlign: 'center',
		}
	},
	openFreeButton: {
		[theme.breakpoints.down("md")]: {
			width: '100%'
		}
	},
	cardsBox: {
		[theme.breakpoints.down('md')]: {
			maxHeight: '205px'
		}
	}
}));

export { useStyles };