import { Button, Dialog, makeStyles, Typography } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { setSignInDialogOpen, setSignUpDialogOpen } from '../../store/slices/dialogs/DialogsSlice';
import { Toolbar } from '@material-ui/core';
import { IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { ReactComponent as GoogleIcon } from '../../assets/img/google.svg';
import { ReactComponent as TwitchIcon } from '../../assets/img/twitch.svg';
import { ReactComponent as FbIcon } from '../../assets/img/facebook.svg';
import { ReactComponent as TwitterIcon } from '../../assets/img/twitter.svg';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
	signUpdialog: {
		textAlign: 'center',

		'& .MuiDialog-paper': {
			backgroundColor: theme.palette.background.panel,
		},
		'& .MuiButton-root': {
		    fontFamily: 'Montserrat !important',
			fontWeight: 600,
		}
	},
	dialogHeader: {
		color: theme.palette.text.trueWhite,

		[theme.breakpoints.only('xs')]: {
			fontSize: 18
		},
	},
	dialogContent: {
		padding: 35,
		paddingTop: 15,
		fontWeight: 600,
		fontSize: 14,
		letterSpacing: '1.25px',

		'& button': {
			[theme.breakpoints.only('xs')]: {
				fontSize: 11,
			},
			'& svg': {
				width: 29,
				height: 29,
				marginRight: 20,
				
				[theme.breakpoints.only('xs')]: {
					marginRight: 10,
				}
			},
		},
	},
	googleButton: {
		backgroundColor: '#fff',
		color: '#626A70',
		marginBottom: 24,

		'&:hover': {
			backgroundColor: '#fff',
		}
	},
	twitchButton: {
		backgroundColor: '#6C5CD0',
		color: '#fff',
		marginBottom: 24,

		'&:hover': {
			backgroundColor: '#6C5CD0',
		}
	},
	fbButton: {
		backgroundColor: '#3B5998',
		color: '#fff',
		marginBottom: 24,

		'&:hover': {
			backgroundColor: '#3B5998',
		}
	},
	twitterButton: {
		backgroundColor: '#08A0E9',
		color: '#fff',
		marginBottom: 24,

		'&:hover': {
			backgroundColor: '#08A0E9',
		}
	},
	signUpButton: {
		backgroundColor: '#FD2B15',
		color: '#fff',

		'&:hover': {
			backgroundColor: '#FD2B15',
		}
	},
	text: {
		color: '#fff',
		marginBottom: 24,
		
		[theme.breakpoints.only('xs')]: {
			fontSize: 11,
		},
	},
	or: {
		color: theme.palette.text.lightOn,
		width: '100%',
		textAlign: 'center', 
		borderBottom: `1px solid ${theme.palette.text.lightOn}`, 
		lineHeight: '0.1em',
		margin: '32px 0',
		
		'& span': {
			background: theme.palette.background.panel,
		    padding: '0 10px'
		},

		[theme.breakpoints.only('xs')]: {
			margin: '24px 0',
		},
	}
}));

const SignUpDialog = (props) => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const signUpDialogOpen = useSelector((state) => state.dialogs.signUpDialogOpen);

	const signIn = () => {
		dispatch(setSignUpDialogOpen(false));
		dispatch(setSignInDialogOpen(true));
	};

	return (
		<Dialog 
			fullWidth 
			maxWidth="xs" 
			open={signUpDialogOpen} 
			onClose={() => dispatch(setSignUpDialogOpen(false))}
			className={classes.signUpdialog}
		>
			<Toolbar>
				<div className="flex-grow-full"></div>
				<Typography variant="h6" className={classes.dialogHeader}>
					CREATE YOUR <b>ACCOUNT</b>
				</Typography>
				<div className="flex-grow-full"></div>
				<IconButton edge="start" color="inherit" onClick={() => dispatch(setSignUpDialogOpen(false))} aria-label="close" size="small">
					<CloseIcon />
				</IconButton>
			</Toolbar>
			<div className={classes.dialogContent}>
				<Button variant="contained" color="primary" fullWidth className={classes.googleButton}>
					<GoogleIcon />SIGN IN WITH GOOGLE
				</Button>
				<Button variant="contained" color="primary" fullWidth className={classes.twitchButton}>
					<TwitchIcon />SIGN IN WITH TWITCH
				</Button>
				<Button variant="contained" color="primary" fullWidth className={classes.fbButton}>
					<FbIcon />SIGN IN WITH FACEBOOK
				</Button>
				<Button variant="contained" color="primary" fullWidth className={classes.twitterButton}>
					<TwitterIcon />SIGN IN WITH TWITTER
				</Button>
				<Button 
					variant="contained" 
					color="primary" 
					fullWidth 
					className={classes.signUpButton} 
					component={Link} 
					to="/register" 
					onClick={() => dispatch(setSignUpDialogOpen(false))}
				>
					SIGN UP WITH EMAIL
				</Button>
				<p className={classes.or}>
					<span>OR</span>
				</p>
				<p className={classes.text}>Already a MartialCards collector?</p>
				<Button variant="contained" color="primary" fullWidth onClick={signIn}>
					SIGN INTO MY ACCOUNT
				</Button>
			</div>
		</Dialog>
	);
}

export default SignUpDialog;