import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    backButtonBox: {
		height: 50,
		display: 'flex',
		alignItems: 'center',
		flexGrow: 1,
		borderBottom: `1px solid ${theme.palette.border.main}`
	},
	cardMarketListings: {
		padding: theme.spacing(2)
	},
	cardBasicInfoBox: {
		padding: theme.spacing(3)
	}
}));

export { useStyles };