import { Avatar, TableBody, TableHead, TableRow } from "@material-ui/core";
import { useState } from 'react';
import ButtonPrimary from "../../UI/Buttons/ButtonPrimary/ButtonPrimary";
import BuyErrorDialog from "../../UI/Dialogs/BuyErrorDialog/BuyErrorDialog";
import StyledTable from "../../UI/StyledTable/StyledTable";
import { StyledTableCell } from "../../UI/StyledTable/StyledTableCell";
import CustomDialog from './../../UI/Dialogs/CustomDialog/CustomDialog';
import { StyledTableRow } from './../../UI/StyledTable/StyledTableRow';
import BuyCardDialogContent from './BuyCardDialogContent/BuyCardDialogContent';
import { useStyles } from "./CardOffersStyle";
import ConfirmBuyCardDialogContent from './ConfirmBuyCardDialogContent/ConfirmBuyCardDialogContent';

const createData = (id, avatar, seller, price, date) => {
	return { id, avatar, seller, price, date };
}

const CardOffers = (props) => {
	const classes = useStyles();

	// const userWallet = useSelector((state) => state.user.data?.userWallet?.cash);

	const [openErrorDialog, setOpenErrorDialog] = useState(false);
	const [openPurchaseDialog, setOpenPurchaseDialog] = useState(false);
	const [openConfimationDialog, setOpenConfimationDialog] = useState(false);
	const [dialogPrice, setDialogPrice] = useState(0);

	const rows = props.cardOffers.map(cardOffer => createData(
		cardOffer.uuid, 
		cardOffer.owner?.avatar?.url,
		cardOffer.owner?.nickname,
		cardOffer.price,
		cardOffer.createdAt,
	));

	const handleOpenPurchaseDialog = (price) => {
		// if (Number(userWallet) >= Number(price)) {
			setDialogPrice(price);
			setOpenPurchaseDialog(true);
		// } else {
		// 	setDialogPrice(0);
		// 	setOpenErrorDialog(true);
		// }
	}

	const handleBuyButtonClick = () => {
		setOpenPurchaseDialog(false);
		// POST buy card and update user wallet
		setOpenConfimationDialog(true);
	};

	return (
		<>
			<StyledTable>
				<TableHead>
					<TableRow>
						<StyledTableCell>ID</StyledTableCell>
						<StyledTableCell align="right">SELLER</StyledTableCell>
						<StyledTableCell align="right">PRICE</StyledTableCell>
						<StyledTableCell align="right">CREATE DATE</StyledTableCell>
						{!props.noBuy && <StyledTableCell align="right">ACTION</StyledTableCell>}
					</TableRow>
				</TableHead>
				<TableBody>
					{rows.map((row, index) => (
						<StyledTableRow key={index}>
							<StyledTableCell component="th" scope="row">{row.id}</StyledTableCell>
							<StyledTableCell align="right">
								<div className="display-flex align-items-center justify-content-end">
									<Avatar src={row.avatar} className={classes.avatar} alt="Avatar" />
									<span>{row.seller}</span>
								</div>
							</StyledTableCell>
							<StyledTableCell align="right" className={classes.whiteSpaceNoWrap}>{Number(row.price).toFixed(2)} PLN</StyledTableCell>
							<StyledTableCell align="right">{new Date(row.date).toLocaleString()}</StyledTableCell>
							{!props.noBuy && (<StyledTableCell align="right">
								<ButtonPrimary className={classes.whiteSpaceNoWrap} onClick={() => handleOpenPurchaseDialog(row.price)}>BUY NOW</ButtonPrimary>
							</StyledTableCell>)}
						</StyledTableRow>
					))}
				</TableBody>
			</StyledTable>
			{!props.noBuy && (<>
				<BuyErrorDialog open={openErrorDialog} handleClose={() => setOpenErrorDialog(false)} />
				<CustomDialog
					title="You are purchasing an item" 
					maxWidth="sm"
					open={openPurchaseDialog}
					handleClose={() => setOpenPurchaseDialog(false)}>
					<BuyCardDialogContent 
						onClose={() => setOpenPurchaseDialog(false)}
						handleBuyButtonClick={handleBuyButtonClick}
						card={props.card}
						price={dialogPrice} />
				</CustomDialog>
				<CustomDialog
					title="Confirmation" 
					maxWidth="md"
					open={openConfimationDialog}
					handleClose={() => setOpenConfimationDialog(false)}>
					<ConfirmBuyCardDialogContent onClose={() => setOpenConfimationDialog(false)} />
				</CustomDialog>
			</>)}
		</>
	);
}

export default CardOffers;