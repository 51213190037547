import { CircularProgress, Grid } from "@material-ui/core";
import clsx from 'clsx';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useParams } from "react-router-dom";
import CardReversImg from '../../../assets/img/card/card-revers.webp';
import { ReactComponent as QuestIcon } from '../../../assets/img/quest.svg';
import { fetchPackCards } from "../../../store/slices/packs/PackCardsSlice";
import AnimatedCard from "../../AnimatedCard/AnimatedCard";
import BadgeBlack from "../../UI/Badges/BadgeBlack/BadgeBlack";
import ButtonYellow from "../../UI/Buttons/ButtonYellow/ButtonYellow";
import ScrollToTop from './../../../hoc/ScrollToTop/ScrollToTop';
import { fetchPack } from './../../../store/slices/packs/PackSlice';
import { getRarityColor } from './../../../utils/rarityColor';
import ButtonGreyOutlined from './../../UI/Buttons/ButtonGreyOutlined/ButtonGreyOutlined';
import ButtonPrimary from './../../UI/Buttons/ButtonPrimary/ButtonPrimary';
import { useStyles } from "./OpenPackStyle";

const OpenPack = (props) => {
	const classes = useStyles();
	const routerHistory = useHistory();
	const { packId } = useParams();
	const [showCards, setShowCards] = useState(false);
	const [showLastCard, setShowLastCard] = useState(false);
	const [showLastCardAnimation, setShowLastCardAnimation] = useState(false);

	const dispatch = useDispatch();
	const packStatus = useSelector(state => state.pack.status);
	const pack = useSelector(state => state.pack.data);
	const packCards = useSelector(state => state.packCards.data);

	useEffect(() => {
		dispatch(fetchPack({ packId: packId }));
		dispatch(fetchPackCards({ packId: packId }));
	}, [packId, dispatch]);
	
	return (
		<>
			{!showCards && (
				<Grid 
					container item xs={12} 
					justifyContent="center" 
					className={clsx(
						classes.openPackBox, 
						classes.packBackground, 
						"display-flex align-items-center text-align-center"
					)}>
					{(packStatus === 'init' || packStatus === 'pending') && <CircularProgress />}
					{packStatus === 'fulfilled' && (
						<Grid item xs={12} md={6}>
							<p className={classes.name}>{pack?.boosterPack?.name}</p>
							{pack?.boosterPack?.quantity > 0 && <div>
								<BadgeBlack>STACK PACK: {pack?.boosterPack?.quantity}</BadgeBlack>
							</div>}
							<img src={pack?.boosterPack?.image?.url} alt="Pack img" className={classes.packImg} />
							<Grid item xs={11} sm={6} className="margin-auto">
								<ButtonPrimary fullWidth onClick={() => setShowCards(true)}>OPEN PACK</ButtonPrimary>
							</Grid>
							<Grid item xs={11} sm={6} className="margin-auto">
								<ButtonGreyOutlined fullWidth className={classes.closeButton} onClick={() => routerHistory.goBack()}>CLOSE</ButtonGreyOutlined>
							</Grid>
						</Grid>
					)}
				</Grid>
			)}
			{showCards && (
				<Grid 
				container item xs={12} 
				justifyContent="center"
				className={clsx(
					classes.openPackBox,
					classes.cardsBackground,
					"display-flex align-items-center text-align-center"
					)}>
						<ScrollToTop force />
						{!packCards?.length ? <CircularProgress /> : (
							<Grid item container>
								{packCards.map((card, i) => (
									<Grid item xs={12} lg key={card.id}>
										<div>
											{i === 4 && !showLastCard && <img 
												src={CardReversImg} 
												alt="Card revers" 
												style={{ width: 210, height: 324 }}
												className={showLastCardAnimation ? "rotate-out-ver" : "cursor-pointer"}
												onClick={() => setShowLastCardAnimation(true)}
												onAnimationEnd={() => {setShowLastCardAnimation(false); setShowLastCard(true);}} />}
											{i === 4 && showLastCard && <div className="rotate-in-ver">
												<AnimatedCard card={card?.card} size="sm" />
											</div>}
											{i !== 4 && <AnimatedCard card={card?.card} size="sm" />}
											{(i !== 4 || showLastCard) && <>
												<br />
												<span 
													className={classes.rarity} 
													style={{ color: getRarityColor(card?.card?.cardType?.name) }}
												>
													{card?.card?.cardType?.name}
												</span>&nbsp;
												<span className={classes.collection}>{card?.card?.cardCollection?.name}</span>
												<p className={classes.cardName}>{card?.card?.name}</p>
											</>}
										</div>
									</Grid>
								))}
								<Grid item xs={12}>
									<div className={clsx(classes.questBox, "display-flex justify-content-center align-items-center")}>
										<QuestIcon className={classes.questIcon} />
										<p> You win: <span>500.00 PLN</span></p>
									</div>
								</Grid>
								<Grid item xs={12}>
									<ButtonYellow className={classes.cardsButton} component={Link} to="/library">ADD TO LIBRARY</ButtonYellow>
									<ButtonPrimary className={classes.cardsButton} component={Link} to="/your-packs">OPEN NEW</ButtonPrimary>
								</Grid>
							</Grid>
						)}
				</Grid>
			)}
		</>
	);
}

export default OpenPack;