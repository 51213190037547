import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import { NavLink } from "react-router-dom";
import logo from '../../../../assets/img/logo.svg';
import MenuItems from "../NavbarSharedElements/MenuItems/MenuItems";
import { useStyles } from "./NavbarLeftStyle";

const NavbarLeft = () => {
	const classes = useStyles();

	return (
		<nav className={classes.drawer} aria-label="menu">
			<Hidden smDown implementation="css">
				<Drawer classes={{ paper: classes.drawerPaper }} variant="permanent" open>
					<div className={classes.listBox}>
						<NavLink to="/" className={classes.logo}>
							<img src={logo} alt="logo" />
						</NavLink>
						<MenuItems />
					</div>
				</Drawer>
			</Hidden>
		</nav>
	);
}

export default NavbarLeft;