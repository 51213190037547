import { AppBar, Button, Dialog, Grid, Hidden, IconButton, makeStyles, Slide, Toolbar } from '@material-ui/core';
import { forwardRef, useState } from 'react';
import { ReactComponent as LogoIcon } from '../../assets/img/logo.svg';
import { scrollToElement } from './../../utils/scrollToElement';
import ScrollToColor from './../ScrollToColor/ScrollToColor';
import CloseIcon from '@material-ui/icons/Close';
import MenuIcon from '@material-ui/icons/Menu';
import { useDispatch } from 'react-redux';
import { setSignInDialogOpen, setSignUpDialogOpen } from '../../store/slices/dialogs/DialogsSlice';

const useStyles = makeStyles((theme) => ({
	appBar: {
		boxShadow: 'none !important',
		height: 100,
		justifyContent: 'center'
	},
	toolbar: {
		width: '100%',
		maxWidth: 1410,
		margin: '0 auto'
	},
	logo: {
		[theme.breakpoints.down('sm')]: {
			width: 50,
			height: 50
		},
		[theme.breakpoints.between('md', 'lg')]: {
			width: 70,
			height: 70,
		},
		[theme.breakpoints.up('xl')]: {
			width: 80,
			height: 85,
		}
	},
	buttonGrey: {
		color: `${theme.palette.text.lightOn} !important`,
		border: `1px solid ${theme.palette.text.lightOn} !important`,

		[theme.breakpoints.up('sm')]: {
			marginRight: `15px !important`
		},
		[theme.breakpoints.down('sm')]: {
			margin: `15px 0 !important`
		}
	},
	navLink: {
		fontFamily: 'Roboto',
		fontSize: 14,
		color: theme.palette.text.lightOn,
		textDecoration: 'none',
		margin: '0 37px',
		cursor: 'pointer',

		[theme.breakpoints.only('md')]: {
			margin: '0 10px',
		},
		[theme.breakpoints.down('sm')]: {
			margin: '15px 0'
		},
		'&:hover': {
			color: theme.palette.text.trueWhite,
			textDecoration: 'underline',
			textDecorationColor: theme.palette.primary.main,
			textUnderlineOffset: '5px',
			textDecorationThickness: '2px'
		}
	},
	appBarMobile: {
		backgroundColor: `${theme.palette.background.main} !important`,
		height: 80,
		justifyContent: 'center',
		color: theme.palette.text.trueWhite
	},
	dialogContent: {
		backgroundColor: theme.palette.background.main,
		color: theme.palette.text.greyDetails,
		flexGrow: 1,
		marginTop: 80,
		padding: `0 ${theme.spacing(3)}px`,
		display: 'flex',
		flexDirection: 'column',

		[theme.breakpoints.down('md')]: {
			textAlign: 'center'
		}
	},
	menuButton: {
		marginRight: '30px !important',
		marginLeft: '10px !important',
		color: theme.palette.text.trueWhite
	},
	buttonsMobile: {
		display: 'flex',
		flexDirection: 'column-reverse',
		marginTop: theme.spacing(2)
	}
}));

const Transition = forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

const Header = () => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const [openMenu, setOpenMenu] = useState(false);

	const handleOpenMenu = () => {
		setOpenMenu(true);
	};

	const handleCloseMenu = () => {
		setOpenMenu(false);
	};

	const navigateToElement = name => {
		scrollToElement(name);
		handleCloseMenu();
	};

	const links = <>
		<p className={classes.navLink} style={{ marginLeft: 0 }} onClick={() => navigateToElement('home')}>
			HOME
		</p>
		<p className={classes.navLink} onClick={() => navigateToElement('about-game')}>
			ABOUT GAME
		</p>
		<p className={classes.navLink} onClick={() => navigateToElement('collection')}>
			COLLECTION
		</p>
		<p className={classes.navLink} onClick={() => navigateToElement('crafting')}>
			CRAFTING
		</p>
		<p className={classes.navLink} style={{ marginRight: 0 }} onClick={() => navigateToElement('marketplace')}>
			MARKETPLACE
		</p>
	</>;

	const buttons = <>
		<Button variant="outlined" className={classes.buttonGrey} onClick={() => dispatch(setSignInDialogOpen(true))}>Sign in</Button>
		<Button color="primary" variant="contained" onClick={() => dispatch(setSignUpDialogOpen(true))}>Sign up</Button>
	</>;

	return (
		<ScrollToColor>
			<AppBar position="fixed" className={classes.appBar}>
				<Toolbar className={classes.toolbar}>
					<Grid container item xs={2} md={1} lg={2} alignItems="center">
						<LogoIcon className={classes.logo} />
					</Grid>
					<Hidden only={['xs', 'sm']}>
						<Grid container item xs={8} justifyContent="center" alignItems="center">
							{links}
						</Grid>
						<Grid container item xs={2} md={3} lg={2} justifyContent="flex-end" alignItems="center">
							{buttons}
						</Grid>
					</Hidden>
					<Hidden mdUp>
						<div className="display-flex flex-grow-full" />
						<Button color="primary" variant="contained" onClick={() => dispatch(setSignUpDialogOpen(true))}>Sign up</Button>
						<IconButton color="inherit" aria-label="open menu" onClick={handleOpenMenu} className={classes.menuButton}>
							<MenuIcon />
						</IconButton>
						<Dialog fullScreen open={openMenu} onClose={handleCloseMenu} TransitionComponent={Transition}>
							<AppBar className={classes.appBarMobile}>
								<Toolbar>
									<LogoIcon className={classes.logo} />
									<div className="display-flex flex-grow-full" />
									<IconButton edge="start" color="inherit" onClick={handleCloseMenu} aria-label="close">
										<CloseIcon />
									</IconButton>
								</Toolbar>
							</AppBar>
							<div className={classes.dialogContent}>
								{links}
								<div className={classes.buttonsMobile}>
									{buttons}
								</div>
							</div>
						</Dialog>
					</Hidden>
				</Toolbar>
			</AppBar>
		</ScrollToColor>
	);
}

export default Header;