import { Grid, Hidden } from '@material-ui/core';
import clsx from 'clsx';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import ButtonPrimary from '../../UI/Buttons/ButtonPrimary/ButtonPrimary';
import BuyErrorDialog from '../../UI/Dialogs/BuyErrorDialog/BuyErrorDialog';
import BuyPackDialogContent from '../BuyPackDialogContent/BuyPackDialogContent';
import ConfirmBuyPackDialogContent from '../ConfirmBuyPackDialogContent/ConfirmBuyPackDialogContent';
import CustomDialog from './../../UI/Dialogs/CustomDialog/CustomDialog';
import CoinIcon from './../../UI/Icons/CoinIcon/CoinIcon';
import { useStyles } from './PackBoxStyle';

const PackBox = (props) => {
    const classes = useStyles();

	const userBalance = useSelector((state) => state.user.data?.userWallet?.coins);

	const [openErrorDialog, setOpenErrorDialog] = useState(false);
	const [openPurchaseDialog, setOpenPurchaseDialog] = useState(false);
	const [openConfimationDialog, setOpenConfimationDialog] = useState(false);
	const [generatedPackId, setGeneratedPackId] = useState();
	
	const [quantity, setQuantity] = useState(1);
	
	const handleBuyButtonClick = () => {
		setOpenPurchaseDialog(false);
		// TODO POST buy pack and update user wallet
		setGeneratedPackId(1); // TODO set id from backend
		setOpenConfimationDialog(true);
	};

	const handleOpenPurchaseDialog = () => {
		if (Number(userBalance) >= Number(props.pack?.price)) {
			setOpenPurchaseDialog(true);
		} else {
			setOpenErrorDialog(true);
		}
	}

    return (
		<>
			<Grid container item className={clsx(classes.packBox, "panel-fill display-flex")}>
				<Grid item container xs={12} sm={5} alignItems="center" className="display-flex">
					<img src={props.pack?.image?.url} alt="Pack img" className={classes.packImg} />
				</Grid>
				<Grid item xs={12} sm={7} className={classes.infoBox}>
					<h3 className={classes.title}>{props.pack?.name}</h3>
					<p className={classes.subtitle}>{props.pack?.description}</p>
					<Hidden mdUp>
						<div className="display-flex align-items-center">
							<CoinIcon />&nbsp;<p>{props.pack?.price}</p>
						</div>	
						<ButtonPrimary onClick={handleOpenPurchaseDialog}>BUY NOW</ButtonPrimary>
					</Hidden>
					<Hidden smDown>
						<div className="display-flex align-items-center">
							<ButtonPrimary onClick={handleOpenPurchaseDialog}>BUY NOW</ButtonPrimary>&nbsp;&nbsp;&nbsp;
							<CoinIcon />&nbsp;<p>{props.pack?.price}</p>
						</div>
					</Hidden>
				</Grid>
			</Grid>
			<BuyErrorDialog open={openErrorDialog} handleClose={() => setOpenErrorDialog(false)} />
			<CustomDialog
				title="You are purchasing an item" 
				maxWidth="sm"
				open={openPurchaseDialog}
				handleClose={() => setOpenPurchaseDialog(false)}>
				<BuyPackDialogContent 
					onClose={() => setOpenPurchaseDialog(false)}
					handleBuyButtonClick={handleBuyButtonClick}
					quantity={quantity}
					setQuantity={setQuantity}
					id={props.pack?.id}
					img={props.pack?.image?.url}
					name={props.pack?.name}
					price={props.pack?.price} />
			</CustomDialog>
			<CustomDialog
				title="Confirmation" 
				maxWidth="md"
				open={openConfimationDialog}
				handleClose={() => setOpenConfimationDialog(false)}>
				<ConfirmBuyPackDialogContent packId={generatedPackId} />
			</CustomDialog>
		</>
    );
}

export default PackBox;