import { Button, ButtonGroup } from '@material-ui/core';
import { useState } from 'react';
import { useStyles } from './IncrementDecrementButtonGroupStyle';

const IncrementDecrementButtonGroup = props => {
	const classes = useStyles();
	const [quantity, setQuantity] = useState(props.quantity);

	const minusButtonClickHandle = () => {
		props.minusButtonClickHandle(quantity-1);
		setQuantity(quantity-1);
	};

	const plusButtonClickHandle = () => {
		props.plusButtonClickHandle(quantity+1);
		setQuantity(quantity+1);
	};

	return (
		<ButtonGroup size="large" className={classes.quantityButtons} aria-label="small outlined button group">
			<Button 
				disabled={quantity === props.minusButtonDisabled} 
				onClick={minusButtonClickHandle}
			>-</Button>
			<Button disabled>{quantity}</Button>
			<Button 
				disabled={quantity === props.plusButtonDisabled} 
				className="plus-button" 
				onClick={plusButtonClickHandle}
			>+</Button>
		</ButtonGroup>
	);
}

export default IncrementDecrementButtonGroup;