import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
	select: {
		color: theme.palette.text.lightOn,
		border: `1px solid ${theme.palette.border.main}`,
		borderRadius: '3px',
		height: '50px',

		'& svg': {
			color: theme.palette.text.lightOn
		}
	},
	formControl: {
		width: '300px',
		verticalAlign: 'middle',
		textAlign: 'left',

		[theme.breakpoints.down("sm")]: {
			width: '100%',
			margin: `${theme.spacing(1)}px 0`,
		}
	},
	font: {
		fontFamily: 'Klavika',
		fontSize: '14px',
		lineHeight: '17px',
		letterSpacing: '1.25px'
	},
	label: {
		marginBottom: 0,
		fontSize: '14px',
		color: theme.palette.text.trueWhite
	}
}));

export { useStyles };