import { CircularProgress, Grid } from '@material-ui/core';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as StoreIcon } from '../../assets/img/shopping-bag.svg';
import { fetchPacks } from './../../store/slices/packs/PacksSlice';
import PageHeader from './../UI/PageHeader/PageHeader';
import PackBox from './PackBox/PackBox';

const Store = () => {
	const dispatch = useDispatch();
	const packsStatus = useSelector(state => state.packs.status);
	const packs = useSelector(state => state.packs.data);

	useEffect(() => {
		dispatch(fetchPacks());
	}, [dispatch]);

	return (
	  <>
		<PageHeader title="STORE" icon={<StoreIcon />} />
		<br />
		<Grid container item xs={12} alignItems="center" justifyContent="center" spacing={2}>
			{(packsStatus === 'init' || packsStatus === 'pending') && <div className="text-align-center"><CircularProgress /></div>}
			{(packsStatus === 'fulfilled' || packsStatus === 'rejected') && (!packs?.length ? 
				<p className="text-align-center">No data :(</p> : 
				packs.map(pack => (
					<Grid item container xs={12} md={6} lg={4} key={pack.id}>
						<PackBox pack={pack} />
					</Grid>))
				)}
		</Grid>
	  </>
	);
}
  
export default Store;