import { CircularProgress, Divider, Grid, Hidden, InputAdornment, TextField } from '@material-ui/core';
import Search from '@material-ui/icons/Search';
import clsx from "clsx";
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as CheckMarkIcon } from '../../assets/img/check-mark.svg';
import { ReactComponent as ClockIcon } from '../../assets/img/clockwise.svg';
import { ReactComponent as WalletGreenIcon } from '../../assets/img/wallet-green.svg';
import { ReactComponent as WarningIcon } from '../../assets/img/warning.svg';
import { fetchTransactionHistory } from './../../store/slices/user/TransactionHistorySlice';
// import { useState } from 'react';
// import Pagination from '@material-ui/Pagination';
import { getStatusColor } from './../../utils/status';
import ButtonPrimary from './../UI/Buttons/ButtonPrimary/ButtonPrimary';
import PageHeader from './../UI/PageHeader/PageHeader';
import { useStyles } from './TransactionHistoryStyle';

const TransactionHistory = () => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const transactionHistoryStatus = useSelector((state) => state.transactionHistory.status);
	const transactionHistory = useSelector((state) => state.transactionHistory.data);
	// const [page, setPage] = useState(1);

  	// const handlePageChange = (event, value) => {
	// 	setPage(value);
  	// };

	useEffect(() => {
		dispatch(fetchTransactionHistory());
	}, [dispatch]);

	const getStatusInfo = status => {
		switch (status) {
			case 'DONE': return <><CheckMarkIcon fill={getStatusColor(status)} /> Done</>;
			case 'REJECT': return <><WarningIcon fill={getStatusColor(status)} /> Reject</>;
			case 'IN_PROGRES': return <><ClockIcon fill={getStatusColor(status)} /> In progres</>;
			default: break;
		}
	};

	return (
		<>
			<PageHeader title="YOUR TRANSACTION HISTORY" icon={<WalletGreenIcon />} />
			<Grid container className={classes.filterBox}>
				<Grid item xs={12} lg={4} className="display-flex">
					<TextField
						className={classes.searchInput}
						// value={searchInputValue}
						// onChange={(event) => setSearchInputValue(event.target.value)}
						variant="outlined" 
						placeholder="Search"
						InputProps={{
							endAdornment: <InputAdornment position="end"><Search /></InputAdornment>
						}}
					/>
					<ButtonPrimary 
						className={classes.searchButton}
						// onClick={() => dispatch(setFilterName(searchInputValue))}
					>SEARCH</ButtonPrimary>
				</Grid>
			</Grid>
			<br />
			<Grid container justifyContent="center">
				{(transactionHistoryStatus === 'init' || transactionHistoryStatus === 'pending') && <div className="text-align-center"><CircularProgress /></div>}
				{(transactionHistoryStatus === 'fulfilled' || transactionHistoryStatus === 'rejected') && (!transactionHistory?.length ? 
					<p className="text-align-center">No data :(</p> : 
					transactionHistory.map(transaction => (
						<Grid key={transaction.id} container item xs={12} className={clsx("panel-fill", classes.transactionBox)} alignItems="center">
							<Grid item xs={12} md={4} lg={2} className={clsx("display-flex align-items-center", classes.transactionTitle)}>
								<div className={classes.icon}>
									<WalletGreenIcon /> 
								</div>
								<div className="text-align-left">
									{transaction.name} 
									<div className={classes.transactionId}>ID: {transaction.id}</div>
								</div>
							</Grid>
							<Hidden mdUp>
								<Divider orientation="horizontal" className={classes.divider} />
							</Hidden>
							<Grid item xs={12} md={4} lg={2}>
								<span className={classes.title}>Top-up amount: </span>{Number(transaction.amount || 0).toFixed(2)} PLN
							</Grid>
							<Hidden mdUp>
								<Divider orientation="horizontal" className={classes.divider} />
							</Hidden>
							<Grid item xs={12} md={4} lg={2}>
								<span className={classes.title}>Execution date: </span>{new Date(transaction.executionDate).toLocaleString()}
							</Grid>
							<Hidden mdUp>
								<Divider orientation="horizontal" className={classes.divider} />
							</Hidden>
							<Grid item xs={12} md={4} lg={2}>
								<span className={classes.title}>Paid by: </span>{transaction.paidBy}
							</Grid>
							<Hidden mdUp>
								<Divider orientation="horizontal" className={classes.divider} />
							</Hidden>
							<Grid item xs={12} md={4} lg={2}>
								<span className={classes.title}>Payment method: </span>{transaction.paymentMethod}
							</Grid>
							<Hidden mdUp>
								<Divider orientation="horizontal" className={classes.divider} />
							</Hidden>
							<Grid item xs={12} md={4} lg={2}>
								<div className={classes.statusInfo} style={{ color: getStatusColor(transaction.status) }}>
									{getStatusInfo(transaction.status)}
								</div>
							</Grid>
						</Grid>
					))
				)}
			</Grid>
			{/* <Grid container alignItems="center">
				<Hidden mdDown>
					<Grid item xs={2}>Page {page}/11</Grid>
				</Hidden>
				<Grid item xs={12} md={10} justifyContent="flex-end">
					<Pagination count={10} page={page} onChange={handlePageChange} />
				</Grid>
			</Grid> */}
		</>
	);
}
  
export default TransactionHistory;