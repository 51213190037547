import { Grid } from '@material-ui/core';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Slider1Img from '../../../assets/img/slider-1.svg';
import { useStyles } from './SliderBoxStyle';

const SliderBox = () => {
	const classes = useStyles();

	return (
		<Grid item container className={classes.sliderBox}>
			<Grid item xs={12}>
				<Carousel showArrows={true} showThumbs={false} showStatus={false} showIndicators={false} autoPlay infiniteLoop>
					<img src={Slider1Img} alt="Slider1" />
					<img src={Slider1Img} alt="Slider2" />
				</Carousel>
			</Grid>
		</Grid>
	);
}
  
export default SliderBox;