import { ListItemIcon, MenuItem } from "@material-ui/core";
import { forwardRef } from "react";
import { NavLink } from "react-router-dom";
import { ReactComponent as ConnectAccountIcon } from '../../../../../assets/img/connect-account.svg';
import { ReactComponent as HistoryIcon } from '../../../../../assets/img/history.svg';
import { ReactComponent as SettingsIcon } from '../../../../../assets/img/settings.svg';
import { ReactComponent as TeamWorkIcon } from '../../../../../assets/img/teamwork.svg';
import { ReactComponent as WalletIcon } from '../../../../../assets/img/wallet.svg';
import { useStyles } from "./UserMenuItemsStyle";

const UserMenuItems = forwardRef((props, ref) => {
	const classes = useStyles();

	return (
		<>
			<MenuItem onClick={props.handleUserMenuClose} className={classes.userMenuListItem}>
				<NavLink to="/user-profile" className={classes.navLink}>
					<ListItemIcon>
						<SettingsIcon className={classes.userMenuListItem} />
					</ListItemIcon>
					Edit profile
				</NavLink>
			</MenuItem>
			<MenuItem onClick={props.handleUserMenuClose} className={classes.userMenuListItem}>
				<NavLink to="/buy-credit" className={classes.navLink}>
					<ListItemIcon>
						<WalletIcon className={classes.userMenuListItem} />
					</ListItemIcon>
					Buy credit
				</NavLink>
			</MenuItem>
			<MenuItem onClick={props.handleUserMenuClose} className={classes.userMenuListItem}>
				<NavLink to="/transaction-history" className={classes.navLink}>
					<ListItemIcon>
						<HistoryIcon className={classes.userMenuListItem} />
					</ListItemIcon>
					Transaction history
				</NavLink>
			</MenuItem>
			<MenuItem onClick={props.handleUserMenuClose} className={classes.userMenuListItem}>
				<NavLink to="/user-profile#connect-account" className={classes.navLink}>
					<ListItemIcon>
						<ConnectAccountIcon className={classes.userMenuListItem} />
					</ListItemIcon>
					Connect account
				</NavLink>
			</MenuItem>
			<MenuItem onClick={props.handleUserMenuClose} className={classes.userMenuListItem}>
				<ListItemIcon>
					<TeamWorkIcon className={classes.userMenuListItem} />
				</ListItemIcon>
				Partner program
			</MenuItem>
		</>
	);
});

export default UserMenuItems;