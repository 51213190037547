import { Grid, Hidden } from "@material-ui/core";
import { Fragment } from "react";
import { useState } from 'react';
import { Carousel } from "react-responsive-carousel";
import DropdownImg from '../../../assets/img/dropdown.svg';
import DiamondPackBox from './../../Dashboard/DiamondPackBox/DiamondPackBox';
import ButtonGreyOutlined from './../../UI/Buttons/ButtonGreyOutlined/ButtonGreyOutlined';
import './PacksSlider.scss';
import { useStyles } from "./PacksSliderStyle";

const PacksSlider = (props) => {
	const classes = useStyles();
	const [currentIndex, setSlide] = useState(0);
    const sliders = [<DiamondPackBox />, <DiamondPackBox />, <DiamondPackBox />, <DiamondPackBox />, <DiamondPackBox />, <DiamondPackBox />, <DiamondPackBox />];

    return (
		<div className="packs-slider">
			<Hidden mdDown>
				{sliders?.length > 3 && (
					<Grid container item xs={12}>
						<Grid item xs={12} className="text-align-right">
							<ButtonGreyOutlined className={classes.arrowButton} onClick={() => setSlide((currentIndex - 3 + sliders.length) % sliders.length)}>
								<img src={DropdownImg} className={classes.arrowLeft} alt="Prev" />
							</ButtonGreyOutlined>
							<ButtonGreyOutlined className={classes.arrowButton} onClick={() => setSlide((currentIndex + 3) % sliders.length)}>
								<img src={DropdownImg} className={classes.arrowRight} alt="Next" />
							</ButtonGreyOutlined>
						</Grid>
					</Grid>
				)}
				{sliders?.length <= 3 && (<><br /><br /></>)}
				<br />
				<Grid container item xs={12} justifyContent="center">
					{sliders?.length <= 3 && sliders.map((slider, index) => <Grid item xs={4} key={index}>{slider}</Grid>)}
					{sliders?.length > 3 && (
						<Carousel
							centerMode
							infiniteLoop
							centerSlidePercentage={100 / 3}
							selectedItem={currentIndex}
							showArrows={false}
							showThumbs={false}
							showStatus={false}
							showIndicators={false}
						>
							{sliders.map((slider, index) => <Fragment key={index}>{slider}</Fragment>)}
						</Carousel>
					)}
				</Grid>
			</Hidden>
			<Hidden lgUp>
				{sliders?.length === 1 && <DiamondPackBox />}
				{sliders?.length > 1 && (
					<Carousel
						infiniteLoop
						selectedItem={currentIndex}
						showArrows={false}
						showThumbs={false}
						showStatus={false}
						showIndicators={true}
					>
						{sliders.map(x => x)}
					</Carousel>
				)}
			</Hidden>
		</div>
    );
}

export default PacksSlider;