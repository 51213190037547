import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
	sellCardDialog: {
		padding: theme.spacing(3),
	},
	sellBox: {
		textAlign: 'left',

		[theme.breakpoints.only("xs")]: {
			textAlign: 'center'
		}
	},
	name: {
		fontSize: 30,
		color: theme.palette.text.trueWhite,
		marginTop: 0,
		marginBottom: 0,

		[theme.breakpoints.only("xs")]: {
			fontSize: 24
		}
	},
	nameDescription: {
		marginBottom: 0
	},
	description: {
		fontSize: 14,
		color: theme.palette.text.trueWhite,
		marginBottom: theme.spacing(1),
		textAlign: 'left'
	},
	cardStack: {
		fontSize: 15,
		color: theme.palette.text.trueWhite,
		marginBottom: theme.spacing(2),
		marginTop: theme.spacing(1),
		display: 'flex',
		alignItems: 'center',

		[theme.breakpoints.only("xs")]: {
			justifyContent: 'center'
		},
		'& svg': {
			fill: theme.palette.primary.main,
			marginRight: theme.spacing(1)
		}
	},
	sellButton: {
		marginTop: theme.spacing(2)
	}
}));

export { useStyles };