import { CircularProgress, Grid, Hidden } from "@material-ui/core";
import clsx from "clsx";
import SectionHeader from "../UI/SectionHeader/SectionHeader";
import CardBasicInfo from "./CardBasicInfo/CardBasicInfo";
import CardOffers from "./CardOffers/CardOffers";
import { useStyles } from "./MarketplaceItemStyle";
import OtherPopularCards from "./OtherPopularCards/OtherPopularCards";
import PacksSlider from "./PacksSlider/PacksSlider";
import StatisticsChart from "./StatisticsChart/StatisticsChart";
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from "react-router-dom";
import { useEffect } from 'react';
import { fetchCard } from '../../store/slices/card-details/CardSlice';
import { fetchCardStatistics } from "../../store/slices/card-details/CardStatisticsSlice";
import { fetchCardsOtherPopular } from "../../store/slices/card-details/CardsOtherPopularSlice";
import { fetchCardOffers } from "../../store/slices/card-details/CardOffersSlice";

const MarketplaceItem = (props) => {
	const classes = useStyles();

	const dispatch = useDispatch();
	const cardStatus = useSelector((state) => state.card.status);
	const card = useSelector((state) => state.card.data);
	const cardStatisticsStatus = useSelector((state) => state.cardStatistics.status);
	const cardStatistics = useSelector((state) => state.cardStatistics.data);
	const cardsOtherPopularStatus = useSelector((state) => state.cardsOtherPopular.status);
	const cardsOtherPopular = useSelector((state) => state.cardsOtherPopular.data);
	const cardOffersStatus = useSelector((state) => state.cardOffers.status);
	const cardOffers = useSelector((state) => state.cardOffers.data);

	const { cardId } = useParams();

	useEffect(() => {
		if (cardId) {
			dispatch(fetchCard({ id: cardId }));
			dispatch(fetchCardStatistics({ id: cardId }));
			dispatch(fetchCardsOtherPopular({ id: cardId }));
			dispatch(fetchCardOffers({ cardId: cardId }));
		}
	}, [cardId, dispatch]);

	return (
		<>
			{(cardStatus === 'init' || cardStatus === 'pending') && <div className="text-align-center"><CircularProgress /></div>}
			{cardStatus === 'fulfilled' && <CardBasicInfo card={card} />}

			<div className={classes.packsBox}>
				<PacksSlider />
			</div>
			<br />
			<Grid container item xs={12} spacing={2} className={clsx(classes.sectionsBox, "display-flex")}>
				<Grid item xs={12} lg={8}>
					{(cardStatisticsStatus === 'init' || cardStatisticsStatus === 'pending') && <div className="text-align-center"><CircularProgress /></div>}
					{cardStatisticsStatus === 'fulfilled' && (
						<>
							<SectionHeader title="STATISTICS" subtitle="Watch the card's popularity among players" />
							<StatisticsChart cardStatistics={cardStatistics} />
						</>
					)}
				</Grid>
				<Hidden smDown>
					<Grid item xs={12} lg={4}>
						{(cardsOtherPopularStatus === 'init' || cardsOtherPopularStatus === 'pending') && <div className="text-align-center"><CircularProgress /></div>}
						{cardsOtherPopularStatus === 'fulfilled' && (
							<>
								<SectionHeader title="OTHER POPULAR CARDS" subtitle="See also other cards in this series" />
								<OtherPopularCards cardsOtherPopular={cardsOtherPopular} />
							</>
						)}
					</Grid>
				</Hidden>
				<Grid item xs={12}>
					{(cardOffersStatus === 'init' || cardOffersStatus === 'pending') && <div className="text-align-center"><CircularProgress /></div>}
					{cardOffersStatus === 'fulfilled' && cardStatus === 'fulfilled' && (
						<>
							<SectionHeader title="MARKETPLACE" subtitle="Card purchase offers" />
							<CardOffers cardOffers={cardOffers} card={card} />
						</>
					)}
				</Grid>
			</Grid>
		</>
	);
}

export default MarketplaceItem;