import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
	circularProgres: {
		position: 'relative'
	},
	circleTop: {
		color: props => props.color === 'success' ? theme.palette.auxiliary.success : theme.palette.primary.main,
		position: 'absolute',
		left: 0,
	},
	circleBottom: {
		color: theme.palette.text.greyStuned
	}
}));

export { useStyles };