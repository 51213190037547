import { getRarityColor } from '../../../utils/rarityColor';
import AnimatedCard from '../../AnimatedCard/AnimatedCard';
import { useStyles } from './OtherPopularCardsStyle';

const OtherPopularCards = (props) => {
	const classes = useStyles();

	return (props.cardsOtherPopular.map(card => (
		<div key={card.id} className={classes.popularCardBox}>
			<div className={classes.cardBox}>
				<AnimatedCard card={card?.card} size="sm" noRotate />
			</div>
			<div>
				<span className={classes.name}>{card?.card?.name}</span>
				<br />
				<span 
					className={classes.rarity}
					style={{ color: getRarityColor(card?.card?.cardType?.name) }}
				>
					{card?.card?.cardType?.name}&nbsp;
				</span>
				<span className={classes.organization}>{card?.card?.cardCollection?.organisation?.name}</span>
			</div>
			<div className="flex-grow-full" />
			<p className={classes.price}>FROM {Number(card?.price).toFixed(2)} PLN</p>
		</div>
	)));
}

export default OtherPopularCards;