import axios from 'axios';

console.log(process.env.NODE_ENV);

const instance = axios.create({
	baseURL: 'https://api.martialcards.pl/api/v1/',
	// baseURL: 'http://localhost:3001/api/v1/',
	headers: { 'Access-Control-Allow-Origin': '*' }
});

export default instance;