import { Grid, Button } from '@material-ui/core';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import CardBasicInfo from './../../MarketplaceItem/CardBasicInfo/CardBasicInfo';
import SectionHeader from './../../UI/SectionHeader/SectionHeader';
import CardOffers from './../../MarketplaceItem/CardOffers/CardOffers';
import { useStyles } from './CardViewStyle';
import { setSelectedCard } from '../../../store/slices/library/LibraryYourCardsSlice';
import { useDispatch } from 'react-redux';

const CardView = props => {
    const classes = useStyles();
	const dispatch = useDispatch();

    return (
        <Grid container item xs={8} className="flex-grow-full max-width-100">
			<div className={classes.backButtonBox}>
				<Button onClick={() => dispatch(setSelectedCard(null))}>
					<NavigateBeforeIcon />BACK
				</Button>
			</div>
			<Grid item xs={12} className={classes.cardBasicInfoBox}>
				<CardBasicInfo card={props.card} libraryView sellOnMarket />
			</Grid>
			<Grid item xs={12} className={classes.cardMarketListings}>
				<SectionHeader title="MARKET LISTINGS" subtitle="See also other cards in this series" />
				<CardOffers cardOffers={props.cardOffers} card={props.card} noBuy />
			</Grid>
		</Grid>
    );
}

export default CardView;