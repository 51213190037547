import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
	flexGrow: {
		flexGrow: 1
	},
	displayFlex: {
		display: 'flex'
	},
	pageHeader: {
		marginTop: `-${theme.spacing(3)}px`,
		marginLeft: `-${theme.spacing(3)}px`,
		marginRight: `-${theme.spacing(3)}px`,
		backgroundColor: theme.palette.background.main,
		background: `linear-gradient(169.5deg, rgba(10, 15, 29, 0) -33.62%, ${theme.palette.border.panel} 125.58%)`,
		padding: theme.spacing(3),
		height: '94px',
		alignItems: 'center'
	},
	icon: {
		borderRadius: '50%',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		height: '48px',
		minWidth: '48px',
		marginRight: '10px',
		border: `1px solid ${theme.palette.primary.main}`,

		'& svg': {
			height: '19px',
			width: '19px',
			fill: theme.palette.primary.main
		}
	},
	title: {
		fontFamily: 'Klavika',
		fontWeight: 'bold',
		fontSize: '16px',
		lineHeight: '19px',
		color: theme.palette.text.trueWhite,
		textTransform: 'uppercase'
	}
}));

export { useStyles };