import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
	filterBox: {
		marginLeft: `-${theme.spacing(3)}px`,
		marginRight: `-${theme.spacing(3)}px`,
		background: theme.palette.background.main,
		borderBottom: `1px solid ${theme.palette.border.main}`,
		padding: `${theme.spacing(2)}px ${theme.spacing(3)}px`,
		width: 'auto',
		alignItems: 'center'
	},
	filterButton: {
		width: '152px',
		fontSize: '14px',
		lineHeight: '17px',
		letterSpacing: '1.25px',
		color: '#DCDDDD',
		background: theme.palette.background.panel,
		margin: theme.spacing(1),

		'&:hover': {
			border: `1px solid ${theme.palette.primary.main}`,
			opacity: 0.7
		},
		'& svg': {
			fill: theme.palette.text.trueWhite,
			width: '24px',
			height: '22px',
			marginRight: '10px'
		}
	},
	activeFilterButton: {
		border: `1px solid ${theme.palette.primary.main}`,
		background: theme.palette.background.main,
		'& svg': {
			fill: theme.palette.primary.main
		}
	},
	displayFlex: {
		display: 'flex'
	},
	flexGrow: {
		flexGrow: 1
	},
	filterSelectsBox: {
		textAlign: 'left',
		
		[theme.breakpoints.up("lg")]: {
			textAlign: 'end',
		}
	},
	filterButtonsBox: {
		[theme.breakpoints.down("md")]: {
			flexDirection: 'column-reverse',
			textAlign: 'center',
		}
	},
	searchInput: {
		width: '100%',
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(1),
	},
	searchButton: {
		margin: theme.spacing(1),
		[theme.breakpoints.down("md")]: {
			marginRight: 0
		}
	},
	scrollableBox: {
		[theme.breakpoints.down("sm")]: {
			overflowX: 'auto',
			width: '85vw',
			display: 'flex',
		}
	}
}));

export { useStyles };