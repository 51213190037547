import { List, ListItem, ListItemIcon, ListItemText, Tooltip } from '@material-ui/core';
import { Link, NavLink } from 'react-router-dom';
import { ReactComponent as LibraryIcon } from '../../../../../assets/img/card.svg';
import { ReactComponent as CraftingIcon } from '../../../../../assets/img/crafting.svg';
import { ReactComponent as HomeIcon } from '../../../../../assets/img/home.svg';
import { ReactComponent as LogoutIcon } from '../../../../../assets/img/logout.svg';
import { ReactComponent as MarketplaceIcon } from '../../../../../assets/img/marketplace.svg';
import { ReactComponent as QuestIcon } from '../../../../../assets/img/quest.svg';
import { ReactComponent as StoreIcon } from '../../../../../assets/img/shopping-bag.svg';
import { ReactComponent as BackpackIcon } from '../../../../../assets/img/backpack.svg';
import { useStyles } from './MenuItemsStyle';

const MenuItems = (props) => {
	const classes = useStyles(props);

	return (
		<>
			<List className={classes.menuList}>
				<ListItem button key="Home" onClick={props.handleCloseMenu} className={classes.listItem}>
					<Tooltip title={"Home"} placement="right" arrow disableTouchListener={true}>
						<NavLink to="/" className={classes.navLink}>
								<ListItemIcon className={classes.listItemIcon}>
									<HomeIcon />
								</ListItemIcon>
							{props.isMobile && <ListItemText>Home</ListItemText>}
						</NavLink>
					</Tooltip>
				</ListItem>
				<ListItem button key="Your packs" onClick={props.handleCloseMenu} className={classes.listItem}>
					<Tooltip title={"Your packs"} placement="right" arrow disableTouchListener={true}>
						<NavLink to="/your-packs" className={classes.navLink}>
							<ListItemIcon className={classes.listItemIcon}>
								<LibraryIcon />
							</ListItemIcon>
							{props.isMobile && <ListItemText>Your packs</ListItemText>}
						</NavLink>
					</Tooltip>
				</ListItem>
				<ListItem button key="Library" onClick={props.handleCloseMenu} className={classes.listItem}>
					<Tooltip title={"Library"} placement="right" arrow disableTouchListener={true}>
						<NavLink to="/library" className={classes.navLink}>
							<ListItemIcon className={classes.listItemIcon}>
								<BackpackIcon />
							</ListItemIcon>
							{props.isMobile && <ListItemText>Library</ListItemText>}
						</NavLink>
					</Tooltip>
				</ListItem>
				<ListItem button key="Store" onClick={props.handleCloseMenu} className={classes.listItem}>
					<Tooltip title={"Store"} placement="right" arrow disableTouchListener={true}>
						<NavLink to="/store" className={classes.navLink}>
							<ListItemIcon className={classes.listItemIcon}>
								<StoreIcon />
							</ListItemIcon>
							{props.isMobile && <ListItemText>Store</ListItemText>}
						</NavLink>
					</Tooltip>
				</ListItem>
				<ListItem button key="Marketplace" onClick={props.handleCloseMenu} className={classes.listItem}>
					<Tooltip title={"Marketplace"} placement="right" arrow disableTouchListener={true}>
						<NavLink to="/marketplace" className={classes.navLink}>
							<ListItemIcon className={classes.listItemIcon}>
								<MarketplaceIcon />
							</ListItemIcon>
							{props.isMobile && <ListItemText>Marketplace</ListItemText>}
						</NavLink>
					</Tooltip>
				</ListItem>
				<ListItem button key="Crafting" onClick={props.handleCloseMenu} className={classes.listItem}>
					<Tooltip title={"Crafting"} placement="right" arrow disableTouchListener={true}>
						<NavLink to="/crafting" className={classes.navLink}>
							<ListItemIcon className={classes.listItemIcon}>
								<CraftingIcon />
							</ListItemIcon>
							{props.isMobile && <ListItemText>Crafting</ListItemText>}
						</NavLink>
					</Tooltip>
				</ListItem>
				<ListItem button key="Quest" onClick={props.handleCloseMenu} className={classes.listItem}>
					<Tooltip title={"Quest"} placement="right" arrow disableTouchListener={true}>
						<NavLink to="/quest" className={classes.navLink}>
							<ListItemIcon className={classes.listItemIcon}>
								<QuestIcon />
							</ListItemIcon>
							{props.isMobile && <ListItemText>Quest</ListItemText>}
						</NavLink>
					</Tooltip>
				</ListItem>
			</List>
			<List>
				<ListItem button key="Logout" onClick={props.handleCloseMenu} className={classes.listItem}>
					<Tooltip title={"Logout"} placement="right" arrow disableTouchListener={true}>
						<Link to="/" className={classes.navLink} onClick={() => localStorage.removeItem('token')}>
							<ListItemIcon className={classes.listItemIcon}>
								<LogoutIcon />
							</ListItemIcon>
							{props.isMobile && <ListItemText>Logout</ListItemText>}
						</Link>
					</Tooltip>
				</ListItem>
			</List>
		</>
	);
}

export default MenuItems;