import { TableRow, withStyles } from "@material-ui/core";

export const StyledTableRow = withStyles((theme) => ({
	root: {
		backgroundColor: theme.palette.background.panel,
		color: theme.palette.text.trueWhite,

		'&:hover': {
			backgroundColor: theme.palette.background.main
		}
	},
}))(TableRow);