import { Divider, Grid, Hidden } from "@material-ui/core";
import { Atropos } from 'atropos/react/atropos-react.esm';
import clsx from "clsx";
import { useState } from 'react';
import { ReactComponent as MarketplaceIcon } from '../../../assets/img/marketplace.svg';
import AnimatedCard from "../../AnimatedCard/AnimatedCard";
import BadgeRed from "../../UI/Badges/BadgeRed/BadgeRed";
import ButtonPrimary from "../../UI/Buttons/ButtonPrimary/ButtonPrimary";
import SellCardDialogContent from "../SellCardDialogContent/SellCardDialogContent";
import CustomDialog from './../../UI/Dialogs/CustomDialog/CustomDialog';
import ConfirmSellCardDialogContent from './../ConfirmSellCardDialogContent/ConfirmSellCardDialogContent';
import { useStyles } from "./CardBasicInfoStyle";

const CardBasicInfo = (props) => {
	const classes = useStyles(props);
	const [openSellDialog, setOpenSellDialog] = useState(false);
	const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);

	const handleSell = (data) => {
		console.log('submit', data);
		// TODO post sell
		setOpenSellDialog(false);
		setOpenConfirmationDialog(true);
	};

    return (
        <div className={classes.marketplaceItemBox}>
			<Grid container item className={clsx(classes.marketplaceItem, "display-flex")}>
				<Grid item xs={12} lg={3} className="text-align-center display-flex justify-content-center align-items-center">
					{props.card && <>
						<Hidden lgUp>
							<AnimatedCard card={props.card} size="lg" />
						</Hidden>
						<Hidden mdDown>
							<AnimatedCard card={props.card} size={props.libraryView ? "sm" : "lg"} />
						</Hidden>
					</>}
				</Grid>
				<Grid item container xs={12} lg={9} className={classes.cardInfoBox}>
					<Grid item container xs={12} lg={props.libraryView ? 7 : 8} xl={8} className={classes.cardInfo}>
						<Grid item xs={12}>
							<Hidden mdDown><br /></Hidden>
							<br />
							<p className={clsx(classes.titleDescription, classes.titleDescriptionBig)}>CARD NAME</p>
							<p className={classes.titleBig}>{props.card?.name}</p>
						</Grid>
						<Grid item container xs={12} lg={3} alignItems="center">
							<Grid item xs={6} lg={12}>
								<p className={classes.titleDescription}>CARD RARITY</p>
							</Grid>
							<Grid item xs={6} lg={12}>
								<p className={clsx(classes.title, classes.cardRarityFont)}>{props.card?.cardType?.name}</p>
							</Grid>
						</Grid>
						<Hidden lgUp><Divider orientation="horizontal" className={classes.dividerHorizontalMobile} /></Hidden>
						<Grid item container xs={12} lg={3} alignItems="center">
							<Grid item xs={6} lg={12}>
								<p className={classes.titleDescription}>ORGANIZATION</p>
							</Grid>
							<Grid item xs={6} lg={12}>
								<p className={classes.title}>{props.card?.cardCollection?.organisation?.name}</p>
							</Grid>
						</Grid>
						<Hidden lgUp><Divider orientation="horizontal" className={classes.dividerHorizontalMobile} /></Hidden>
						<Grid item container xs={12} lg={3} alignItems="center">
							<Grid item xs={6} lg={12}>
								<p className={classes.titleDescription}>SKILL</p>
							</Grid>
							<Grid item xs={6} lg={12}>
								<p className={classes.title}>{props.card?.cardSkill?.name}</p>
							</Grid>
						</Grid>
						<Hidden lgUp><Divider orientation="horizontal" className={classes.dividerHorizontalMobile} /></Hidden>
						<Grid item container xs={12} lg={3} alignItems="center">
							<Grid item xs={5} lg={12}>
								<p className={classes.titleDescription}>STICKER</p>
							</Grid>
							<Grid item xs={7} lg={12}>
								<p className={classes.title}>{props.card?.sticker?.name}</p>
							</Grid>
						</Grid>
						<Hidden lgUp><Divider orientation="horizontal" className={classes.dividerHorizontalMobile} /></Hidden>
						<Hidden lgUp>
							<Grid item container xs={12} lg={3} alignItems="center">
								<Grid item xs={6} lg={12}>
									<p className={classes.titleDescription}>COLLECTION</p>
								</Grid>
								<Grid item xs={6} lg={12}>
									<p className={classes.title}>{props.card?.cardCollection?.name}</p>
								</Grid>
							</Grid>
							<Divider orientation="horizontal" className={classes.dividerHorizontalMobile} />
							{!props.sellOnMarket && (
								<Grid item container xs={12} lg={3} alignItems="center">
									<Grid item xs={6} lg={12}>
										<p className={classes.titleDescription}>STATUS COLLECTION</p>
									</Grid>
									<Grid item xs={6} lg={12}>
										<div className={classes.badge}>
											<BadgeRed>NOT OWNED</BadgeRed>
										</div>
									</Grid>
								</Grid>
							)}
							{props.sellOnMarket && (
								<Grid item container xs={12} lg={3} alignItems="center">
									<Grid item xs={12} container  alignItems="center">
										<div className={classes.marketplaceIcon}><MarketplaceIcon /></div>
										<p className={classes.sellCard}>Sell this item on the marketplace</p>
									</Grid>
									<Grid item xs={12}>
										<ButtonPrimary fullWidth onClick={() => setOpenSellDialog(true)}>SELL CARD</ButtonPrimary>
									</Grid>
								</Grid>
							)}
						</Hidden>
					</Grid>
					<Hidden mdDown>
						<Grid item xs={12} lg={props.libraryView ? 5 : 4} xl={4} className={clsx(classes.playerImgBox, "display-flex align-items-end")}>
							<div className={classes.halfCircle}>
								<Atropos highlight={false} shadow={false} rotate={false}>
									<img src={props.card?.playerImage?.url} className={classes.marketplacePlayerImg} alt="FIGHTER" data-atropos-offset="0" />
								</Atropos>
							</div>
						</Grid>
						<Grid item container xs={12}>
							<Grid item xs={12} className={classes.bottomInfoBox}>
								<span className={classes.titleDescription}>COLLECTION: </span>
								<span className={clsx(classes.title, classes.cardCollection)}>&nbsp;{props.card?.cardCollection?.name}</span>
								<Divider orientation="vertical" className={classes.divider} />
								{!props.sellOnMarket && (
									<>
										<span className={classes.titleDescription} style={{ marginRight: '10px' }}>STATUS COLLECTION:&nbsp;</span>
										<BadgeRed>NOT OWNED</BadgeRed>
									</>
								)}
								{props.sellOnMarket && (
									<>
										<div className={classes.marketplaceIcon}><MarketplaceIcon /></div>
										<span className={classes.sellCard}>Sell this item on the marketplace</span>
										<div className="flex-grow-full" />
										<ButtonPrimary 
											className={classes.sellCardButton}
											onClick={() => setOpenSellDialog(true)}
										>SELL CARD</ButtonPrimary>
									</>
								)}
							</Grid>
						</Grid>
					</Hidden>
				</Grid>
			</Grid>
			{props.sellOnMarket && (
				<>
					<CustomDialog
						title="Put an item up for sale" 
						maxWidth="md"
						open={openSellDialog}
						handleClose={() => setOpenSellDialog(false)}>
						<SellCardDialogContent 
							handleSell={handleSell}
							card={props.card}
							name={props.card?.name}
							stack={3} /> 
							{/* stack={props.card.quantity} />  */}
					</CustomDialog>
					<CustomDialog
						title="Confirmation" 
						maxWidth="md"
						open={openConfirmationDialog}
						handleClose={() => setOpenConfirmationDialog(false)}>
						<ConfirmSellCardDialogContent  handleClose={() => setOpenConfirmationDialog(false)} />
					</CustomDialog>
				</>
			)}
		</div>
    );
}

export default CardBasicInfo;