import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
	buyProductDialog: {
		textAlign: 'center',
		padding: theme.spacing(2),
		fontFamily: 'Klavika',
		color: theme.palette.text.lightOn,
		fontSize: '15px'
	},
	creditCardIcon: {
		fill: theme.palette.primary.main,
		width: '80px',
		height: '80px',
	},
	question: {
		fontSize: '24px',
		lineHeight: '29px',
		color: theme.palette.text.trueWhite,
		marginBottom: 0
	},
	priceBox: {
		width: '250px',
		margin: 'auto',

		[theme.breakpoints.down("xs")]: {
			width: '100%'
		}
	},
	price: {
		color: theme.palette.text.trueWhite,
	},
	cancelButton: {
		marginRight: theme.spacing(2),

		[theme.breakpoints.down("xs")]: {
			marginBottom: theme.spacing(2)
		}
	}
}));

export { useStyles };