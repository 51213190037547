import { FormControl, MenuItem, Select } from '@material-ui/core';
import clsx from 'clsx';
import { forwardRef } from 'react';
import { useSelector } from 'react-redux';
import { underscoreToSpace, upperCase } from '../../../../utils/text-format';
import { useStyles } from './AllYourCardsFiltersStyle';

const AllYourCardsFilters = forwardRef((props, ref) => {
	const classes = useStyles();
	const cardTypes = useSelector((state) => state.cardTypes.data);
	const organizations = useSelector((state) => state.organizations.data);

	return (<>
		<p className={classes.label}>PRICE:</p>
		<FormControl variant="outlined" className={classes.formControl}>
			<Select
				defaultValue=""
				className={clsx(classes.select, classes.font)}
				value={props.priceSort}
				onChange={event => props.setPriceSort(event.target.value)}
			>
				<MenuItem value="">&nbsp;</MenuItem>
				<MenuItem value="asc">MIN-MAX</MenuItem>
				<MenuItem value="desc">MAX-MIN</MenuItem>
			</Select>
		</FormControl>
		<p className={classes.label}>POPULARITY:</p>
		<FormControl variant="outlined" className={classes.formControl}>
			<Select
				defaultValue=""
				className={clsx(classes.select, classes.font)}
				value={props.popularitySort}
				onChange={event => props.setPopularitySort(event.target.value)}
			>
				<MenuItem value="">&nbsp;</MenuItem>
				<MenuItem value="asc">MIN-MAX</MenuItem>
				<MenuItem value="desc">MAX-MIN</MenuItem>
			</Select>
		</FormControl>
		<p className={classes.label}>ORGANIZATION:</p>
		<FormControl variant="outlined" className={classes.formControl}>
			<Select
				defaultValue=""
				className={clsx(classes.select, classes.font)}
				value={props.organization}
				onChange={event => props.setOrganization(event.target.value)}
			>
				<MenuItem value="">&nbsp;</MenuItem>
				{organizations?.map(organization => (
					<MenuItem key={organization.id} value={organization.name}>
						{upperCase(underscoreToSpace(organization.name))}
					</MenuItem>
				))}
			</Select>
		</FormControl>
		<p className={classes.label}>RARITY:</p>
		<FormControl variant="outlined" className={classes.formControl}>
			<Select
				defaultValue=""
				className={clsx(classes.select, classes.font)}
				value={props.rarity}
				onChange={event => props.setRarity(event.target.value)}
			>
				<MenuItem value="">&nbsp;</MenuItem>
				{cardTypes?.map(cardType => (
					<MenuItem key={cardType.id} value={cardType.name}>
						{upperCase(underscoreToSpace(cardType.name))}
					</MenuItem>
				))}
			</Select>
		</FormControl>
	</>);
});

export default AllYourCardsFilters;