import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
	fontDescription: {
		fontSize: '10px'
	},
	fontCount: {
		fontSize: '20px'
	},
	divider: {
		height: '50%',
		margin: '10px !important',
		border: `0.5px solid ${theme.palette.text.trueWhite} !important`,
		opacity: 0.3
	}
}));

export { useStyles };