import { LinearProgress, withStyles } from "@material-ui/core";

const ProgressBar = withStyles((theme) => ({
	root: {
		height: 4,
		borderRadius: 5,
	},
	colorPrimary: {
		backgroundColor: theme.palette.auxiliary.grey,
	},
	bar: {
		borderRadius: 5,
		backgroundColor: theme.palette.auxiliary.success,
	},
}))(LinearProgress);

export default ProgressBar;