import { yupResolver } from '@hookform/resolvers/yup';
import { Avatar, CircularProgress, Grid, TextField } from '@material-ui/core';
import clsx from 'clsx';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as EditIcon } from '../../../assets/img/edit-button.svg';
import { setMessage, setOpen } from '../../../store/slices/alert/AlertSlice';
import { editUser } from '../../../store/slices/user/UserSlice';
import { editUserValidationSchema } from '../../../utils/validations/editUserValidation';
import ButtonPrimary from '../../UI/Buttons/ButtonPrimary/ButtonPrimary';
import LimitedBackdrop from './../../UI/LimitedBackdrop/LimitedBackdrop';
import { useStyles } from './UserProfileBasicInfoStyle';

const UserProfileBasicInfo = props => {
    const classes = useStyles();
	const dispatch = useDispatch();
	const user = useSelector((state) => state.user.data);
	const userStatus = useSelector((state) => state.user.status);
    const { control, setValue, handleSubmit, formState: { errors } } = useForm({
		resolver: yupResolver(editUserValidationSchema),
		defaultValues: {
			nickname: "",
			email: "",
			phone: "",
			avatar: ""
		}
	});
	const [avatar, setAvatar] = useState();
	const [openSpinner, setOpenSpinner] = useState(false);
    
	useEffect(() => {
		if (user?.id) {
			setValue('nickname', user.nickname);
			setValue('email', user.email);
			setValue('phone', user.phone || '');
			setValue('avatar', user.avatar?.webp?.url);
			setAvatar(user?.avatar?.webp?.url);
		}
	}, [user, setValue]);

	useEffect(() => {
		setOpenSpinner(userStatus === 'init' || userStatus === 'pending');
	}, [userStatus]);

    const editUserProfile = data => {
		try {
			setOpenSpinner(true);
			dispatch(editUser(data));
		} catch {
			setOpenSpinner(false);
			dispatch(setOpen(true));
			dispatch(setMessage('Something went wrong'));
		} finally {
			setOpenSpinner(false);
			dispatch(setOpen(true));
			dispatch(setMessage('Data updated'));
		}
	};

	const avatarFileUpload = event => {
		const file = event.target.files[0];
		const reader = new FileReader();
		if (file && file.type.match('image.*')) {
			reader.readAsDataURL(file);
		}
		reader.onloadend = e => {
			setValue('avatar', reader.result);
			setAvatar(reader.result);
		};
	};

    return (
        <form onSubmit={handleSubmit(editUserProfile)}>
			<Grid item container spacing={2}>
				<Grid container item xs={12} md={4} lg={3}>
					<Grid item xs={12} className="panel-fill position-relative">
						<LimitedBackdrop open={openSpinner}><CircularProgress /></LimitedBackdrop>
						<label htmlFor="icon-button-file" className="cursor-pointer">
							<input accept="image/*" id="icon-button-file" type="file" multiple={false} onChange={avatarFileUpload} className="display-none" />
							<Avatar src={avatar} className={classes.avatar} alt="Avatar" />
							<div className={clsx("display-flex justify-content-center align-items-center", classes.editProfileTextBox)}>
								<EditIcon />
								<p>&nbsp;&nbsp;Edit profile</p>
							</div>
						</label>
					</Grid>
				</Grid>
				<Grid container item xs={12} md={8} lg={9}>
					<Grid container item xs={12} className={clsx("panel-fill position-relative", classes.formBox)}>
						<LimitedBackdrop open={openSpinner}><CircularProgress /></LimitedBackdrop>
						<Grid container item xs={12} spacing={2}>
							<Grid item xs={12} lg={6}>
								<p className="input-label">Nickname</p>
								<Controller
									name="nickname"
									control={control}
									render={({ field }) => (
										<TextField
											{ ...field }
											className={errors?.nickname ? "error-border-input" : ""}
											variant="outlined" 
											placeholder="Nickname"
											fullWidth
										/>
									)}
								/>
							</Grid>
							<Grid item xs={12} lg={6}>
								<p className="input-label">E-mail</p>
								<Controller
									name="email"
									control={control}
									render={({ field }) => (
										<TextField
											{ ...field }
											className={errors?.email ? "error-border-input" : ""}
											variant="outlined" 
											placeholder="E-mail"
											fullWidth
										/>
									)}
								/>
							</Grid>
							<Grid item xs={12} lg={6}>
								<p className="input-label">Phone number</p>
								<Controller
									name="phone"
									control={control}
									render={({ field }) => (
										<TextField
											{ ...field }
											className={errors?.phone ? "error-border-input" : ""}
											variant="outlined" 
											placeholder="Phone number"
											fullWidth
										/>
									)}
								/>
							</Grid>
							<Grid item xs={12} className={classes.saveButtonBox}>
								<ButtonPrimary type="submit">SAVE</ButtonPrimary>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</form>
    );
};

export default UserProfileBasicInfo;