import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
	animatedCard: {
		height: props => props.size === "lg" ? 470 : props.size === "sm" ? 330 : 250,
		width: props => props.size === "lg" ? 300 : props.size === "sm" ? 220 : 150,
		display: 'flex',
	    justifyContent: 'center',

		'& .atropos-inner': {
			height: 462,
			width: 300,
		},
		'& .atropos': {
			height: 462,
			width: 300,
			margin: 'auto'
		}
	},
	cardImg: {
		borderRadius: '10px',
		height: 440,
		width: 300,
		marginTop: theme.spacing(2),
	},
	fighterImg: {
		height: 430,
	},
	logoBox: {
		top: '6%',
	},
	mdLogo: {
		width: '30px !important',
		height: 30,
		marginBottom: 10
	},
	pd4Logo: {
		width: '20px !important',
		height: 70
	},
	numberBox: {
		top: '4%',
		right: '5%',
	},
	number: {
		fontSize: 40,
		lineHeight: props => String(props.card?.cardType?.name).toUpperCase() === 'EXCLUSIVE' ? '38px' : '',
	},
	numberCategory: {
		fontSize: 12,
	},
	nameBox: {
		top: '50%',
	},
	name: {
		marginTop: '27px !important'
	},
	nameHolo: {
		marginTop: '27px !important'
	},
	infoBox: {
		bottom: '5%',
		width: '90%'
	},
	bioBox: {
		height: 10,

		'& svg': {
			height: 10,
			width: 50
		}
	},
	starImg: {
		height: '8px !important',
		width: '8px !important'
	},
	ability: {
		fontSize: 9
	},
	bio: {
		fontSize: 12,
	},
	description: {
		fontSize: 9,
		maxHeight: 26
	},
	bottomNumbers: {
		marginTop: 15
	},
	rareImg: {
		height: 40,
		width: 80,
		marginTop: '-6px'
	},
	smallTitle: {
		fontSize: 6,
	},
	value: {
		fontSize: 28,
		marginTop: '-5px !important',
		width: 37
	},
	divider: {
		margin: '10px !important',
		marginTop: '13px !important',
		height: 20,
	},
	footer: {
		fontSize: 6,
		bottom: 8,
	},
	holoNumberBox: {
		height: 40,
		marginTop: 10,
		padding: '0 10px',
	}
}));

export { useStyles };