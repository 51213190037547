import { Route, Switch } from 'react-router-dom';
import Crafting from '../components/Crafting/Crafting';
import Dashboard from '../components/Dashboard/Dashboard';
import AllYourCards from '../components/Library/AllYourCards/AllYourCards';
import Library from '../components/Library/Library';
import Marketplace from '../components/Marketplace/Marketplace';
import MarketplaceItem from '../components/MarketplaceItem/MarketplaceItem';
import Notifications from '../components/Notifications/Notifications';
import Quest from '../components/Quest/Quest';
import Store from '../components/Store/Store';
import UserProfile from '../components/UserProfile/UserProfile';
import BuyCredit from './../components/BuyCredit/BuyCredit';
import OpenPack from './../components/Store/OpenPack/OpenPack';
import YourPacks from './../components/YourPacks/YourPacks';
import TransactionHistory from './../components/TransactionHistory/TransactionHistory';

const routes = (
    <Switch>
        <Route path="/library" exact component={Library} />
        <Route path="/library/your-items" exact component={AllYourCards} />
        <Route path="/store" component={Store} />
        <Route path="/open-pack/:packId" component={OpenPack} />
        <Route path="/marketplace" exact component={Marketplace} />
        <Route path="/marketplace/:cardId" exact component={MarketplaceItem} />
        <Route path="/crafting" component={Crafting} />
        <Route path="/quest" component={Quest} />
        <Route path="/user-profile" component={UserProfile} />
        <Route path="/notifications" component={Notifications} />
        <Route path="/buy-credit" component={BuyCredit} />
        <Route path="/your-packs" component={YourPacks} />
        <Route path="/transaction-history" component={TransactionHistory} />
        <Route path="/" exact component={Dashboard} />
    </Switch>
);

export default routes;