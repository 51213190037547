import { Grid } from "@material-ui/core";
import { ReactComponent as CardsImg } from '../../../assets/img/card.svg';
import CircleProgressBar from "../../UI/ProgressBars/CircleProgressBar/CircleProgressBar";
import { useStyles } from "./CollectionBoxStyle";
import PunchdownCollectionImg from '../../../assets/img/punchdown-collection.jpg';
import clsx from 'clsx';
import { useSelector } from 'react-redux';

const CollectionBox = props => {
	const classes = useStyles();
	const selectedCollection = useSelector(state => state.libraryCollections.selectedCollection);

	return (
		<Grid 
			container item xs={12} 
			className={clsx(
				classes.collectionBox, 
				props.collection?.id === selectedCollection?.id ? classes.selectedCollection : '')} 
			alignItems="center" 
			onClick={props.handleClick}
		>
			<img src={PunchdownCollectionImg} alt="Background img" className={classes.backgroundImg} />
			<div className={classes.imgGradient}></div>
			<div style={{ position: 'relative' }}>
				<p className={classes.name}>{props.organizationName}: {props.collection?.name}</p>
				<div className={classes.cardAmount}>
					<CardsImg />
					<span className={classes.myCardsAmount}>3</span>
					/
					<span>100</span>
				</div>
			</div>
			<div className="flex-grow-full" />
			<CircleProgressBar value={40} size={50} thickness={2} color="success" />
		</Grid>
	);
}

export default CollectionBox;