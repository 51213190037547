import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../../../shared/axios-client';

export const fetchCollections = createAsyncThunk(
	'collections/fetch',
	async () => {
		const response = await axios.get('card_collections');
		return response.data.data;
	}
);

export const CollectionsSlice = createSlice({
	name: 'collections',
	initialState: {
		status: "init",
		data: [],
		error: null,
	},
	reducers: {},
	extraReducers: {
		[fetchCollections.pending.type]: (state, action) => {
			state.status = "pending";
			state.data = [];
			state.error = null;
		},
		[fetchCollections.fulfilled.type]: (state, action) => {
			state.status = "fulfilled";
			state.data = action.payload;
			state.error = null;
		},
		[fetchCollections.rejected.type]: (state, action) => {
			state.status = "rejected";
			state.data = [];
			state.error = action.error.message;
		},
	}
});

export default CollectionsSlice.reducer;