import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
	quantityButtons: {
		border: `1px solid ${theme.palette.border.main}`,

		[theme.breakpoints.only("xs")]: {
			width: '100%'
		},
		'& button, button:disabled': {
			color: theme.palette.text.lightOn
		},
		'& .plus-button': {
			color: theme.palette.primary.main
		}
	}
}));

export { useStyles };