import { Button } from "@material-ui/core";
import clsx from 'clsx';
import { useStyles } from "./ButtonPrimaryOutlinedStyle";

const ButtonPrimaryOutlined = (props) => {
	const classes = useStyles();

	return (
		<Button
			{...props}
			color="primary"
			variant="outlined"
			className={clsx(props.className, classes.primaryOutlined)}
		></Button>
	);
}

export default ButtonPrimaryOutlined;