import { Divider, Grid } from '@material-ui/core';
import { useState } from 'react';
import { useStyles } from './PaymentMethodStyle';
import clsx from 'clsx';
import AmazonPayImg from '../../../assets/img/AmazonPay.svg';
import PaypalImg from '../../../assets/img/Paypal.svg';
import PaySafeCardImg from '../../../assets/img/paysafecard.svg';
import PayUImg from '../../../assets/img/payU.svg';
import Przelewy24Img from '../../../assets/img/Przelewy24.svg';

const PaymentMethod = (props) => {
	const classes = useStyles();

	const [paymentMethod, setPaymentMethod] = useState('');

	return (
		<Grid container item xs={12} className="panel-fill text-align-center" alignItems="center" justifyContent="center">
			<Grid 
				item xs={12} sm 
				className={clsx(classes.paymentMethodItem, paymentMethod === "pay-safe-card" ? classes.paymentMethodItemActive : null)}
				onClick={() => setPaymentMethod("pay-safe-card")}>
				<img src={PaySafeCardImg} alt="pay-safe-card" />
				<hr className={classes.paymentMethodActiveLine} hidden={paymentMethod !== "pay-safe-card"} />
			</Grid>
			<Divider orientation="vertical" flexItem className={classes.divider} />
			<Grid 
				item xs={12} sm 
				className={clsx(classes.paymentMethodItem, paymentMethod === "payU" ? classes.paymentMethodItemActive : null)}
				onClick={() => setPaymentMethod("payU")}>
				<img src={PayUImg} alt="payU" />
				<hr className={classes.paymentMethodActiveLine} hidden={paymentMethod !== "payU"} />
			</Grid>
			<Divider orientation="vertical" flexItem className={classes.divider} />
			<Grid item xs={12} sm 
				className={clsx(classes.paymentMethodItem, paymentMethod === "przelewy-24" ? classes.paymentMethodItemActive : null)}
				onClick={() => setPaymentMethod("przelewy-24")}>
				<img src={Przelewy24Img} alt="przelewy-24" />
				<hr className={classes.paymentMethodActiveLine} hidden={paymentMethod !== "przelewy-24"} />
			</Grid>
			<Divider orientation="vertical" flexItem className={classes.divider} />
			<Grid item xs={12} sm 
				className={clsx(classes.paymentMethodItem, paymentMethod === "paypal" ? classes.paymentMethodItemActive : null)}
				onClick={() => setPaymentMethod("paypal")}>
				<img src={PaypalImg} alt="paypal" />
				<hr className={classes.paymentMethodActiveLine} hidden={paymentMethod !== "paypal"} />
			</Grid>
			<Divider orientation="vertical" flexItem className={classes.divider} />
			<Grid item xs={12} sm 
				className={clsx(classes.paymentMethodItem, paymentMethod === "amazon-pay" ? classes.paymentMethodItemActive : null)}
				onClick={() => setPaymentMethod("amazon-pay")}>
				<img src={AmazonPayImg} alt="amazon-pay" />
				<hr className={classes.paymentMethodActiveLine} hidden={paymentMethod !== "amazon-pay"} />
			</Grid>
		</Grid>
	);
}

export default PaymentMethod;