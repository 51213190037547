import { Breadcrumbs, Typography } from '@material-ui/core';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import clsx from 'clsx';
import { Link, Route } from 'react-router-dom';
import { useStyles } from './HeaderBreadcrumbsStyle';
import { dashToSpace } from './../../../../utils/text-format';

const HeaderBreadcrumbs = () => {
	const classes = useStyles();

	return (
		<Route>
			{({ location }) => {
				const pathnames = location.pathname.split('/').filter(x => x);
				return (
					<Breadcrumbs separator={<NavigateNextIcon className={classes.nextIcon} />} aria-label="Breadcrumb">
						<Link className={clsx(classes.link, classes.breadcrumbsText)} to="/">Home</Link>
						{pathnames.map((value, index) => {
							const last = index === pathnames.length - 1;
							const to = `/${pathnames.slice(0, index + 1).join('/')}`;

							return last ? (
								<Typography color="primary" className={classes.breadcrumbsText} key={to}>
									{dashToSpace(value)}
								</Typography>
							) : (
								<Link className={clsx(classes.link, classes.breadcrumbsText)} to={to} key={to}>
									{dashToSpace(value)}
								</Link>
							);
						})}
					</Breadcrumbs>
				);
			}}
		</Route>
	);
}

export default HeaderBreadcrumbs;