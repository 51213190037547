import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
	confirmSellCardDialogContent: {
		padding: theme.spacing(3),
		textAlign: 'center',
		fontSize: '15px',
		
		[theme.breakpoints.up("sm")]: {
			width: '70%',
			margin: 'auto'
		}
	},
	description: {
		fontSize: '24px',
		lineHeight: '29px',
		color: theme.palette.text.trueWhite,
		marginBottom: 0
	},
	cool: {
		color: theme.palette.primary.main
	}
}));

export { useStyles };