import { Grid } from '@material-ui/core';
import ButtonPrimary from '../../UI/Buttons/ButtonPrimary/ButtonPrimary';
import { useStyles } from './UserProfileConnectAccountStyle';
import clsx from 'clsx';
import { ReactComponent as TwitchIcon } from '../../../assets/img/twitch.svg';
import { ReactComponent as GoogleIcon } from '../../../assets/img/google.svg';
import { ReactComponent as FacebookIcon } from '../../../assets/img/facebook.svg';
import { ReactComponent as TwitterIcon } from '../../../assets/img/twitter.svg';

const UserProfileConnectAccount = props => {
	const classes = useStyles();

	return (
		<Grid container spacing={2}>
			<Grid container item xs={12} md={6}>
				<Grid item xs={12} className={clsx("panel-fill", classes.connectBox)}>
					<div className="display-flex align-items-center">
						<div className={classes.icon}><TwitchIcon /></div>
						<div className={classes.textBox}>
							<p className={classes.title}>Link to your Twitch account</p>
							<p className={classes.description}>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Enim, massa donec et sit massa fringilla.</p>
						</div>
					</div>
					<div className="flex-grow-full" />
					<ButtonPrimary>CONNECT</ButtonPrimary>
				</Grid>
			</Grid>
			<Grid container item xs={12} md={6}>
				<Grid item xs={12} className={clsx("panel-fill", classes.connectBox)}>
					<div className="display-flex align-items-center">
						<div className={classes.icon}><GoogleIcon /></div>
						<div className={classes.textBox}>
							<p className={classes.title}>Link to your Google account</p>
							<p className={classes.description}>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Enim, massa donec et sit massa fringilla.</p>
						</div>
					</div>
					<div className="flex-grow-full" />
					<ButtonPrimary>CONNECT</ButtonPrimary>
				</Grid>
			</Grid>
			<Grid container item xs={12} md={6}>
				<Grid item xs={12} className={clsx("panel-fill", classes.connectBox)}>
					<div className="display-flex align-items-center">
						<div className={classes.icon}><FacebookIcon /></div>
						<div className={classes.textBox}>
							<p className={classes.title}>Link to your Facebook account</p>
							<p className={classes.description}>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Enim, massa donec et sit massa fringilla.</p>
						</div>
					</div>
					<div className="flex-grow-full" />
					<ButtonPrimary>CONNECT</ButtonPrimary>
				</Grid>
			</Grid>
			<Grid container item xs={12} md={6}>
				<Grid item xs={12} className={clsx("panel-fill", classes.connectBox)}>
					<div className="display-flex align-items-center">
						<div className={classes.icon}><TwitterIcon /></div>
						<div className={classes.textBox}>
							<p className={classes.title}>Link to your Twitter account</p>
							<p className={classes.description}>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Enim, massa donec et sit massa fringilla.</p>
						</div>
					</div>
					<div className="flex-grow-full" />
					<ButtonPrimary>CONNECT</ButtonPrimary>
				</Grid>
			</Grid>
		</Grid>
	);
};

export default UserProfileConnectAccount;