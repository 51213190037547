import { Grid } from "@material-ui/core";
import { useSelector } from 'react-redux';
import CardBox from "../../CardBox/CardBox";
import SectionHeader from "../../UI/SectionHeader/SectionHeader";
import { useStyles } from "./MarketplaceBoxStyle";

const MarketplaceBox = () => {
	const classes = useStyles();

	const dashboardMarketplaceCards = useSelector((state) => state.dashboardCards.data?.dashboardMarketplace);

	return (
		<>
			<Grid item xs={12}>
				<SectionHeader title="ON THE MARKETPLACE" subtitle="Watch the market, grab the cards you need" />
			</Grid>
			{dashboardMarketplaceCards?.length && (
				<div className={classes.cardsBox}>
					{dashboardMarketplaceCards.map(card => (
						<CardBox 
							key={card.id}
							card={card}
							tag={card.tag} />
					))}
				</div>)
			}
		</>
	);
}
  
export default MarketplaceBox;