import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
	filters: {
		'& .MuiPaper-root': {
			padding: theme.spacing(2),
			paddingTop: 0
		}
	},
	filtersAccordion: {
		width: '100%',

		'& .MuiAccordionDetails-root': {
			display: 'block'
		}
	}
}));

export { useStyles };