import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
	filterBox: {
		marginLeft: `-${theme.spacing(3)}px`,
		marginRight: `-${theme.spacing(3)}px`,
		background: theme.palette.background.main,
		borderBottom: `1px solid ${theme.palette.border.main}`,
		padding: `${theme.spacing(2)}px ${theme.spacing(3)}px`,
		width: 'auto',
		alignItems: 'center'
	},
	searchInput: {
		width: '100%',
	},
	searchButton: {
		margin: `0 ${theme.spacing(1)}px`,
		[theme.breakpoints.down("md")]: {
			marginRight: 0
		}
	},
	transactionBox: {
		padding: theme.spacing(2),
		marginBottom: theme.spacing(2),
		fontSize: 15,
		textAlign: 'center',

		[theme.breakpoints.down("md")]: {
			paddingTop: theme.spacing(3),
			paddingBottom: theme.spacing(3),
			textAlign: 'left'
		}
	},
	transactionTitle: {
		[theme.breakpoints.down("md")]: {
			marginBottom: 10
		}
	},
	transactionId: {
		fontSize: 13
	},
	title: {
		color: theme.palette.text.lightOn
	},
	icon: {
		borderRadius: '50%',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		height: '48px',
		minWidth: '48px',
		marginRight: '10px',
		border: `1px solid ${theme.palette.primary.main}`,
	},
	divider: {
		width: '100%',
		margin: '10px 0 !important',
	},
	statusInfo: {
		display: 'flex',
		alignItems: 'center',

		'& svg': {
			marginRight: 5
		},
		[theme.breakpoints.up("lg")]: {
			justifyContent: 'center'
		}
	}
}));

export { useStyles };