import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
	menuList: {
		flexGrow: 1,
		paddingTop: theme.spacing(2)
	},
	listItem: {
		textAlign: props => props.isMobile ? 'left' : 'center',
		justifyContent: props => props.isMobile ? 'normal' : 'center',
		
		'& svg': {
			fill: theme.palette.icons.main,
		},
		'&:hover': {
			borderLeft: `3px solid ${theme.palette.primary.main}`,
			background: 'linear-gradient(90.36deg, rgba(0, 231, 174, 0.1) 0.29%, rgba(196, 196, 196, 0) 98.86%)',
			color: theme.palette.primary.main
		},
		'&:hover svg': {
			fill: theme.palette.primary.main
		}
	},
	navLink: {
		padding: props => theme.spacing(props.isMobile ? 1 : 2),
		display: 'flex',
		color: 'inherit',
		textDecoration: 'none',
		flexGrow: 1
	},
	listItemIcon: {
		justifyContent: 'center'
	}
}));

export { useStyles };