import { configureStore } from '@reduxjs/toolkit';
import cardOffersReducer from './slices/card-details/CardOffersSlice';
import cardReducer from './slices/card-details/CardSlice';
import cardsOtherPopularReducer from './slices/card-details/CardsOtherPopularSlice';
import cardStatisticsReducer from './slices/card-details/CardStatisticsSlice';
import cardsReducer from './slices/cards/CardsSlice';
import dashboardCardsReducer from './slices/dashboard/DashboardCardsSlice';
import cardTypesReducer from './slices/dicts/CardTypesSlice';
import userReducer from './slices/user/UserSlice';
import packsReducer from './slices/packs/PacksSlice';
import yourPacksReducer from './slices/packs/YourPacksSlice';
import packReducer from './slices/packs/PackSlice';
import collectionsReducer from './slices/dicts/CollectionsSlice';
import organizationsReducer from './slices/dicts/OrganizationsSlice';
import libraryCollectionsReducer from './slices/library/LibraryCollectionsSlice';
import libraryOrganizationsReducer from './slices/library/LibraryOrganizationsSlice';
import libraryCardsReducer from './slices/library/LibraryCardsSlice';
import libraryYourCardsReducer from './slices/library/LibraryYourCardsSlice';
import allYourCardsReducer from './slices/library/AllYourCardsSlice';
import alertReducer from './slices/alert/AlertSlice';
import transactionHistoryReducer from './slices/user/TransactionHistorySlice';
import dialogsReducer from './slices/dialogs/DialogsSlice';
import packCardsReducer from './slices/packs/PackCardsSlice';
import landingPageCardsReducer from './slices/landing-page/LandingPageSlice';

export default configureStore({
	reducer: {
		cardTypes: cardTypesReducer,
		cards: cardsReducer,
		card: cardReducer,
		cardStatistics: cardStatisticsReducer,
		cardsOtherPopular: cardsOtherPopularReducer,
		cardOffers: cardOffersReducer,
		user: userReducer,
		dashboardCards: dashboardCardsReducer,
		packs: packsReducer,
		pack: packReducer,
		packCards: packCardsReducer,
		collections: collectionsReducer,
		organizations: organizationsReducer,
		yourPacks: yourPacksReducer,
		libraryCollections: libraryCollectionsReducer,
		libraryOrganizations: libraryOrganizationsReducer,
		libraryCards: libraryCardsReducer,
		libraryYourCards: libraryYourCardsReducer,
		allYourCards: allYourCardsReducer,
		alert: alertReducer,
		transactionHistory: transactionHistoryReducer,
		dialogs: dialogsReducer,
		landingPageCards: landingPageCardsReducer
	},
});