import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
	popularCardBox: {
		background: theme.palette.background.panel,
		border    : `1px solid ${theme.palette.border.main}`,
		borderRadius: '3px',
		margin: '10px 0',
		display: 'flex',
		alignItems: 'center',
		padding: '10px',
		height: 90,
		textTransform: 'uppercase'
	},
	cardBox: {
		transform: 'scale(0.25)',
		width: 100,
		marginLeft: '-30px'
	},
	name: {
		fontSize: '18px',

		[theme.breakpoints.only("lg")]: {
			fontSize: '15px',
		}
	},
	rarity: {
		fontSize: '12px',
	},
	organization: {
		fontSize: '12px',
		color: theme.palette.text.lightOn,
	},
	price: {
		fontSize: '16px',
		
		[theme.breakpoints.only("lg")]: {
			fontSize: '14px',
		}
	}
}));

export { useStyles };