import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../../shared/axios-client';

export const fetchPackCards = createAsyncThunk(
	'packCards/fetch',
	async ({packId}) => {
        // TODO get generated_cards by boosterPackId
		// const response = await axios.get('generated_cards', { params: { boosterPackId: packId }});
		// return response.data.data;

		const response1 = await axios.get(`generated_cards/23`);
		const response2 = await axios.get(`generated_cards/33`);
		const response3 = await axios.get(`generated_cards/40`);
		const response4 = await axios.get(`generated_cards/43`);
		const response5 = await axios.get(`generated_cards/44`);

		return [response1.data.data, response2.data.data, response3.data.data, response4.data.data, response5.data.data];
	}
);

export const PackCardsSlice = createSlice({
	name: 'packCards',
	initialState: {
		status: "init",
		error: null,
		data: {},
	},
	reducers: {},
	extraReducers: {
		[fetchPackCards.pending.type]: (state, action) => {
			state.status = "pending";
			state.error = null;
			state.data = {};
		},
		[fetchPackCards.fulfilled.type]: (state, action) => {
			state.status = "fulfilled";
			state.error = null;
			state.data = action.payload;
		},
		[fetchPackCards.rejected.type]: (state, action) => {
			state.status = "rejected";
			state.error = action.error.message;
			state.data = {};
		},
	}
});

export default PackCardsSlice.reducer;