import { Button } from "@material-ui/core";
import clsx from 'clsx';
import { useStyles } from "./ButtonGreyStyle";

const ButtonGrey = (props) => {
	const classes = useStyles();

	return (
		<Button
			{...props}
			color="secondary"
			variant="contained"
			className={clsx(classes.grey, props.className)}
		></Button>
	);
}

export default ButtonGrey;