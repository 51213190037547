import { Button } from "@material-ui/core";
import clsx from 'clsx';
import { useStyles } from "./ButtonPrimaryStyle";

const ButtonPrimary = (props) => {
	const classes = useStyles();

	return (
		<Button
			{...props}
			color="primary"
			variant="contained"
			className={clsx(classes.primary, props.className)}
		></Button>
	);
}

export default ButtonPrimary;