import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Checkbox, FormControlLabel, FormGroup, Grid, makeStyles, TextField } from "@material-ui/core";
import clsx from 'clsx';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import HomeBg from '../../assets/img/home_bg.webp';
import { ReactComponent as LogoIcon } from '../../assets/img/logo.svg';
import { setSignInDialogOpen } from "../../store/slices/dialogs/DialogsSlice";
import { registerValidationSchema } from './../../utils/validations/registerValidation';

const useStyles = makeStyles((theme) => ({
	register: {
		backgroundImage: `url(${HomeBg})`,
		backgroundSize: '100% 100%',
		textAlign: 'center',
		padding: '50px 10px',
		fontFamily: 'Montserrat',
		color: theme.palette.text.trueWhite,

		'& .MuiButton-root': {
		    fontFamily: 'Montserrat !important',
			fontWeight: 600,
		}
	},
	logo: {
		width: 80,
		height: 85
	},
	title: {
		fontSize: 24,
		lineHeight: '29px'
	},
	inputLabel: {
		fontWeight: 600,
		letterSpacing: '1.25px',
		textAlign: 'left',
		marginBottom: 10
	},
	formBox: {
		maxWidth: 480,
		padding: 25,
		margin: 'auto'
	},
	haveAccount: {
		fontSize: 14,
		color: '#DCDDDD'
	},
	login: {
		color: theme.palette.primary.main,
		textDecoration: 'underline',
		cursor: 'pointer'
	},
	goBack: {
		fontSize: 14,
		letterSpacing: '0.25px',
		color: theme.palette.text.lightOn,
		fontWeight: 500,
		marginTop: 24,
	},
	checkboxFormControl: {
		marginBottom: 15,

		'& .MuiTypography-body1': {
			color: theme.palette.text.trueWhite,
			fontSize: 13,
			lineHeight: '18px',
			textAlign: 'left',
		},

		'& .MuiCheckbox-root': {
			color: '#39C700',
			marginRight: 10
		}
	}
}));

const Register = () => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const { control, handleSubmit, formState: { errors } } = useForm({
		resolver: yupResolver(registerValidationSchema),
		defaultValues: {
			email: "",
			userName: "",
			password: "",
			confirmPassword: ""
		}
	});
	const history = useHistory();

	const register = data => {
		// TODO register
		console.log(data);
		localStorage.setItem('token', 'token-testowy');
		history.push('/');
	};

	return (
		<Grid container item className={classes.register}>
			<Grid item xs={12}>
				<LogoIcon className={classes.logo} />
				<p className={classes.title}>REGISTER YOUR <b>ACCOUNT</b></p>
				<div className={clsx(classes.formBox, "panel-fill")}>
					<form onSubmit={handleSubmit(register)}>
						<Grid container item xs={12}>
							<Grid item xs={12}>
								<p className={clsx(classes.inputLabel, "input-label")}>E-MAIL</p>
								<Controller
									name="email"
									control={control}
									render={({ field }) => (
										<TextField
											{ ...field }
											className={errors?.email ? "error-border-input" : ""}
											variant="outlined" 
											placeholder="E-mail"
											type="email"
											fullWidth
										/>
									)}
								/>
							</Grid>
							<Grid item xs={12}>
								<p className={clsx(classes.inputLabel, "input-label")}>USERNAME</p>
								<Controller
									name="userName"
									control={control}
									render={({ field }) => (
										<TextField
											{ ...field }
											className={errors?.userName ? "error-border-input" : ""}
											variant="outlined" 
											placeholder="User name"
											fullWidth
										/>
									)}
								/>
							</Grid>
							<Grid item xs={12}>
								<p className={clsx(classes.inputLabel, "input-label")}>PASSWORD</p>
								<Controller
									name="password"
									control={control}
									render={({ field }) => (
										<TextField
											{ ...field }
											className={errors?.password ? "error-border-input" : ""}
											variant="outlined" 
											placeholder="Password"
											type="password"
											fullWidth
										/>
									)}
								/>
							</Grid>
							<Grid item xs={12}>
								<p className={clsx(classes.inputLabel, "input-label")}>CONFIRM PASSWORD</p>
								<Controller
									name="confirmPassword"
									control={control}
									render={({ field }) => (
										<TextField
											{ ...field }
											className={errors?.confirmPassword ? "error-border-input" : ""}
											variant="outlined" 
											placeholder="Confirm password"
											type="password"
											fullWidth
										/>
									)}
								/>
							</Grid>
							<Grid item xs={12}>
								<FormGroup>
									<FormControlLabel 
										control={<Checkbox />} 
										label="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque eu ac pharetra ut. Lorem consequat nisl, odio pretium, fringilla." 
										className={classes.checkboxFormControl}
									/>
								</FormGroup>
							</Grid>
						</Grid>
						<Button variant="contained" color="primary" fullWidth type="submit">CREATE NEW ACCOUNT</Button>
					</form>
					<Button className={classes.goBack} onClick={() => history.goBack()}>Go back</Button>
				</div>
				<p className={classes.haveAccount}>
					Have an Account? <span className={classes.login} onClick={() => dispatch(setSignInDialogOpen(true))}>Login</span>
				</p>
			</Grid>
		</Grid>
	);
}

export default Register;