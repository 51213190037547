import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
	userBox: {
		background: theme.palette.background.panel,
		border: `1px solid ${theme.palette.border.main}`,
		boxSizing: 'border-box',
		borderRadius: '3px',
		padding: theme.spacing(2),
		paddingBottom: theme.spacing(1),
		fontFamily: 'Klavika',

		'& hr': {
			margin: '0 !important',
			width: '100%',
			border: `0.5px solid ${theme.palette.border.main} !important`,
		}
	},
	avatar: {
        width: '82px',
        height: '82px',
        border: `2px solid ${theme.palette.auxiliary.grey}`
    },
	displayFlex: {
		display: 'flex'
	},
	flexGrow: {
		flexGrow: 1
	},
	checkCircleIcon: {
		backgroundColor: theme.palette.auxiliary.success,
		height: '40px',
		width: '40px',
		borderRadius: '50%',
		display: 'flex',
	    justifyContent: 'center',
	    alignItems: 'center'
	},
	listItemIcon: {
		borderRadius: '50%',
		justifyContent: 'center',
		alignItems: 'center',
		height: '45px',
		minWidth: '45px',
		marginRight: '10px',

		'& svg': {
			height: '19px',
			width: '19px',
		}
	},
	buyCoinsIcon: {
		border: `1px solid ${theme.palette.auxiliary.warning}`,
		'& svg': {
			fill: theme.palette.auxiliary.warning,
		}
	},
	packStoreIcon: {
		border: `1px solid ${theme.palette.primary.main}`,
		'& svg': {
			fill: theme.palette.primary.main,
		}
	},
	libraryIcon: {
		border: `1px solid ${theme.palette.auxiliary.success}`,
		'& svg': {
			fill: theme.palette.auxiliary.success,
		}
	},
	list: {
		'& div': {
			paddingLeft: 0,
			paddingRight: 0
		},
		'& p': {
			[theme.breakpoints.down("md")]: {
				marginBottom: 0,
				marginTop: '5px'
			}
		}
	},
	listItemText: {
		fontSize: '16px',
		lineHeight: '19px',
		letterSpacing: '0.03em'
	},
	collectibles: {
		fontSize: '15px',
		lineHeight: '18px',
		letterSpacing: '2px',
		color: theme.palette.text.lightOn
	},
	dropdownRight: {
		transform: 'rotate(-90deg)',

		[theme.breakpoints.down("md")]: {
			marginLeft: 'auto',
		}
	},
	fontMontserrat: {
		fontFamily: 'Montserrat',
		fontStyle: 'normal',
		fontWeight: 'bold',
		lineHeight: '16px',
		fontSize: '13px',
	},
	viewAll: {
		color: theme.palette.text.lightOn,
		fontSize: '11px',
		'& a': {
            color: theme.palette.text.lightOn,
            textDecoration: 'none',
            '&:hover': {
                textDecoration: 'underline'
            }
        }
	},
	userName: {
		fontSize: '18px',
		margin: 0
	},
    alignItemsCenter: {
        alignItems: 'center'
    },
	navlink: {
		display: 'flex',
		color: 'inherit',
		textDecoration: 'inherit',
		flexGrow: 1,
    	alignItems: 'center'
	}
}));

export { useStyles };