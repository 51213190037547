import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
	dialog: {
		'& .MuiPaper-rounded': {
			background: theme.palette.background.panel,
			borderRadius: 0
		}
	},
	appBar: {
		background: theme.palette.background.panel,
		borderBottom: `1px solid ${theme.palette.border.panel}`,
		height: '80px',
		justifyContent: 'flex-end'
	},
	title: {
		fontFamily: 'Klavika',
		fontStyle: 'normal',
		fontWeight: 500,
		fontSize: '24px',
		lineHeight: '29px',
		color: theme.palette.text.trueWhite,

		[theme.breakpoints.down("xs")]: {
			fontSize: '20px'
		}
	},
	closeButton: {
		color: theme.palette.icons.main,
	},
    dialogContent: {
		backgroundColor: theme.palette.background.panel,
		color: theme.palette.text.greyDetails,
		flexGrow: 1,
		fontFamily: 'Klavika',
		textAlign: 'center',
		
		'& button, [role=button]': {
			[theme.breakpoints.down("xs")]: {
				width: '100%'
			}
		}
	}
}));

export { useStyles };