import { Button, Grid, makeStyles } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { ReactComponent as ArrowsIcon } from '../../assets/img/arrows.svg';
import { ReactComponent as CardIcon } from '../../assets/img/card.svg';
import { ReactComponent as CreditCardIcon } from '../../assets/img/credit-card.svg';
import { ReactComponent as MarketplaceIcon } from '../../assets/img/marketplace.svg';
import { ReactComponent as ShoppingBagIcon } from '../../assets/img/shopping-bag.svg';
import { setSignUpDialogOpen } from "../../store/slices/dialogs/DialogsSlice";

const useStyles = makeStyles((theme) => ({
	marketplace: {
		maxWidth: 1410,
		margin: 'auto',
		textAlign: 'center',

		[theme.breakpoints.down('md')]: {
			padding: theme.spacing(2),
		},
		[theme.breakpoints.up('md')]: {
			height: 'calc(100vh - 100px)'
		}
	},
	title: {
		fontSize: 48,
		fontWeight: 800,
		lineHeight: '59px',
		margin: 0,

		[theme.breakpoints.down('md')]: {
			fontSize: 30,
			lineHeight: '37px',
		}
	},
	subtitle: {
		fontSize: 16,
		letterSpacing: '0.15px',
		maxWidth: 469,
		lineHeight: '28px',
		margin: '35px auto',

		[theme.breakpoints.down('md')]: {
			margin: '18px auto',
		}
	},
	buttonsBox: {
		width: 300,
		height: 62,
		backgroundColor: theme.palette.background.panel,
		border: `1px solid ${theme.palette.border.main} !important`,
		borderRadius: '4px',
		margin: 'auto',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',

		'& button': {
			height: 48.5,
			width: 145.5
		}
	},
	sellButton: {
		color: '#fff !important'
	},
	description: {
		fontSize: 16,
		fontWeight: 600,
		lineHeight: '24px'
	},
	icon: {
		border: `3px solid ${theme.palette.primary.main}`,
		borderRadius: '50%',
		width: 106,
		height: 106,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		margin: 'auto',

		'& svg': {
			width: 42,
			height: 42,
			fill: theme.palette.primary.main
		},
	},
	iconsBox: {
		marginTop: '65px !important'
	},
	arrows: {
		[theme.breakpoints.down('sm')]: {
			margin: theme.spacing(3),
			transform: 'rotate(90deg)'
		}
	}
}));

const Marketplace = () => {
	const classes = useStyles();
	const dispatch = useDispatch();

	return (
		<Grid container className={classes.marketplace}>
			<Grid container item xs={12} className="margin-auto">
				<Grid item xs={12}>
					<p className={classes.title} data-aos="fade-down">
						Marketplace<br />sell and buy card
					</p>
					<p className={classes.subtitle} data-aos="fade-down">
						On our markeplace you can sell and buy cards from other users. Start earning money collecting cards
					</p>
					<div className={classes.buttonsBox} data-aos="fade-down">
						<div className="display-flex">
							<Button color="primary" variant="contained" onClick={() => dispatch(setSignUpDialogOpen(true))}>BUY</Button>
							<Button className={classes.sellButton} onClick={() => dispatch(setSignUpDialogOpen(true))}>SELL</Button>
						</div>
					</div>
				</Grid>
				<Grid container item xs={12} justifyContent="center" className={classes.iconsBox}>
					<Grid item xs={12} md={2} data-aos="fade-up">
						<div className={classes.icon}>
							<MarketplaceIcon />
						</div>
						<p className={classes.description}>Go to <br />marketplace</p>
					</Grid>
					<Grid item xs={12} md={1} className="align-self-center">
						<ArrowsIcon className={classes.arrows} />
					</Grid>
					<Grid item xs={12} md={2} data-aos="fade-up" data-aos-delay="50">
						<div className={classes.icon}>
							<CardIcon />
						</div>
						<p className={classes.description}>Choose your<br />dream card</p>
					</Grid>
					<Grid item xs={12} md={1} className="align-self-center">
						<ArrowsIcon className={classes.arrows} />
					</Grid>
					<Grid item xs={12} md={2} data-aos="fade-up" data-aos-delay="100">
						<div className={classes.icon}>
							<ShoppingBagIcon />
						</div>
						<p className={classes.description}>Buy the<br />selected card</p>
					</Grid>
					<Grid item xs={12} md={1} className="align-self-center">
						<ArrowsIcon className={classes.arrows} />
					</Grid>
					<Grid item xs={12} md={2} data-aos="fade-up" data-aos-delay="150">
						<div className={classes.icon}>
							<CreditCardIcon />
						</div>
						<p className={classes.description}>Complete the<br />transaction</p>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	)
}

export default Marketplace;