import { Button, Dialog, Grid, IconButton, makeStyles, Toolbar, Typography, TextField } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { useDispatch, useSelector } from 'react-redux';
import { setSignInDialogOpen } from '../../store/slices/dialogs/DialogsSlice';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { loginValidationSchema } from './../../utils/validations/loginValidation';
import clsx from 'clsx';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
	signInDialog: {
		'& .MuiDialog-paper': {
			backgroundColor: theme.palette.background.panel,
		},
		'& .MuiButton-root': {
		    fontFamily: 'Montserrat !important',
			fontWeight: 600,
		}
	},
	title: {
		fontWeight: 'bold',
		fontSize: 24,
		lineHeight: '29px',
		margin: '24px 0',
		color: theme.palette.text.trueWhite
	},
	dialogContent: {
		padding: 35,
		paddingTop: 15,

		[theme.breakpoints.only('xs')]: {
			padding: 16,
		}
	},
	inputLabel: {
		fontWeight: 600,
		letterSpacing: '1.25px',
		marginBottom: 10
	}
}));

const SignInDialog = (props) => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const signInDialogOpen = useSelector((state) => state.dialogs.signInDialogOpen);
	const { control, handleSubmit, formState: { errors } } = useForm({
		resolver: yupResolver(loginValidationSchema),
		defaultValues: {
			email: "",
			password: ""
		}
	});
	const history = useHistory();

	const login = data => {
		// TODO login
		console.log(data);
		localStorage.setItem('token', 'token-testowy');
		dispatch(setSignInDialogOpen(false));
		history.push('/');
	};

	return (
		<Dialog 
			fullWidth 
			maxWidth="xs" 
			open={signInDialogOpen} 
			onClose={() => dispatch(setSignInDialogOpen(false))}
			className={classes.signInDialog}
		>
			<Toolbar>
				<div className="flex-grow-full"></div>
				<Typography variant="h6" className={classes.title}>Login with e-mail</Typography>
				<div className="flex-grow-full"></div>
				<IconButton edge="start" color="inherit" onClick={() => dispatch(setSignInDialogOpen(false))} aria-label="close" size="small">
					<CloseIcon />
				</IconButton>
			</Toolbar>
			<div className={classes.dialogContent}>
				<form onSubmit={handleSubmit(login)}>
					<Grid container item xs={12}>
						<Grid item xs={12}>
							<p className={clsx(classes.inputLabel, "input-label")}>E-MAIL</p>
							<Controller
								name="email"
								control={control}
								render={({ field }) => (
									<TextField
										{ ...field }
										className={errors?.email ? "error-border-input" : ""}
										variant="outlined" 
										placeholder="E-mail"
										type="email"
										fullWidth
									/>
								)}
							/>
						</Grid>
						<Grid item xs={12}>
							<p className={clsx(classes.inputLabel, "input-label")}>PASSWORD</p>
							<Controller
								name="password"
								control={control}
								render={({ field }) => (
									<TextField
										{ ...field }
										className={errors?.password ? "error-border-input" : ""}
										variant="outlined" 
										placeholder="PASSWORD"
										type="password"
										fullWidth
									/>
								)}
							/>
						</Grid>
					</Grid>
					<Button variant="contained" color="primary" fullWidth type="submit">LOGIN</Button>
				</form>
			</div>
		</Dialog>
	);
}

export default SignInDialog;