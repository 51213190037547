import { createSlice } from '@reduxjs/toolkit';

export const DialogsSlice = createSlice({
	name: 'alert',
	initialState: {
		signUpDialogOpen: false,
		signInDialogOpen: false,
	},
	reducers: {
		setSignUpDialogOpen: (state, action) => {
			state.signUpDialogOpen = action.payload;
		},
		setSignInDialogOpen: (state, action) => {
			state.signInDialogOpen = action.payload;
		}
	}
});

export const { setSignUpDialogOpen, setSignInDialogOpen } = DialogsSlice.actions;

export default DialogsSlice.reducer;