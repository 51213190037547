import TickInsideCircleImg from '../../../assets/img/tick-inside-circle.svg';
import ButtonPrimary from "../../UI/Buttons/ButtonPrimary/ButtonPrimary";
import { useStyles } from "./ConfirmSellCardDialogContentStyle";

const ConfirmSellCardDialogContent = (props) => {
	const classes = useStyles();

    return (
        <div className={classes.confirmSellCardDialogContent}>
			<br />
			<img src={TickInsideCircleImg} alt="OK" />
			<p className={classes.description}><span className={classes.cool}>Cool!</span> Your item{props.plural ? 's have' : ' has'} been listed on the marketplace</p>
			<p>When someone buys your item{props.plural && 's'}, you'll receive a notification and the proceeds of the sale go to your wallet</p>
			<br />
			<ButtonPrimary onClick={props.handleClose}>OK</ButtonPrimary>
		</div>
    );
}

export default ConfirmSellCardDialogContent;