import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../../shared/axios-client';

export const fetchUser = createAsyncThunk(
	'user/fetch',
	async () => {
		const response = await axios.get('users/custom/3');
		return response.data.data;
	}
);

export const editUser = createAsyncThunk(
	'user/edit',
	async (data) => {
		let formData = new FormData();
		formData.set('user[avatar]', data.avatar);
		formData.set('user[nickname]', data.nickname);
		formData.set('user[email]', data.email);
		formData.set('user[phone]', data.phone);
		const response = await axios.patch('users/custom/3', formData, {headers: {'content-type': 'multipart/form-data'}});
		return response.data.data;
	}
);

export const UserSlice = createSlice({
	name: 'user',
	initialState: {
		status: "init",
		error: null,
		data: {}
	},
	reducers: {},
	extraReducers: {
		[fetchUser.pending.type]: (state, action) => {
			state.status = "pending";
			state.error = null;
			state.data = {};
		},
		[fetchUser.fulfilled.type]: (state, action) => {
			state.status = "fulfilled";
			state.error = null;
			state.data = action.payload;
		},
		[fetchUser.rejected.type]: (state, action) => {
			state.status = "rejected";
			state.error = action.error.message;
			state.data = {};
		},
		[editUser.fulfilled.type]: (state, action) => {
			state.error = null;
			state.data = action.payload;
		}
	}
});

export default UserSlice.reducer;