import { forwardRef } from "react";
import NumberFormat from 'react-number-format';

const DecimalFormat = forwardRef(function NumberFormatCustom(props, ref) {
  const { inputRef, ...other } = props;

  return (
	<NumberFormat
		{...other}
		isNumericString
		decimalScale={2}
	/>
  );
});

export default DecimalFormat;