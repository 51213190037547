import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
	filterBox: {
		marginLeft: `-${theme.spacing(3)}px`,
		marginRight: `-${theme.spacing(3)}px`,
		background: theme.palette.background.main,
		borderBottom: `1px solid ${theme.palette.border.main}`,
		padding: `${theme.spacing(2)}px ${theme.spacing(3)}px`,
		width: 'auto',
		alignItems: 'center'
	},
	searchInput: {
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(1),
		width: '100%',
	},
	searchButton: {
		margin: theme.spacing(1),

		[theme.breakpoints.down("md")]: {
			marginRight: 0
		},
		[theme.breakpoints.only("md")]: {
			marginRight: theme.spacing(1)
		}
	},
	sellButtonBox: {
		justifyContent: "flex-end",
		
		[theme.breakpoints.down("sm")]: {
			justifyContent: "center",
			marginTop: theme.spacing(1),

			'& button': {
				width: '100%'
			}
		}
	}
}));

export { useStyles };