import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
	withdrawBox: {
		[theme.breakpoints.down("sm")]: {
			flexDirection: 'column-reverse'
		}
	},
	withdrawInfoBox: {
		display: 'flex',
		alignItems: 'center',
		padding: `${theme.spacing(3)}px ${theme.spacing(2)}px`,
		background: 'linear-gradient(169.5deg, rgba(10, 15, 29, 0) -33.62%, #1F2431 125.58%)',
		fontSize: 25,

		[theme.breakpoints.only("xs")]: {
			display: 'block',
			paddingTop: theme.spacing(1),

			'& button': {
				width: '100%'
			}
		}
	},
	walletIcon: {
		minWidth: 63,
		width: 63,
		height: 63,
		borderRadius: '50%',
		border: `1px solid ${theme.palette.icons.main}`,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		marginRight: theme.spacing(2),

		'& svg': {
			width: 20,
			height: 20
		}
	},
	withdrawWarningBox: {
		background: 'rgba(214, 179, 19, 0.05)',
		border: `1px solid ${theme.palette.auxiliary.warning}`,
		borderRadius: 3,
		fontSize: 15,
		height: 'fit-content',
		paddingLeft: theme.spacing(2),
		paddingRight: theme.spacing(2),
	},
	warningText: {
		color: theme.palette.auxiliary.warning
	}
}));

export { useStyles };