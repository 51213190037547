import { useEffect } from "react";
import { useHistory } from "react-router-dom";

const ScrollToTop = ({ force }) => {
	const history = useHistory();

	useEffect(() => {
		if (force) {
			window.scrollTo(0, 0);
			return null;
		} else {
			const unlisten = history.listen(() => {
				window.scrollTo(0, 0);
			});
			return () => unlisten();
		}
	}, [force, history]);

	return null;
}

export default  ScrollToTop;