export const getRarityColor = rarity => {
	switch (String(rarity).toUpperCase()) {
		case 'COMMON':
			return "#333333";
		case 'EXCLUSIVE':
			return "#d11767";
		case 'LEGENDARY':
			return "#a96700";
		case 'RARE':
			return "#4748af"
		case 'SPECIAL':
			return "#70bfcd";
		case 'SUPER RARE':
			return "#4e0c77";
		case 'UNCOMMON':
			return "#2ec008";
		default:
			return "#333333";
	};
};