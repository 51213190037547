import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
	packBox: {
		background: `linear-gradient(169.5deg, rgba(10, 15, 29, 0) -33.62%, ${theme.palette.border.panel} 125.58%)`,
		fontFamily: 'Klavika',

		[theme.breakpoints.down('md')]: {
			'& button': {
				width: '100%'
			}
		},
	},
	packImg: {
		height: '100%',
		width: 'auto',
		maxHeight: '250px',
		margin: 'auto',
		padding: theme.spacing(2),
		borderRadius: '20px'
	},
	title: {
		fontWeight: 500,
		fontSize: '24px',
		lineHeight: '29px',
		letterSpacing: '0.04em',
		marginBottom: 0,
		marginTop: 0,

		[theme.breakpoints.up('sm')]: {
			marginTop: theme.spacing(2)
		}
	},
	subtitle: {
		lineHeight: '20px',
		color: theme.palette.text.lightOn
	},
	infoBox: {
		padding: theme.spacing(2)
	}
}));

export { useStyles };
