import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
	whiteSpaceNoWrap: {
		whiteSpace: 'nowrap'
	},
	rarityCell: {
		color: `${theme.palette.auxiliary.warning} !important`
	},
	avatar: {
		width: '42px',
		height: '42px',
		border: `2px solid ${theme.palette.auxiliary.grey}`,
		marginRight: theme.spacing(2)
	},
}));

export { useStyles };