import { CircularProgress, Grid, InputAdornment, TextField } from "@material-ui/core";
import Search from '@material-ui/icons/Search';
import clsx from "clsx";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as CardIcon } from '../../assets/img/card.svg';
import { ReactComponent as MarketplaceIcon } from '../../assets/img/marketplace.svg';
import FiltersSelect from "../UI/FiltersSelect/FiltersSelect";
import PageHeader from "../UI/PageHeader/PageHeader";
import { fetchCards, setFilterName, setFilterNeed } from './../../store/slices/cards/CardsSlice';
import CardBox from './../CardBox/CardBox';
import ButtonGreyOutlined from './../UI/Buttons/ButtonGreyOutlined/ButtonGreyOutlined';
import ButtonPrimary from './../UI/Buttons/ButtonPrimary/ButtonPrimary';
import { useStyles } from "./MarketplaceStyle";
import MarketplaceFilterItems from './MarketplaceFilterItems/MarketplaceFilterItems';

const Marketplace = () => {
	const classes = useStyles();
	
	const dispatch = useDispatch();
	const cards = useSelector((state) => state.cards.data);
	const cardsStatus = useSelector((state) => state.cards.status);
	const name = useSelector((state) => state.cards.filter.name);
	const need = useSelector((state) => state.cards.filter.need);
	const rarity = useSelector((state) => state.cards.filter.rarity);
	const organization = useSelector((state) => state.cards.filter.organization);
	const priceSort = useSelector((state) => state.cards.sort.price);
	const popularitySort = useSelector((state) => state.cards.sort.popularity);

	const [searchInputValue, setSearchInputValue] = useState(name);

	useEffect(() => {
		const filters = {
			name: name,
			tag: need ? 'NEED' : null,
			priceSort: priceSort,
			popularitySort: popularitySort,
			rarity: rarity,
			organization: organization
		};
		dispatch(fetchCards(filters));
	}, [name, need, priceSort, popularitySort, rarity, organization, dispatch]);

	return (
		<>
			<PageHeader title="MARKETPLACE" icon={<MarketplaceIcon />} />
			<Grid container className={clsx(classes.filterBox, classes.font)}>
				<Grid item container xs={12} lg={5} className={classes.filterButtonsBox}>
					<Grid item xs={12} lg={6} className="display-flex">
						<TextField
							className={classes.searchInput}
							value={searchInputValue}
							onChange={(event) => setSearchInputValue(event.target.value)}
							variant="outlined" 
							placeholder="Search"
							InputProps={{
								endAdornment: <InputAdornment position="end"><Search /></InputAdornment>
							}}
						/>
						<ButtonPrimary 
							className={classes.searchButton}
							onClick={() => dispatch(setFilterName(searchInputValue))}
						>SEARCH</ButtonPrimary>
					</Grid>
					<Grid item xs={12} lg={6} className={clsx(classes.scrollableBox, "display-flex")}>
						<ButtonGreyOutlined 
							className={clsx(classes.filterButton, !need && classes.activeFilterButton)}
							onClick={() => dispatch(setFilterNeed(false))}
						><CardIcon /> CARD</ButtonGreyOutlined>
						<ButtonGreyOutlined 
							className={clsx(classes.filterButton, need && classes.activeFilterButton)}
							onClick={() => dispatch(setFilterNeed(true))}
						>NEED</ButtonGreyOutlined>
					</Grid>
				</Grid>
				<Grid item container xs={12} lg={7} className={clsx(classes.displayFlex, classes.filterSelectsBox)}>
					<FiltersSelect>
						<MarketplaceFilterItems />
					</FiltersSelect>
				</Grid>
			</Grid>
			<br />
			<Grid container justifyContent="center">
				{(cardsStatus === 'init' || cardsStatus === 'pending') && <div className="text-align-center"><CircularProgress /></div>}
				{(cardsStatus === 'fulfilled' || cardsStatus === 'rejected') && (!cards?.length ? 
					<p className="text-align-center">No data :(</p> : 
					cards.map(card => (
						<CardBox 
							key={card.id}
							card={card}
							tag={card.tag} />
					))	
				)}
			</Grid>
		</>
	);
}
  
export default Marketplace;