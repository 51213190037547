import { useScrollTrigger } from "@material-ui/core";
import { cloneElement } from "react";

const ScrollHandler = props => {
    const trigger = useScrollTrigger({
        disableHysteresis: true,
        threshold: 0,
        target: props.window ? window() : undefined
    });

    return cloneElement(props.children, {
        style: {
            backgroundColor: trigger ? "#070A14" : "transparent"
        }
    });
};

const ScrollToColor = props => {
    return <ScrollHandler {...props}>{props.children}</ScrollHandler>;
};

export default ScrollToColor;