import { createTheme } from '@material-ui/core/styles';

const lpTheme = createTheme({
	palette: {
		primary: {
			main: '#00E7AE'
		},
		secondary: {
			main: '#13161F'
		},
		background: {
			main: '#070A14',
			panel: '#0C1120',
			hoverMain: '#13161F'
		},
		text: {
			trueWhite: '#FFFFFF',
			greyStuned: '#414553',
			greyDetails: '#434654',
			lightOn: '#767D92',
		},
		border: {
			main: '#1E222D',
			panel: '#1F2431',
		},
		icons: {
			main: '#4B5C6E'
		}
	}
});

lpTheme.overrides = {
	MuiButton: {
		root: {
			fontFamily: 'Montserrat',
			textTransform: 'none',
			height: 44,
			borderRadius: '3px',
			padding: '14px 20px',
			fontSize: '13px',
			fontWeight: 600,
			lineHeight: '16px',
			color: lpTheme.palette.background.panel
		},
	},
	MuiAppBar: {
		root: {
			color: lpTheme.palette.text.trueWhite
		}
	},
	MuiPaper: {
		root: {
			backgroundColor: lpTheme.palette.background.main,
			color: lpTheme.palette.text.trueWhite
		}
	},
	MuiAccordionSummary: {
		root: {
			backgroundColor: lpTheme.palette.background.main,
			borderTop: `1px solid ${lpTheme.palette.border.main}`,
			padding: 0
		},
		expandIcon: {
			color: lpTheme.palette.icons.main
		}
	},
	MuiAccordionDetails: {
		root: {
			padding: 0,
			display: 'block'
		}
	},
	MuiTypography: {
		root: {
			fontFamily: 'Montserrat !important',
		}
	},
	MuiDialog: {
		container: {
			fontFamily: 'Montserrat !important',
		}
	},
	MuiTextField: {
		root: {
			background: lpTheme.palette.background.main,
			border: `1px solid ${lpTheme.palette.border.panel}`,
			borderRadius: '4px',
			height: '53px',
			fontFamily: 'Montserrat',
			fontSize: '14px',
			lineHeight: '17px',
			marginBottom: 25,

			'&:hover': {
				border: `1px solid ${lpTheme.palette.border.panel}`,
			}
		}
	},
	MuiInputBase: {
		root: {
   			height: '53px',
		},
		input: {
			fontFamily: 'Montserrat',
			color: '#DCDDDD',
			padding: '0 8px',
   			height: '53px',
		}
	},
};

export default lpTheme;