import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../../shared/axios-client';

export const fetchPacks = createAsyncThunk(
	'packs/fetch',
	async () => {
		const response = await axios.get('booster_packs/3');
		return [response.data.data];
		// TODO
		// const response = await axios.get('booster_packs');
		// return response.data.data;
	}
);

export const PacksSlice = createSlice({
	name: 'packs',
	initialState: {
		status: "init",
		error: null,
		data: [],
	},
	reducers: {},
	extraReducers: {
		[fetchPacks.pending.type]: (state, action) => {
			state.status = "pending";
			state.error = null;
			state.data = [];
		},
		[fetchPacks.fulfilled.type]: (state, action) => {
			state.status = "fulfilled";
			state.error = null;
			state.data = action.payload;
		},
		[fetchPacks.rejected.type]: (state, action) => {
			state.status = "rejected";
			state.error = action.error.message;
			state.data = [];
		},
	}
});

export default PacksSlice.reducer;