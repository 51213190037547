import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
	badgeBlack: {
		background: theme.palette.background.panel,
		borderRadius: '120px',
		fontFamily: 'Klavika',
		fontSize: '15px',
		lineHeight: '18px',
		color: theme.palette.text.trueWhite,
		padding: '7px 15px',
	}
}));

export { useStyles };