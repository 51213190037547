import { Grid, Hidden } from "@material-ui/core";
import clsx from "clsx";
import DiamondCardImg from '../../../assets/img/diamond-card.svg';
import ButtonPrimary from "../../UI/Buttons/ButtonPrimary/ButtonPrimary";
import CoinIcon from './../../UI/Icons/CoinIcon/CoinIcon';
import { useStyles } from "./DiamonPackBoxStyle";

const DiamondPackBox = () => {
	const classes = useStyles();

	return (
        <Grid item className={classes.diamondPackBox} xs={12}>
			<Grid item container>
				<Grid item xs={12} lg={5} className={classes.diamondPackBackground}>
					<img src={DiamondCardImg} alt="Diamond Card" />
				</Grid>
				<Grid item xs={12} lg={7} className={classes.infoBox}>
					<h3 className={classes.title}>Diamond Pack 2021</h3>
					<p className={classes.subtitle}>Contains 7 cards from Core 2021 season. Can contain Common, Uncommon, Tier 1, Tier 2, Tier 3 and Team</p>
					<Hidden lgUp>
						<div className={clsx(classes.displayFlex, classes.alignItemsCenter)}>
							<CoinIcon />&nbsp;<p>30.000</p>
						</div>	
						<ButtonPrimary>BUY NOW</ButtonPrimary>
					</Hidden>
					<Hidden mdDown>
						<div className={clsx(classes.displayFlex, classes.alignItemsCenter)}>
							<ButtonPrimary>BUY NOW</ButtonPrimary>&nbsp;&nbsp;&nbsp;
							<CoinIcon />&nbsp;<p>30.000</p>
						</div>
					</Hidden>
				</Grid>
			</Grid>
		</Grid>
	);
}
  
export default DiamondPackBox;