import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
	badgeGreen: {
		background: theme.palette.auxiliary.success,
		borderRadius: '120px',
		fontFamily: 'Klavika',
		fontSize: '12px',
		lineHeight: '14px',
		color: theme.palette.text.trueWhite,
		padding: '7px 15px',
	}
}));

export { useStyles };