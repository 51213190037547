import { Paper, Table, TableContainer } from '@material-ui/core';
import { useStyles } from './StyledTableStyle';

const StyledTable = props => {
	const classes = useStyles();
	
	return (
		<TableContainer component={Paper} className={classes.tableComponent}>
			<Table className={classes.table} aria-label="customized table">
				{props.children}
			</Table>
		</TableContainer>
	);
}

export default StyledTable;