import { makeStyles } from '@material-ui/core/styles';
import { drawerWidth } from '../../../../shared/vars';

const useStyles = makeStyles((theme) => ({
	appBar: {
		background: theme.palette.background.main,
		borderBottom: `1px solid ${theme.palette.border.panel}`,
		width: `calc(100% - ${drawerWidth}px)`,
		marginLeft: drawerWidth,
		height: '85px'
	},
	flexGrow: {
		flexGrow: 1
	},
	balanceButton: {
		background: theme.palette.background.panel,
		border: `1px solid ${theme.palette.border.main}`,
		boxSizing: 'border-box',
		borderRadius: '3px'
	},
	fontWhite: {
		fontFamily: 'Klavika',
		fontStyle: 'normal',
		fontWeight: 500,
		fontSize: '13px',
		lineHeight: '16px',
		color: theme.palette.text.trueWhite
	},
	avatar: {
		width: '42px',
		height: '42px',
		border: `2px solid ${theme.palette.auxiliary.grey}`,
	},
	avatarName: {
		fontSize: '16px',
		lineHeight: '19px',
		margin: '10px',
		marginRight: '15px',
	},
	navLink: {
		display: 'flex',
    	textDecoration: 'none'
	},
	walletInfo: {
		marginLeft: 5
	},
	walletAmount: {
		margin: 0,
	    marginBottom: '-5px',
	},
	walletLink: {
		color: theme.palette.primary.main,
	    textDecoration: 'none',
	    fontSize: 10,

		'&:hover': {
			textDecoration: 'underline'
		}
	}
}));

export { useStyles };