import { makeStyles } from '@material-ui/core/styles';
import { drawerWidth } from '../../../../shared/vars';

const useStyles = makeStyles((theme) => ({
	drawer: {
		[theme.breakpoints.up("md")]: {
			width: drawerWidth,
			flexShrink: 0
		}
	},
	drawerPaper: {
		width: drawerWidth,
		background: theme.palette.background.main,
		borderRight: `1px solid ${theme.palette.border.panel}`,
		textAlign: 'center'
	},
	logo: {
		width: '62px',
		height: '67px',
		marginTop: '25px',
		marginLeft: '30px'
	},
	listBox: {
		flexGrow: 1,
		display: 'flex',
		flexDirection: 'column',
	}
}));

export { useStyles };