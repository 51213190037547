import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
	danger: {
        background: theme.palette.auxiliary.danger,
		borderRadius: '3px',
		padding: '14px 20px',
		textTransform: 'none',
		color: theme.palette.text.trueWhite,
		fontWeight: 600,
		fontSize: '13px',
		lineHeight: '16px',
		fontFamily: 'Montserrat',
		height: '44px',

        '&:hover': {
            background: theme.palette.auxiliary.danger,
        }
	}
}));

export { useStyles };