import { createSlice } from '@reduxjs/toolkit';

export const AlertSlice = createSlice({
	name: 'alert',
	initialState: {
		open: false,
		message: ''
	},
	reducers: {
		setOpen: (state, action) => {
			state.open = action.payload;
		},
		setMessage: (state, action) => {
			state.message = action.payload;
		} 
	}
});

export const { setOpen, setMessage } = AlertSlice.actions;

export default AlertSlice.reducer;