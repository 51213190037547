import { Grid, TableBody, TableHead, TableRow, TextField } from '@material-ui/core';
import clsx from 'clsx';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import AnimatedCard from '../../../AnimatedCard/AnimatedCard';
import ConfirmSellCardDialogContent from '../../../MarketplaceItem/ConfirmSellCardDialogContent/ConfirmSellCardDialogContent';
import IncrementDecrementButtonGroup from '../../../UI/Buttons/IncrementDecrementButtonGroup/IncrementDecrementButtonGroup';
import DecimalFormat from '../../../UI/DecimalFormat/DecimalFormat';
import StyledTable from '../../../UI/StyledTable/StyledTable';
import { StyledTableCell } from '../../../UI/StyledTable/StyledTableCell';
import { StyledTableRow } from '../../../UI/StyledTable/StyledTableRow';
import CustomDialog from './../../../UI/Dialogs/CustomDialog/CustomDialog';
import { useStyles } from './YourCardsTableSellStyle';
import { getRarityColor } from './../../../../utils/rarityColor';

const YourCardsTableSell = props => {
	const classes = useStyles(props);
	const { control, setValue, getValues, formState: { errors } } = useFormContext();
	const { fields: sellCardFormFields } = useFieldArray({ 
		control, 
		name: "sell" 
	});

	return (
		<>
			<Grid item xs={12}>
				<StyledTable>
					<TableHead>
						<TableRow>
							<StyledTableCell className={classes.whiteSpaceNoWrap}>NAME CARD</StyledTableCell>
							<StyledTableCell>COLLECTION</StyledTableCell>
							<StyledTableCell>RARITY</StyledTableCell>
							<StyledTableCell>QUANTITY</StyledTableCell>
							<StyledTableCell className={classes.whiteSpaceNoWrap}>STACK CARD</StyledTableCell>
							<StyledTableCell className={classes.whiteSpaceNoWrap}>BASIC PRICE</StyledTableCell>
							<StyledTableCell className={classes.whiteSpaceNoWrap}>YOUR PRICE</StyledTableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{sellCardFormFields.map((card, i) => (
							<StyledTableRow key={card.id} className={clsx(classes.tableRow, errors?.sell?.[i] ? "error-border-table-row" : "")}>
								<StyledTableCell scope="row">
									<div className={clsx("display-flex align-items-center", classes.nameCell)}>
										<div className={classes.cardBox}>
											<AnimatedCard card={card?.card} size="sm" noRotate />
										</div>
										<span className={classes.whiteSpaceNoWrap}>{card?.card?.name}</span>
									</div>
								</StyledTableCell>
								<StyledTableCell className={classes.whiteSpaceNoWrap}>{card?.card?.cardCollection?.name}</StyledTableCell>
								<StyledTableCell 
									style={{ color: `${getRarityColor(card?.card?.cardType?.name)}`}}
								>
									{String(card?.card?.cardType?.name).toUpperCase()}
								</StyledTableCell>
								<StyledTableCell>
									{card.quantity > 1 ? (
										<IncrementDecrementButtonGroup
											quantity={getValues().sell?.[i]?.sellQuantity}
											minusButtonClickHandle={quantity => setValue(`sell[${i}].sellQuantity`, quantity)}
											plusButtonClickHandle={quantity => setValue(`sell[${i}].sellQuantity`, quantity)}
											minusButtonDisabled={1}
											plusButtonDisabled={card.quantity}
										/>
									) : 1}
								</StyledTableCell>
								<StyledTableCell>{card.quantity ? card.quantity : 1}</StyledTableCell>
								<StyledTableCell className={classes.whiteSpaceNoWrap}>{Number(card.price).toFixed(2)} PLN</StyledTableCell>
								<StyledTableCell>
									<Controller
										key={card.id}
										name={`sell[${i}].sellPrice`}
										control={control}
										render={({ field }) => (
											<TextField
												{ ...field }
												className={clsx(classes.priceInput, errors?.sell?.[i]?.sellPrice && "error-border-input")}
												variant="outlined" 
												placeholder="Your price"
												InputProps={{
													inputComponent: DecimalFormat,
												}}
											/>
										)}
									/>
								</StyledTableCell>
							</StyledTableRow>
						))}
					</TableBody>
				</StyledTable>
			</Grid>
			<CustomDialog
				title="Confirmation" 
				maxWidth="md"
				open={props.openConfirmationDialog}
				handleClose={props.handleCloseConfirmationDialog}>
				<ConfirmSellCardDialogContent plural handleClose={props.handleCloseConfirmationDialog} />
			</CustomDialog>
		</>
	);
}

export default YourCardsTableSell;