import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../../shared/axios-client';

export const fetchAllYourCards = createAsyncThunk(
	'allYourCards/fetch',
	async ({name = null, priceSort = null, popularitySort = null, rarity = null, organization = null}) => {
		const response = await axios.get('generated_cards', { params: { 
			nameIncludes: name,
			priceSort: priceSort,
			popularitySort: popularitySort,
			'byCardTypeIds[]': rarity,
			'byOrganisationIds[]': organization,
			'byOwnerIds[]': 3, // TODO logged user id
			view: 'full_nested',
			limit: 100 // TODO paginacja
		}});
		return response.data.data;
	}
);

export const AllYourCardsSlice = createSlice({
	name: 'allYourCards',
	initialState: {
		status: "init",
		error: null,
		data: [],
	},
	reducers: {},
	extraReducers: {
		[fetchAllYourCards.pending.type]: (state, action) => {
			state.status = "pending";
			state.error = null;
			state.data = [];
		},
		[fetchAllYourCards.fulfilled.type]: (state, action) => {
			state.status = "fulfilled";
			state.error = null;
			state.data = action.payload;
		},
		[fetchAllYourCards.rejected.type]: (state, action) => {
			state.status = "rejected";
			state.error = action.error.message;
			state.data = [];
		},
	}
});

export default AllYourCardsSlice.reducer;