import clsx from 'clsx';
import NotificationsNewImg from '../../../../../assets/img/notifications-new.svg';
import ButtonGrey from '../../../Buttons/ButtonGrey/ButtonGrey';
import { useStyles } from './NotificationsButtonStyle';

const NotificationsButton = (props) => {
	const classes = useStyles();

	return (
		<ButtonGrey className={clsx(classes.notifications, props.className)}>
			<img src={NotificationsNewImg} alt="NotificationsNew" />
		</ButtonGrey>
	);
}

export default NotificationsButton;