import { Button } from "@material-ui/core";
import clsx from 'clsx';
import { useStyles } from "./ButtonLightOnOutlinedStyle";

const ButtonLightOnOutlined = (props) => {
	const classes = useStyles();

	return (
		<Button
			{...props}
			color="secondary"
			variant="outlined"
			className={clsx(classes.lightOnOutlined, props.className)}
		></Button>
	);
}

export default ButtonLightOnOutlined;