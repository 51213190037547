import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
	primary: {
		borderRadius: '3px',
		padding: '14px 20px',
		textTransform: 'none',
		color: theme.palette.background.panel,
		fontWeight: 600,
		fontSize: '13px',
		lineHeight: '16px',
		boxShadow: '0px 6px 30px rgba(0, 231, 174, 0.25)',
		fontFamily: 'Montserrat',
		height: '44px'
	}
}));

export { useStyles };