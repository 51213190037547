import { Grid } from '@material-ui/core';
import { ReactComponent as WalletGreenIcon } from '../../assets/img/wallet-green.svg';
import PageHeader from './../UI/PageHeader/PageHeader';
import SectionHeader from './../UI/SectionHeader/SectionHeader';
import PaymentMethod from './PaymentMethod/PaymentMethod';
import Product from './Product/Product';

const BuyCredit = (props) => {

	return (
		<>
			<PageHeader title="BUY CREDIT" icon={<WalletGreenIcon />} />
			<br />
			<SectionHeader title="PAYMENT METHOD" subtitle="Choose what form of payment you want to pay with" />
			<PaymentMethod />
			<br />
			<SectionHeader title="SELECT PRODUCT" subtitle="Choose the product you are interested in" />
			<Grid container item xs={12} alignItems="center" justifyContent="center" spacing={2}>
				<Grid item><Product /></Grid>
				<Grid item><Product /></Grid>
				<Grid item><Product /></Grid>
				<Grid item><Product /></Grid>
				<Grid item><Product /></Grid>
				<Grid item><Product /></Grid>
			</Grid>
		</>
	);
}

export default BuyCredit;