import { Line } from 'react-chartjs-2';
import './StatisticsChart.scss';

const options = {
	scales: {
		y: {
			grid: { color: "#1E222D" }
		},
		x: {
			grid: { color: "#0C1120" }
		}
	},
	plugins: {
		legend: {
			display: false
		},
		tooltips: {
			callbacks: {
				label: (tooltipItem) => tooltipItem.yLabel
			}
		}
	},
};

const StatisticsChart = (props) => {
	const data = {
		labels: props.cardStatistics.date,
		datasets: [
			{
				data: props.cardStatistics.priceAvg,
				fill: true,
				backgroundColor: 'rgba(0, 231, 174, 0.06)',
				borderColor: '#00E7AE',
				tension: 0.5,
				pointRadius: 0,
				hoverRadius: 5
			},
		],
	};

	return (
		<div className="statistic-chart">
			<Line data={data} options={options} />
		</div>
	);
}

export default StatisticsChart;