export const upperCase = text => {
	return text.toUpperCase();
};

export const underscoreToSpace = text => {
	return text.replace("_", " ");
};

export const dashToSpace = text => {
	return text.replace("-", " ");
};