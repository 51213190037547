import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
// import axios from '../../../shared/axios-client';

const transactionHistory = [
	{
		id: '#0591200',
		name: 'Doładowałeś balans',
		amount: 50,
		executionDate: '2021-11-01T00:11:50.037Z',
		paidBy: 'Przelewy24',
		paymentMethod: 'Karta płatnicza',
		status: 'DONE'
	},
	{
		id: '#0591201',
		name: 'Doładowałeś balans',
		amount: 50,
		executionDate: '2021-11-01T00:11:50.037Z',
		paidBy: 'Przelewy24',
		paymentMethod: 'Karta płatnicza',
		status: 'REJECT'
	},
	{
		id: '#0591203',
		name: 'Doładowałeś balans',
		amount: 50,
		executionDate: '2021-11-01T00:11:50.037Z',
		paidBy: 'Przelewy24',
		paymentMethod: 'Karta płatnicza',
		status: 'IN_PROGRES'
	},
	{
		id: '#0591204',
		name: 'Doładowałeś balans',
		amount: 50,
		executionDate: '2021-11-01T00:11:50.037Z',
		paidBy: 'Przelewy24',
		paymentMethod: 'Karta płatnicza',
		status: 'DONE'
	},
	{
		id: '#0591205',
		name: 'Doładowałeś balans',
		amount: 50,
		executionDate: '2021-11-18T10:20:15.455Z',
		paidBy: 'Przelewy24',
		paymentMethod: 'Karta płatnicza',
		status: 'REJECT'
	},
	{
		id: '#0591206',
		name: 'Doładowałeś balans',
		amount: 50,
		executionDate: '2021-11-01T04:11:50.037Z',
		paidBy: 'Przelewy24',
		paymentMethod: 'Karta płatnicza',
		status: 'IN_PROGRES'
	},
];

export const fetchTransactionHistory = createAsyncThunk(
	'transactionHistory/fetch',
	async () => {
		// const response = await axios.get('transaction_history');
		// return response.data.data;
		
		return transactionHistory;
	}
);

export const TransactionHistorySlice = createSlice({
	name: 'transactionHistory',
	initialState: {
		status: "init",
		error: null,
		data: {}
	},
	reducers: {},
	extraReducers: {
		[fetchTransactionHistory.pending.type]: (state, action) => {
			state.status = "pending";
			state.error = null;
			state.data = {};
		},
		[fetchTransactionHistory.fulfilled.type]: (state, action) => {
			state.status = "fulfilled";
			state.error = null;
			state.data = action.payload;
		},
		[fetchTransactionHistory.rejected.type]: (state, action) => {
			state.status = "rejected";
			state.error = action.error.message;
			state.data = {};
		}
	}
});

export default TransactionHistorySlice.reducer;