import { Hidden } from '@material-ui/core';
import CssBaseline from "@material-ui/core/CssBaseline";
import NavbarLeft from "./NavbarLeft/NavbarLeft";
import NavbarMobile from './NavbarMobile/NavbarMobile';
import { useStyles } from "./NavbarStyle";
import NavbarTop from './NavbarTop/NavbarTop';

const Navbar = () => {
  const classes = useStyles();

  return (
	<div className={classes.root}>
		<CssBaseline />
		<Hidden only={['xs', 'sm']}>
			<NavbarTop />
			<NavbarLeft />
		</Hidden>
		<Hidden mdUp>
			<NavbarMobile />
		</Hidden>
	</div>
  );
}

export default Navbar;
