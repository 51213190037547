import { Grid, Hidden, IconButton, withWidth } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { PropTypes } from 'prop-types';
import { useState } from 'react';
import PunchdownTotalbetImg from '../../../assets/img/punchdown-totalbet.svg';
import ButtonPrimaryOutlined from '../../UI/Buttons/ButtonPrimaryOutlined/ButtonPrimaryOutlined';
import TimeCountdown from '../../UI/TimeCountdown/TimeCountdown';
import { useStyles } from './EventBoxStyle';

const EventBox = (props) => {
	const { width } = props;
	const classes = useStyles();
	const [hidden, setHidden] = useState(false);

	return (width === 'xs' && hidden ? null :
        <Grid item className={classes.eventBox}>
			<Hidden smUp>
				<IconButton color="inherit" onClick={() => setHidden(true)} className={classes.closeButton} aria-label="close">
					<CloseIcon />
				</IconButton>
			</Hidden>
            <Grid container spacing={(width === 'xs' || width === 'sm') ? 0 : 3}>
				<Grid item xs={12} lg={2} xl={1} className={classes.punchdownTotalbetImgBox}>
					<img src={PunchdownTotalbetImg} className={classes.punchdownTotalbetImg} alt="PunchdownTotalbetImg" />
				</Grid>
				<Grid item xs={12} md={6} lg={4} xl={5}>
					<Grid container justifyContent="center" alignItems="center" className={classes.eventStartsBox} spacing={width === 'xs' ? 0 : 2}>
						<Grid item>
							<p className={classes.eventStartsText}>THE EVENT STARTS IN:</p>
						</Grid>
						<Grid item className={classes.timeCountdown}>
							<TimeCountdown />
						</Grid>
					</Grid>
				</Grid>
				<Grid item xs={12} md={6}>
					<Grid container justifyContent="center" spacing={1} className={classes.ppvBox}>
						<Grid item>
							<p>BUY PPV, GET UNIQUE CARDS</p>
						</Grid>
						<Grid item>
							<ButtonPrimaryOutlined href="https://www.punchdown.tv" target="_blank">BUY PPV</ButtonPrimaryOutlined>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
}

EventBox.propTypes = {
	width: PropTypes.oneOf(['lg', 'md', 'sm', 'xl', 'xs']).isRequired,
};

export default withWidth()(EventBox);