import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../../shared/axios-client';

export const fetchLibraryCards = createAsyncThunk(
	'libraryCards/fetch',
	async ({organizationId, collectionId}) => {
		const response = await axios.get('cards', { params: { 
			'byOrganisationIds[]': organizationId,
			'byCardCollectionIds[]': collectionId,
			view: 'extended',
			limit: 100 // TODO paginacja
		 }});
		return response.data.data;
	}
);

export const LibraryCardsSlice = createSlice({
	name: 'libraryCards',
	initialState: {
		status: "init",
		error: null,
		data: []
	},
	reducers: {},
	extraReducers: {
		[fetchLibraryCards.pending.type]: (state, action) => {
			state.status = "pending";
			state.error = null;
			state.data = [];
		},
		[fetchLibraryCards.fulfilled.type]: (state, action) => {
			state.status = "fulfilled";
			state.error = null;
			state.data = action.payload;
		},
		[fetchLibraryCards.rejected.type]: (state, action) => {
			state.status = "rejected";
			state.error = action.error.message;
			state.data = [];
		},
	}
});

export default LibraryCardsSlice.reducer;