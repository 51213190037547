import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
	selectedCard: {
		opacity: 0.5,
		position: 'relative'
	},
	cardBox: {
		cursor: 'pointer',
		position: 'relative'
	},
	checkCircleIcon: {
		backgroundColor: theme.palette.auxiliary.success,
		height: 60,
		width: 60,
		borderRadius: '50%',
		display: 'flex',
	    justifyContent: 'center',
	    alignItems: 'center',
		position: 'absolute',
		top: 0,
		bottom: 0,
		left: 0,
		right: 0,
		margin: 'auto',

		'& img': {
			width: 28,
			height: 28
		}
	}
}));

export { useStyles };