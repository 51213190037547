import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
	tableComponent: {
		overflowX: 'auto',
		backgroundColor: theme.palette.background.main,

		[theme.breakpoints.down("md")]: {
			width: '84vw',
			margin: '0 auto'
		}
	},
	table: {
		border: `1px solid ${theme.palette.border.main}`,
		borderRadius: '3px'
	}
}));

export { useStyles };