import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../../shared/axios-client';

export const fetchDashboardCards = createAsyncThunk(
	'dashboardCards/fetch',
	async () => {
		// TODO dashboard endpoint
		const response1 = await axios.get(`generated_cards/23`);
		const response2 = await axios.get(`generated_cards/33`);
		const response3 = await axios.get(`generated_cards/40`);
		const response4 = await axios.get(`generated_cards/43`);

		const data = {
			completeCollection: [response1.data.data, response2.data.data, response3.data.data, response4.data.data], 
			dashboardMarketplace: [response2.data.data, response4.data.data, response1.data.data, response3.data.data]
		};
		return data;
	}
);

export const DashboardCardsSlice = createSlice({
	name: 'dashboardCards',
	initialState: {
		status: "init",
		error: null,
		data: {}
	},
	reducers: {},
	extraReducers: {
		[fetchDashboardCards.pending.type]: (state, action) => {
			state.status = "pending";
			state.error = null;
			state.data = {};
		},
		[fetchDashboardCards.fulfilled.type]: (state, action) => {
			state.status = "fulfilled";
			state.error = null;
			state.data = action.payload;
		},
		[fetchDashboardCards.rejected.type]: (state, action) => {
			state.status = "rejected";
			state.error = action.error.message;
			state.data = {};
		},
	}
});

export default DashboardCardsSlice.reducer;