import CoinImg from '../../../../assets/img/coin.svg';
import { useStyles } from './CoinIconStyle';

const CoinIcon = () => {
	const classes = useStyles();
	const isSafari = navigator.userAgent.toLowerCase().match(/(ipad|iphone)/);
	
	return <img src={CoinImg} className={classes.coinImg} style={{ marginTop: isSafari ? '-3px' : '0' }} alt="Coin" />;
}

export default CoinIcon;