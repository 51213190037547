import { Link } from 'react-router-dom';
import ErrorImg from '../../../../assets/img/error.svg';
import ButtonPrimary from '../../Buttons/ButtonPrimary/ButtonPrimary';
import ButtonDangerOutline from '../../Buttons/ButtonDangerOutline/ButtonDangerOutline';
import CustomDialog from './../CustomDialog/CustomDialog';
import { useStyles } from './BuyErrorDialogStyle';

const BuyErrorDialog = (props) => {
	const classes = useStyles();

	return (
		<CustomDialog
			title="Error" 
			maxWidth="md"
			open={props.open}
			handleClose={props.handleClose}>
				<div className={classes.buyErrorDialog}>
					<br />
					<img src={ErrorImg} alt="Error" className={classes.errorIcon} />
					<p className={classes.description}>There are no funds in your account, please top up your wallet and try again</p>
					<br />
					<ButtonDangerOutline className={classes.cancelButton} onClick={props.handleClose}>CANCEL</ButtonDangerOutline>
					<ButtonPrimary component={Link} to="/buy-credit">BUY BALANCE</ButtonPrimary>
				</div>
		</CustomDialog>
	);
}

export default BuyErrorDialog;