import PageHeader from './../UI/PageHeader/PageHeader';
import { ReactComponent as CardsIcon } from '../../assets/img/card.svg';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { fetchYourPacks } from './../../store/slices/packs/YourPacksSlice';
import { Grid, CircularProgress } from '@material-ui/core';
import YourPackBox from './YourPackBox/YourPackBox';

const YourPacks = props => {
	const dispatch = useDispatch();
	const yourPacksStatus = useSelector(state => state.yourPacks.status);
	const yourPacks = useSelector(state => state.yourPacks.data);

	useEffect(() => {
		dispatch(fetchYourPacks());
	}, [dispatch]);

	return (
		<>
			<PageHeader title="YOUR PACKS" icon={<CardsIcon />} />
			<br />
			<Grid container item xs={12} alignItems="center" justifyContent="center" spacing={2}>
				{(yourPacksStatus === 'init' || yourPacksStatus === 'pending') && <div className="text-align-center"><CircularProgress /></div>}
				{(yourPacksStatus === 'fulfilled' || yourPacksStatus === 'rejected') && (!yourPacks?.length ? 
					<p className="text-align-center">No data :(</p> : 
					yourPacks.map(pack => (
						<Grid item container xs={12} sm={6} md key={pack.id}>
							<YourPackBox pack={pack} />
						</Grid>))
					)}
			</Grid>
		</>
	);
};

export default YourPacks;