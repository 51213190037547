import { Hidden } from "@material-ui/core";
import clsx from "clsx";
import HeaderBreadcrumbs from './HeaderBreadcrumbs/HeaderBreadcrumbs';
import { useStyles } from "./PageHeaderStyle";

const PageHeader = (props) => {
    const classes = useStyles();

    return (
        <header className={clsx(classes.pageHeader, classes.displayFlex, classes.flexGrow, props.className)}>
            <div className={classes.icon}>{props.icon}</div>
            <h3 className={classes.title}>{props.title}</h3>
            <Hidden mdUp>
                <div className={classes.flexGrow}></div>
            </Hidden>
            {props.children}
            <Hidden smDown>
                <div className={classes.flexGrow}></div>
                <HeaderBreadcrumbs />
            </Hidden>
        </header>
    );
}

export default PageHeader;