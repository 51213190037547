import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
	deleteAccountBox: {
		padding: theme.spacing(2),
		fontSize: 18,
		color: theme.palette.text.trueWhite,
		fontWeight: 300,
		letterSpacing: 0.15,
		lineHeight: '26px'
	},
	deleteAccountTitle: {
		marginTop: 0,
		fontWeight: 'bold',
		lineHeight: '22px'
	},
}));

export { useStyles };