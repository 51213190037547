import { Grid } from '@material-ui/core';
import ButtonYellow from './../../UI/Buttons/ButtonYellow/ButtonYellow';
import clsx from 'clsx';
import { useStyles } from './UserProfileWithdrawStyle';
import WalletGreenIcon from '../../UI/Icons/WalletGreenIcon/WalletGreenIcon';
import { useSelector } from 'react-redux';

const UserProfileWithdraw = props => {
	const classes = useStyles();
	const user = useSelector((state) => state.user.data);

	return (
		<Grid container spacing={2} className={classes.withdrawBox}>
			<Grid container item xs={12} md={6} lg={4}>
				<Grid item xs={12} className={clsx("panel-fill", classes.withdrawInfoBox)}>
					<div className="display-flex align-items-center">
						<div className={classes.walletIcon}>
							<WalletGreenIcon />
						</div>
						<p>{Number(user?.userWallet?.cash || 0).toFixed(2)} PLN</p>
					</div>
					<div className="flex-grow-full" />
					<ButtonYellow>WITHDRAW</ButtonYellow>
				</Grid>
			</Grid>
			<Grid container item xs={12} md={6} lg={8}>
				<Grid item xs={12} className={classes.withdrawWarningBox}>
					<p>
						You can withdraw funds to your bank account. Make sure your email address is correct to accept a special confirmation link. The
						<span className={classes.warningText}> minimum withdrawal amount is 500 PLN</span>
					</p>
				</Grid>
			</Grid>
		</Grid>
	);
};

export default UserProfileWithdraw;