import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../../shared/axios-client';

export const fetchLibraryYourCards = createAsyncThunk(
	'libraryYourCards/fetch',
	async ({organizationId, collectionId}) => {
		const response = await axios.get('generated_cards', { params: { 
			'byOrganisationIds[]': organizationId,
			'byCardCollectionIds[]': collectionId,
			'byOwnerIds[]': '3', // TODO logged user id
			view: 'full_nested',
			limit: 100 // TODO paginacja
		}});
		return response.data.data;
	}
);

export const LibraryYourCardsSlice = createSlice({
	name: 'libraryYourCards',
	initialState: {
		status: "init",
		error: null,
		data: [],
		selectedCard: ''
	},
	reducers: {
		setSelectedCard: (state, action) => {
			state.selectedCard = action.payload
		}
	},
	extraReducers: {
		[fetchLibraryYourCards.pending.type]: (state, action) => {
			state.status = "pending";
			state.error = null;
			state.data = [];
		},
		[fetchLibraryYourCards.fulfilled.type]: (state, action) => {
			state.status = "fulfilled";
			state.error = null;
			state.data = action.payload;
		},
		[fetchLibraryYourCards.rejected.type]: (state, action) => {
			state.status = "rejected";
			state.error = action.error.message;
			state.data = [];
		},
	}
});

export const { setSelectedCard } = LibraryYourCardsSlice.actions;

export default LibraryYourCardsSlice.reducer;