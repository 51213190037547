import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
	buyErrorDialog: {
		textAlign: 'center',
		padding: theme.spacing(3),
		fontFamily: 'Klavika',
		fontSize: '24px',
		color: theme.palette.auxiliary.danger,

        '& p': {
            marginTop: 0
        }
	},
	errorIcon: {
		width: '80px',
		height: '80px',
	},
	cancelButton: {
		marginRight: theme.spacing(2),

		[theme.breakpoints.down("xs")]: {
			marginBottom: theme.spacing(2)
		}
	}
}));

export { useStyles };