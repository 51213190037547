import { Grid, Hidden, useMediaQuery, useTheme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import EllipseImg from '../../assets/img/ellipse.webp';
import { ReactComponent as LogoIcon } from '../../assets/img/logo.svg';
import AnimatedCard from '../../components/AnimatedCard/AnimatedCard';

const useStyles = makeStyles((theme) => ({
	aboutGame: {
		maxWidth: 1410,
		margin: '0 auto',

		[theme.breakpoints.down('md')]: {
			padding: theme.spacing(2),
		},
		[theme.breakpoints.up('md')]: {
			height: 'calc(100vh - 100px)'
		}
	},
	logo: {
		width: 80,
		height: 85,
	},
	title: {
		fontSize: 48,
		fontWeight: 800,
		marginTop: 20,
		marginBottom: 35,

		[theme.breakpoints.down('md')]: {
			fontSize: 30,
			marginBottom: 20
		}
	},
	description: {
		fontSize: 16,
		letterSpacing: '0.15px',
		color: '#DCDDDD',
		lineHeight: '28px'
	},
	descriptionBox: {
		zIndex: 2,
		
		[theme.breakpoints.up('md')]: {
			maxWidth: 470
		},
		'@media (min-width: 350px) and (max-width: 400px)': {
			marginTop: -60,
		},
		'@media (min-width: 640px) and (max-width: 959px)': {
			marginTop: -120,
		}
	},
	ellipseImg: {
		position: 'relative',
		width: '100%',
		height: '100%',

		[theme.breakpoints.up('md')]: {
			maxHeight: 'calc(100vh - 100px)',
			width: 'auto'
		},
		[theme.breakpoints.down('md')]: {
			background: 'radial-gradient(50% 50% at 50% 50%, rgba(18, 22, 36, 0) 0%, #2C5B70 100%)',
			filter: 'blur(150px)'
		}
	},
	cardsBox: {
		position: 'absolute',
		top: 0,

		[theme.breakpoints.only('xs')]: {
			'& .animated-card': {
				transform:' scale(0.6)'
			}
		},
		'@media (min-width: 401px) and (max-width: 600px)': {
			'& .animated-card': {
				transform:' scale(0.8)'
			}
		},
	},
	card_0: {
		transform: 'scale(0.3) perspective(300px) rotateY(40deg)',
		top: 100,
		left: 100,

		[theme.breakpoints.only('xs')]: {
			top: 15,
			left: -50
		},
		[theme.breakpoints.only('xl')]: {
			top: 180,
			left: 80
		},
	},
	card_1: {
	    transform: 'perspective(300px) rotateY(-20deg) rotateZ(-8deg)',
		top: 100,
		left: 200,
		zIndex: 10,
		
		[theme.breakpoints.only('xs')]: {
			top: 0,
			left: 20
		},
		'@media (min-width: 401px) and (max-width: 600px)': {
			top: 20,
			left: 35
		},
		[theme.breakpoints.only('xl')]: {
			top: 180,
			left: 220
		}
	},
	card_2: {
		transform: 'scale(0.4) rotate(15deg)',
		left: 270,
		
		[theme.breakpoints.only('xs')]: {
			top: -70,
			left: 50
		},
		'@media (min-width: 401px) and (max-width: 600px)': {
			top: -60,
			left: 90
		},
		[theme.breakpoints.only('xl')]: {
			left: 300
		}
	},
	card_3: {
		transform: 'scale(0.6) perspective(400px) rotateY(-30deg) rotate(20deg)',
		left: 385,
		top: 30,
		
		[theme.breakpoints.only('xs')]: {
			top: -50,
			left: 120
		},
		'@media (min-width: 401px) and (max-width: 600px)': {
			top: -30,
			left: 170
		},
		[theme.breakpoints.only('xl')]: {
			left: 420,
			top: 80
		}
	},
	card_4: {
		transform: 'scale(0.6) perspective(400px) rotateY(45deg) rotate(20deg)',
		left: 500,
		top: 120,
		
		[theme.breakpoints.only('xs')]: {
			top: -20,
			left: 170
		},
		'@media (min-width: 350px) and (max-width: 400px)': {
			left: 190,
		},
		'@media (min-width: 401px) and (max-width: 600px)': {
			top: 30,
			left: 240
		},
		[theme.breakpoints.only('xl')]: {
			left: 550,
			top: 180
		}
	},
	card_5: {
		transform: 'scale(0.65) perspective(400px) rotateY(-20deg) rotate(120deg)',
		left: 450,
		top: 250,

		[theme.breakpoints.only('xs')]: {
			top: 60,
			left: 150
		},
		'@media (min-width: 350px) and (max-width: 400px)': {
			left: 180,
			top: 70
		},
		'@media (min-width: 401px) and (max-width: 600px)': {
			top: 140,
			left: 200
		},
		[theme.breakpoints.only('xl')]: {
			left: 530,
			top: 400
		}
	},
	card_6: {
		transform: 'scale(0.3) rotate(-20deg)',
		left: 300,
		top: 200,

		[theme.breakpoints.only('xs')]: {
			top: 55,
			left: 65
		},
		'@media (min-width: 350px) and (max-width: 400px)': {
			left: 80,
		},
		'@media (min-width: 401px) and (max-width: 600px)': {
			top: 120,
			left: 100
		},
		[theme.breakpoints.only('xl')]: {
			left: 350,
			top: 340
		}
	},
	card_7: {
		transform: 'scale(0.35) perspective(300px) rotate(-20deg) rotateX(15deg) rotateY(-21deg)',
		left: 380,
		top: 335,
		
		[theme.breakpoints.only('xs')]: {
			top: 90,
			left: 110
		},
		'@media (min-width: 350px) and (max-width: 400px)': {
			top: 100,
		},
		'@media (min-width: 401px) and (max-width: 600px)': {
			top: 200,
			left: 140
		},
		[theme.breakpoints.only('xl')]: {
			left: 420,
			top: 480
		}
	},
	card_8: {
		transform: 'scale(0.6) perspective(400px) rotateY(-20deg) rotateX(-15deg) rotate(-60deg)',
		left: 250,
		top: 300,
		
		[theme.breakpoints.only('xs')]: {
			top: 100,
			left: 50
		},
		'@media (min-width: 350px) and (max-width: 400px)': {
			left: 40,
			top: 120
		},
		'@media (min-width: 401px) and (max-width: 600px)': {
			top: 200,
			left: 60
		},
		[theme.breakpoints.only('xl')]: {
			top: 460
		}
	},
	card_9: {
		transform: 'scale(0.45) rotate(60deg)',
		left: 100,
		top: 280,
		
		[theme.breakpoints.only('xs')]: {
			top: 80,
			left: -40
		},
		'@media (min-width: 401px) and (max-width: 600px)': {
			top: 150,
			left: -20
		},
		[theme.breakpoints.only('xl')]: {
			left: 80,
			top: 380
		}
	}
}));

const AboutGame = () => {
	const classes = useStyles();
	const theme = useTheme();
	const isXlWidth = useMediaQuery(theme.breakpoints.only('xl'));
	const cards = useSelector(state => state.landingPageCards.data);

	const descriptionBox = (
		<div className={classes.descriptionBox} data-aos="fade-right">
			<p className={classes.description}>
				Have you ever dreamed of owning your own esports team? Now you can! Collect the best stars, build your roster and battle in realistic match simulations.
			</p>
			<p className={classes.description}>
				Rank up through the core season circuit and play in tournaments that coincide with big events in the scene. Get to the top of the leaderboards to win awesome prizes!
			</p>
		</div>
	);

	return (
		<Grid container className={classes.aboutGame}>
			<Grid item xs={12} md={5} className="margin-auto">
				<LogoIcon className={classes.logo} />
				<p className={classes.title} data-aos="fade-right">What is<br />MartialCards</p>
				<Hidden smDown>
					{descriptionBox}
				</Hidden>
			</Grid>
			<Grid item xs={12} md={7} className="margin-auto">
				<div className="position-relative text-align-center">
					<img src={EllipseImg} className={classes.ellipseImg} alt="Ellipse" />
					<Grid container item className={classes.cardsBox} justifyContent="center">
						{cards.map((card, i) => <div 
							key={card.id} 
							className={clsx(classes[`card_${i}`], 'position-absolute')}>
								<AnimatedCard 
									card={card?.card} 
									size={isXlWidth ? "sm" : "xs"} 
									noShadow />
							</div>
						)}
					</Grid>
				</div>
			</Grid>
			<Hidden mdUp>
				{descriptionBox}
			</Hidden>
		</Grid>
	)
}

export default AboutGame;