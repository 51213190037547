import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../../shared/axios-client';

export const fetchPack = createAsyncThunk(
	'pack/fetch',
	async ({packId}) => {
		const response = await axios.get(`generated_booster_packs/${packId}`);
		return response.data.data;
	}
);

export const PackSlice = createSlice({
	name: 'pack',
	initialState: {
		status: "init",
		error: null,
		data: {},
	},
	reducers: {},
	extraReducers: {
		[fetchPack.pending.type]: (state, action) => {
			state.status = "pending";
			state.error = null;
			state.data = {};
		},
		[fetchPack.fulfilled.type]: (state, action) => {
			state.status = "fulfilled";
			state.error = null;
			state.data = action.payload;
		},
		[fetchPack.rejected.type]: (state, action) => {
			state.status = "rejected";
			state.error = action.error.message;
			state.data = {};
		},
	}
});

export default PackSlice.reducer;