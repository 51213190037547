import { useStyles } from './BuyCardDialogContentStyle';
import ButtonLightOnOutlined from './../../../UI/Buttons/ButtonLightOnOutlined/ButtonLightOnOutlined';
import ButtonPrimary from '../../../UI/Buttons/ButtonPrimary/ButtonPrimary';
import AnimatedCard from './../../../AnimatedCard/AnimatedCard';

const BuyCardDialogContent = (props) => {
	const classes = useStyles();

	return (
		<div className={classes.buyCardDialog}>
			{/* <img src={props.img} alt="Card img" className={classes.cardImg} /> */}
			<AnimatedCard card={props.card} size="sm" />
			<p className={classes.question}>Are you sure you want to buy this item?</p>
			<p className={classes.description}>The amount will be debited from your balance:</p>
			<p>{Number(props.price).toFixed(2)} PLN</p>
			<ButtonLightOnOutlined className={classes.cancelButton} onClick={props.onClose}>CANCEL</ButtonLightOnOutlined>
			<ButtonPrimary onClick={props.handleBuyButtonClick}>BUY NOW</ButtonPrimary>
		</div>
	);
}

export default BuyCardDialogContent;