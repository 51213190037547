import { Dialog, IconButton, Toolbar, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { useStyles } from './CustomDialogStyle';

const CustomDialog = (props) => {
    const classes = useStyles();

    return (
        <Dialog fullWidth maxWidth={props.maxWidth} open={props.open} onClose={props.handleClose} className={classes.dialog}>
			<Toolbar className={classes.appBar}>
				<Typography variant="h6" className={classes.title}>{props.title}</Typography>
				<div className="flex-grow-full"></div>
				<IconButton edge="start" color="inherit" onClick={props.handleClose} aria-label="close" size="small">
					<CloseIcon className={classes.closeButton} />
				</IconButton>
			</Toolbar>
			<div className={classes.dialogContent}>
				{props.children}
			</div>
		</Dialog>
    );
}

export default CustomDialog;