import { makeStyles } from '@material-ui/core/styles';
import HoloBgImg from '../../assets/img/card/holo-bg.webp';
import RarityImg from '../../assets/img/card/05-rarity.svg';
import AbilityImg from '../../assets/img/card/04-ability.svg';
import { getRarityColor } from '../../utils/rarityColor';

const useStyles = makeStyles((theme) => ({
	animatedCard: {
		color: theme.palette.text.trueWhite,
		textAlign: 'center',

		'& .atropos-banner .atropos-shadow': {
			opacity: 0.2
		},
		'& .atropos-active .atropos-shadow': {
			opacity: '0.2 !important'
		},
		'& .atropos-shadow': {
			filter: 'blur(100px)',
			background: props => getRarityColor(props.card?.cardType?.name)
		},
	},
	holoBg: {
		zIndex: '2 !important',
		mixBlendMode: 'hard-light',
		opacity: 0.75,
	},
	frameImg: {
		zIndex: '2 !important'
	},
	logoBox: {
		position: 'absolute',
		left: '5%',
		zIndex: 9,
		display: 'flex',
	    flexDirection: 'column'
	},
	numberBox: {
		position: 'absolute',
		zIndex: 9,
		color: props => String(props.card?.cardType?.name).toUpperCase() === 'LEGENDARY' ? '#dea449' : theme.palette.text.trueWhite 
	},
	holoNumberBox: {
		color: 'black',
		borderRadius: '10px',
		backgroundImage: `url(${HoloBgImg})`,
		backgroundPosition: '55% 55%',
		filter: 'brightness(1.75) contrast(2)'
	},
	number: {
		fontFamily: 'Saira Black',
		margin: 0,
		WebkitTextStrokeWidth: '1px',
		color: props => String(props.card?.cardType?.name).toUpperCase() === 'SPECIAL' ? '#568296' : '',
		WebkitTextFillColor: 'transparent',
		// WebkitTextFillColor: props => props.card?.rarity !== 'SPECIAL' ? 'transparent' : '',
		// background: props => props.card?.rarity === 'SPECIAL' ? 'linear-gradient(to right, #568296, #4f959d)' : '',
		// WebkitBackgroundClip: props => props.card?.rarity === 'SPECIAL' ? 'text' : '',
		// WebkitTextStroke: props => props.card?.rarity === 'SPECIAL' ? '3px transparent' : '',
	},
	numberCategory: {
		fontFamily: 'Saira',
		marginTop: props => String(props.card?.cardType?.name).toUpperCase() === 'EXCLUSIVE' ? 0 : '-10px',
		textTransform: 'uppercase',
		color: props => String(props.card?.cardType?.name).toUpperCase() === 'SPECIAL' ? '#26395a' : '' 
	},
	nameBox: {
		position: 'absolute',
		zIndex: 9,
		left: 0,
		width: '100%',
	},
	nickname: {
		position: 'absolute',
		top: 0,
		right: 0,
		zIndex: -1,
		marginTop: '-5px',
		width: '100%'
	},
	name: {
		height: '100%',
		width: '80% !important',
		margin: 'auto',
	},
	nameHolo: {
		WebkitMask: props => `url(${props.card?.nameImage?.url}) 0% 0% / contain no-repeat`,
		mask: props => `url(${props.card?.nameImage?.url}) 0% 0% / contain no-repeat`,
		width: 250,
		height: 60,
		margin: 'auto',
		filter: 'brightness(1.75) contrast(2)',
		backgroundImage: `url(${HoloBgImg})`,
		backgroundSize: '500%',
		backgroundPosition: '55% 55%'
	},
	infoBox: {
		position: 'absolute',
		left: 0,
		right: 0,
		marginLeft: 'auto',
		marginRight: 'auto',
		zIndex: 9,
	},
	ability: {
		fontFamily: 'Saira',
		fontWeight: 'bold',
	},
	rarityHolo: {
		WebkitMask: `url(${RarityImg}) no-repeat 50% 50%`,
		mask: `url(${RarityImg}) no-repeat 50% 50%`,
		WebkitMaskSize: 'cover',
		MaskSize: 'cover',
		width: 40,
		height: 10,
		display: 'inline-block',
		marginLeft: 5,
		filter: 'brightness(1.75) contrast(2)',
		backgroundImage: `url(${HoloBgImg})`,
		backgroundSize: '500%',
		backgroundPosition: '55% 55%'
	},
	abilityHolo: {
		WebkitMask: `url(${AbilityImg}) no-repeat 50% 50%`,
		mask: `url(${AbilityImg}) no-repeat 50% 50%`,
		WebkitMaskSize: 'cover',
		MaskSize: 'cover',
		width: 40,
		height: 10,
		display: 'inline-block',
		marginRight: 5,
		filter: 'brightness(1.75) contrast(2)',
		backgroundImage: `url(${HoloBgImg})`,
		backgroundSize: '500%',
		backgroundPosition: '55% 55%'
	},
	bio: {
		fontFamily: 'Saira Black',
		color: props => props?.bioColor
	},
	starImg: {
		fill: props => props?.starsColor
	},
	description: {
		fontFamily: 'Saira Semibold',
	},
	smallTitle: {
		fontFamily: 'Saira Medium',
		margin: 0,
		color: props => props.smallTitleColor,
		background: props => props.smallTitleLinearGradientColor,
		WebkitBackgroundClip: props => props.smallTitleLinearGradientColor ? 'text' : '',
		WebkitTextFillColor: props => props.smallTitleLinearGradientColor ? 'transparent' : '',
	},
	value: {
		fontFamily: 'Saira',
		fontWeight: 'bold',
		margin: 'auto',
	},
	valueHolo: {
		WebkitBackgroundClip: 'text',
		WebkitTextFillColor: 'transparent',
    	filter: 'brightness(1.75) contrast(2)',
		backgroundImage: `url(${HoloBgImg})`,
		backgroundSize: '500%',
		backgroundPosition: '55% 55%'
	},
	divider: {
		border: props => `0.25px solid ` + props.dividerColor + ` !important`,
		backgroundColor: props => props.dividerColor
	},
	footer: {
		fontFamily: 'Saira Semibold',
		position: 'absolute',
		left: 0,
		right: 0,
		marginLeft: 'auto',
		marginRight: 'auto',
		zIndex: 9,
		textAlign: 'center'
	}
}));

export { useStyles };