import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
	avatar: {
		border: `10px solid ${theme.palette.auxiliary.grey}`,
		width: 180,
		height: 180,
		margin: 'auto',
		marginTop: theme.spacing(3),
		marginBottom: theme.spacing(1),
	},
	editProfileTextBox: {
		color: theme.palette.text.lightOn,
		fontFamily: 'Montserrat',
		letterSpacing: '0.4px',
		fontSize: 12,
		fontWeight: 'bold',
		marginBottom: theme.spacing(2)
	},
	formBox: {
		padding: theme.spacing(2),
        paddingTop: theme.spacing(3)
	},
	saveButtonBox: {
		marginTop: theme.spacing(2),
		
		[theme.breakpoints.down("sm")]: {
			'& button': {
				width: '100%'
			}
		},
		[theme.breakpoints.up("md")]: {
			display: 'flex',
			justifyContent: 'flex-end'
		}
	}
}));

export { useStyles };