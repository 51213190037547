import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    tabs: {
        marginTop: theme.spacing(2),
        borderBottom: `1px solid ${theme.palette.border.main}`,

        '& .MuiTabs-flexContainer': {
            justifyContent: 'center'
        },
        '& .MuiTab-root': {
            textTransform: 'none',
            fontSize: 16,
            lineHeight: '19px',
            color: theme.palette.text.lightOn
        },
        '& .Mui-selected': {
            color: theme.palette.text.trueWhite
        },
        '& .MuiTabs-indicator': {
            backgroundColor: theme.palette.primary.main,
            height: 1
        }
    }
}));

export { useStyles };