import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
	box: {
		width: '100%',

		[theme.breakpoints.up("sm")]: {
			height: 263,
		}
	},
	collectionInfoBox: {
		borderTop: `1px solid ${theme.palette.border.main}`,
		borderBottom: `1px solid ${theme.palette.border.main}`,
		position: 'relative',
		overflowY: 'hidden',
		zIndex: 1,
		
		[theme.breakpoints.only("xs")]: {
			display: 'block',
			textAlign: 'center',
		},
		[theme.breakpoints.up("sm")]: {
			height: 220,
			padding: theme.spacing(3),
		}
	},
	backgroundImg: {
		position: 'absolute',
		left: 0,
		top: 0,
		width: '100%',
		opacity: 0.2,
		zIndex: 0,

		[theme.breakpoints.up("lg")]: {
			top: -80, // TODO delete it
		}
	},
	linearGradient: {
		background: `linear-gradient(0deg, #070A14 14.35%, rgba(7, 10, 20, 0) 120.85%)`,
		position: 'absolute',
		left: 0,
		top: 0,
		height: '100%',
		width: '100%',
	},
	collectionInfo: {
		marginTop: 'auto',
		marginBottom: theme.spacing(1),
		zIndex: 1,

		[theme.breakpoints.only("xs")]: {
			position: 'relative'
		}
	},
	name: {
		fontSize: 30,
		marginBottom: theme.spacing(1),

		[theme.breakpoints.only("xs")]: {
			fontSize: 24
		}
	},
	cardAmount: {
		color: theme.palette.text.lightOn,
		display: 'flex',
		alignItems: 'center',
		
		[theme.breakpoints.only("xs")]: {
			justifyContent: 'center'
		},
		'& svg': {
			fill: theme.palette.primary.main,
			height: 17,
			width: 17,
			marginRight: theme.spacing(1)
		}
	},
	myCardsAmount: {
		color: theme.palette.text.trueWhite,
	},
	circularProgresBox: {
		marginTop: 'auto',
		marginBottom: theme.spacing(1),
		marginRight: theme.spacing(1),
		zIndex: 1
	},
	circularProgres: {
		background: theme.palette.background.panel,
		borderRadius: '50%',
		padding: 14,
		border: `1px solid ${theme.palette.border.main}`,
		height: 84,
		width: 84,

		[theme.breakpoints.only("xs")]: {
			margin: 'auto'
		}
	},
	bottomInfo: {
		height: 50,
		borderBottom: `1px solid ${theme.palette.border.main}`,
		background: theme.palette.background.panel,
		display: 'flex',
		alignItems: 'center',
		padding: theme.spacing(3),
		textTransform: 'uppercase',

		[theme.breakpoints.only("xs")]: {
			justifyContent: 'center'
		}
	},
	divider: {
        height: 24,
        margin: '15px !important',
        border: `0.25px solid ${theme.palette.text.trueWhite} !important`,
        opacity: 0.2,
    },
	collectionText: {
		color: theme.palette.text.lightOn
	},
	collectionPercentage: {
		color: theme.palette.primary.main,
		marginLeft: theme.spacing(1)
	}
}));

export { useStyles };