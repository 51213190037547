import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
	walletGreenImg: {
		width: '17px',
		height: '17px',
		verticalAlign: 'middle'
	}
}));

export { useStyles };