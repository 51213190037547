import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
	coinImg: {
		width: '17px !important',
		height: '17px !important',
		verticalAlign: 'middle'
	}
}));

export { useStyles };