import { Grid, Hidden, Tab, Tabs } from '@material-ui/core';
import { useEffect, useRef, useState } from 'react';
import { ReactComponent as SettingsIcon } from '../../assets/img/settings.svg';
import PageHeader from "../UI/PageHeader/PageHeader";
import SectionHeader from "../UI/SectionHeader/SectionHeader";
import UserProfileBasicInfo from './UserProfileBasicInfo/UserProfileBasicInfo';
import UserProfileConnectAccount from './UserProfileConnectAccount/UserProfileConnectAccount';
import UserProfileDeleteAccount from './UserProfileDeleteAccount/UserProfileDeleteAccount';
import { useStyles } from './UserProfileStyle';
import UserProfileWithdraw from './UserProfileWithdraw/UserProfileWithdraw';

const UserProfile = () => {
	const classes = useStyles();
	const { hash } = window.location;
	const [tabsValue, setTabsValue] = useState(null);
	const tabRef = useRef(null);

	useEffect(() => {
		setTimeout(() => {
			if (hash) {
				const id = hash.replace('#', '');
				const element = document.getElementById(id);
				if (element) {
					element.scrollIntoView({ block: 'start', behavior: 'instant' });
					window.scrollBy(0, -95);
					setTabsValue(null);
				}
			}
		}, 0);
	}, [hash]);

	useEffect(() => {
		if (tabRef && tabRef.current) {
			window.scrollTo({ top: tabRef.current.offsetTop - 95, behavior: "smooth" });
		}
	}, [tabsValue]);

	return (
		<>
			<PageHeader title="SETTINGS PROFILE" icon={<SettingsIcon />} />
			<Hidden smDown>
				<Tabs value={tabsValue} onChange={(e, value) => setTabsValue(value)} aria-label="nav tabs example" className={classes.tabs}>
					<Tab label="Basic information" value={null} />
					<Tab label="Connect account" value='connect-account' />
					<Tab label="Change password" disabled />
					<Tab label="Delete account" value='delete-account' />
				</Tabs>
			</Hidden>
			<Grid item xs={12} id="basic-info" ref={null}>
				<SectionHeader title="BASIC INFORMATION" subtitle="Basic information about your account" />
			</Grid>
			<UserProfileBasicInfo />
			<br />
			<Grid item xs={12} id="withdraw">
				<SectionHeader title="WITHDRAW" subtitle="You can withdraw the earned funds" />
			</Grid>
			<UserProfileWithdraw />
			<br />
			<Grid item xs={12} id="connect-account" ref={tabsValue === 'connect-account' ? tabRef : null}>
				<SectionHeader title="CONNECT ACCOUNT" subtitle="Link your account with other internet portals" />
			</Grid>
			<UserProfileConnectAccount />
			<br />
			<Grid item xs={12} id="delete-account" ref={tabsValue === 'delete-account' ? tabRef : null}>
				<SectionHeader title="DELETE ACCOUNT" subtitle="Are you sure you want to delete the account?" />
			</Grid>
			<UserProfileDeleteAccount />
		</>
	);
}
  
export default UserProfile;