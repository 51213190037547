import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
	organizationBox: {
		border: `1px solid ${theme.palette.border.main}`,
		borderLeft: `3px solid ${theme.palette.background.panel}`,
		height: 120,
		paddingLeft: theme.spacing(2),
		position: 'relative',

		'&:hover': {
			borderLeft: props => !props.onlyDisplay && `3px solid ${theme.palette.primary.main}`,
			background: props => !props.onlyDisplay && 'linear-gradient(90.36deg, rgba(0, 231, 174, 0.1) 0.29%, rgba(196, 196, 196, 0) 98.86%)',
			cursor: props => props.onlyDisplay ? 'default' : 'pointer'
		}
	},
	backgroundImg: {
		position: 'absolute',
		left: 0,
		top: 0,
		opacity: 0.3,
		objectFit: 'cover',
	    width: '100%',
    	height: 120,
		mixBlendMode: 'luminosity',
		filter: props => !props.organization && 'blur(10px)'
	},
	imgGradient: {
		background: 'radial-gradient(52.1% 53.23% at 88.89% 11.45%, rgba(255, 255, 255, 0.065) 0%, rgba(255, 255, 255, 0) 98.1%), linear-gradient(90deg, #0C1121 0%, rgba(12, 17, 33, 0.2) 93.93%)',
		position: 'absolute',
		left: 0,
		top: 0,
	    width: '100%',
    	height: 120,
	},
	imgBox: {
		border: `1px solid ${theme.palette.border.panel}`,
		background: theme.palette.background.panel,
		width: 80,
		height: 80,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		position: 'relative',
		opacity: props => !props.organization && 0.5
	},
	organizationImg: {
		width: 53,
		height: 53
	},
	description: {
		fontSize: 13,
		color: theme.palette.text.lightOn,
		marginTop: 0,
		position: 'relative'
	},
	name: {
		marginBottom: 0,
		color: props => props.organization ? theme.palette.text.trueWhite : theme.palette.text.lightOn,
		position: 'relative'
	},
	cardAmount: {
		background: theme.palette.background.main,
		color: theme.palette.text.lightOn,
		position: 'absolute',
		bottom: 0,
		right: 0,
		padding: theme.spacing(1),
		paddingLeft: theme.spacing(2),
		borderTopLeftRadius: 20,
		display: 'flex',
		alignItems: 'center',

		'& svg': {
			fill: theme.palette.primary.main,
			height: 17,
			width: 17,
			marginRight: theme.spacing(1)
		}
	},
	myCardsAmount: {
		color: theme.palette.text.trueWhite,
	}
}));

export { useStyles };