import { Divider, Grid } from '@material-ui/core';
import Atropos from 'atropos/react/atropos-react.esm';
import clsx from 'clsx';
import { useState } from 'react';
import MartialCardsLogoImg from '../../assets/img/card/02-MC-logo.svg';
import PunchDownLogoImg from '../../assets/img/card/03-PD4-logo.png';
import { ReactComponent as AbilityImg } from '../../assets/img/card/04-ability.svg';
import { ReactComponent as RarityImg } from '../../assets/img/card/05-rarity.svg';
import { ReactComponent as StarImg } from '../../assets/img/card/06-star_full.svg';
import { ReactComponent as AbilityLegendaryImg } from '../../assets/img/card/ability-LEGENDARY.svg';
import { ReactComponent as AbilitySpecialImg } from '../../assets/img/card/ability-SPECIAL.svg';
import CardBgCommon from '../../assets/img/card/card-bg-COMMON.webp';
import CardBgLegendary from '../../assets/img/card/card-bg-LEGENDARY.webp';
import CardBgRare from '../../assets/img/card/card-bg-RARE.webp';
import CardBgSpecial from '../../assets/img/card/card-bg-SPECIAL.webp';
import CardBgSuperrare from '../../assets/img/card/card-bg-SUPERRARE.webp';
import CardBgUncommon from '../../assets/img/card/card-bg-UNCOMMON.webp';
import CardFrameCommon from '../../assets/img/card/card-frame-COMMON.webp';
import CardFrameExclusive from '../../assets/img/card/card-frame-EXCLUSIVE.webp';
import CardFrameLegendary from '../../assets/img/card/card-frame-LEGENDARY.webp';
import CardFrameRare from '../../assets/img/card/card-frame-RARE.webp';
import CardFrameSpecial from '../../assets/img/card/card-frame-SPECIAL.webp';
import CardFrameSuperrare from '../../assets/img/card/card-frame-SUPERRARE.webp';
import CardFrameUncommon from '../../assets/img/card/card-frame-UNCOMMON.webp';
import RareCommonImg from '../../assets/img/card/category-COMMON.svg';
import RareExclusiveImg from '../../assets/img/card/category-EXCLUSIVE.svg';
import RareLegendaryImg from '../../assets/img/card/category-LEGENDARY.svg';
import RareRareImg from '../../assets/img/card/category-RARE.svg';
import RareSpecialImg from '../../assets/img/card/category-SPECIAL.svg';
import RareSuperrareImg from '../../assets/img/card/category-SUPERRARE.svg';
import RareUncommonImg from '../../assets/img/card/category-UNCOMMON.svg';
import HoloBgImg from '../../assets/img/card/holo-bg.webp';
import MartialCardsLogoSpecialImg from '../../assets/img/card/MC-logo_SPECIAL.svg';
import PunchDownLogoSpecialImg from '../../assets/img/card/PD4-logo-SPECIAL.png';
import PunchDownLogoLegendaryImg from '../../assets/img/card/PD4-logo-LEGENDARY.png';
import { ReactComponent as RarityLegendaryImg } from '../../assets/img/card/rarity-LEGENDARY.svg';
import { ReactComponent as RaritySpecialImg } from '../../assets/img/card/rarity-SPECIAL.svg';
import './AnimatedCard.scss';
import { useStyles as useLGStyles } from './AnimatedCardLgStyle';
import { useStyles as useDefaultStyles } from './AnimatedCardStyle';

const getRarityColors = rarity => {
	switch (String(rarity).toUpperCase()) {
		case 'COMMON':
			return {
				bioColor: '#30fa08',
				starsColor: '#ffffff',
				smallTitleColor: '#30fa08',
				dividerColor: '#30fa08'
			};
		case 'EXCLUSIVE':
			return {
				bioColor: '#2f308a',
				starsColor: '#30fa08',
				smallTitleColor: '#30fa08',
				dividerColor: '#e51467'
			};
		case 'LEGENDARY':
			return {
				bioColor: '#ffffff',
				starsColor: '#fcd800',
				smallTitleLinearGradientColor: '-webkit-linear-gradient(#a96700, #dea449)',
				dividerColor: '#fcd800'
			};
		case 'RARE':
			return {
				bioColor: '#ffffff',
				starsColor: '#e51467',
				smallTitleColor: '#30fa08',
				dividerColor: '#4748af'
			};
		case 'SPECIAL':
			return {
				bioColor: '#78bfcd',
				starsColor: '#26395a',
				smallTitleColor: '#26395a',
				dividerColor: '#30fa08'
			};
		case 'SUPER RARE':
			return {
				bioColor: '#ffffff',
				starsColor: '#e51467',
				smallTitleColor: '#30fa08',
				dividerColor: '#e51467'
			};
		case 'UNCOMMON':
			return {
				bioColor: '#ffffff',
				starsColor: '#ffffff',
				smallTitleColor: '#fcd800',
				dividerColor: '#30fa08'
			};
		default: break;
	}
};

const AnimatedCard = props => {
	const rarity = String(props.card?.cardType?.name).toUpperCase();
	const classes = useDefaultStyles({...props, ...getRarityColors(rarity)});
	const sizedClasses = useLGStyles(props);
	const [mousePosition, setMousePosition] = useState();
	let scale = 1;
	let CardBgImg;
	let CardFrameImg;
	let RareImg;
	let starsAmount = 1;
	let stars = [];
	let rarityImg;
	let abilityImg;

	switch (props.size) {
		case "xs": 
			scale = 0.55;
			break;
		case "sm": 
			scale = 0.7;
			break;
		case "lg": 
			scale = 1;
			break;
		default:
			scale = 1;
			break;
	};

	switch (rarity) {
		case 'COMMON':
			CardBgImg = CardBgCommon;
			CardFrameImg = CardFrameCommon;
			RareImg = RareCommonImg;
			starsAmount = 1;
			break;
		case 'EXCLUSIVE':
			CardBgImg = HoloBgImg;
			CardFrameImg = CardFrameExclusive;
			RareImg = RareExclusiveImg;
			starsAmount = 5;
			break;
		case 'LEGENDARY':
			CardBgImg = CardBgLegendary;
			CardFrameImg = CardFrameLegendary;
			RareImg = RareLegendaryImg;
			starsAmount = 6;
			break;
		case 'RARE':
			CardBgImg = CardBgRare;
			CardFrameImg = CardFrameRare;
			RareImg = RareRareImg;
			starsAmount = 3;
			break;
		case 'SPECIAL':
			CardBgImg = CardBgSpecial;
			CardFrameImg = CardFrameSpecial;
			RareImg = RareSpecialImg;
			starsAmount = 7;
			break;
		case 'SUPER RARE':
			CardBgImg = CardBgSuperrare;
			CardFrameImg = CardFrameSuperrare;
			RareImg = RareSuperrareImg;
			starsAmount = 4;
			break;
		case 'UNCOMMON':
			CardBgImg = CardBgUncommon;
			CardFrameImg = CardFrameUncommon;
			RareImg = RareUncommonImg;
			starsAmount = 2;
			break;
		default:
			break;
	};

	for (let i = 0; i < starsAmount; i++) {
		stars.push(<StarImg key={i} className={clsx(classes.starImg, sizedClasses.starImg)} />);						
	}

	switch (rarity) {
		case 'LEGENDARY':
			rarityImg = <RarityLegendaryImg />;
			abilityImg = <AbilityLegendaryImg />;
			break;
		case 'EXCLUSIVE':
			rarityImg =  <div 
				className={clsx(classes.rarityHolo, sizedClasses.rarityHolo)}
				style={{ backgroundPosition: mousePosition }} />;
			abilityImg = <div 
				className={clsx(classes.abilityHolo, sizedClasses.abilityHolo)}
				style={{ backgroundPosition: mousePosition }} />;
			break;
		case 'SPECIAL':
			rarityImg =  <RaritySpecialImg />;
			abilityImg = <AbilitySpecialImg />;
			break;
		default:
			rarityImg =  <RarityImg />;
			abilityImg = <AbilityImg />
			break;
	}

	const onMouseMove = e => {
		if (!props.noRotate) {
			setMousePosition(`${55 + (e.nativeEvent.x/100)}% ${55 + (e.nativeEvent.y/100)}%`);
		}
	}

	const onTouchMove = e => {
		if (!props.noRotate) {
			setMousePosition(`${55 + (e.touches[0].screenX/100)}% ${55 + (e.touches[0].screenY/100)}%`);
		}
	}
	
	return (
		props.card && <div 
			className={clsx("margin-auto animated-card", classes.animatedCard, sizedClasses.animatedCard)} 
			onMouseMove={onMouseMove} onTouchMove={onTouchMove}>
			<Atropos className="atropos-banner" highlight={false} shadow={!props.noShadow} rotate={!props.noRotate}
			style={{ transform: `scale(${scale})`, transformOrigin: 'top' }}>
				<img className={clsx("atropos-banner-spacer", sizedClasses.cardImg)} src={CardBgImg} alt="Card" />
				{rarity === 'EXCLUSIVE' && 
					<div data-atropos-offset="-3" 
						className={clsx("card-img", sizedClasses.cardImg, classes.holoBg, sizedClasses.fighterImg)} 
						style={{ background: '#f6dbb6' }}>
						<img 
							className={clsx(
								"card-img", 
								sizedClasses.cardImg,
								sizedClasses.fighterImg)}
							style={{ opacity: 0.75, marginTop: 0}}
							src={CardBgImg} 
							alt="Card Bg" />
					</div>
				}
				{rarity !== 'EXCLUSIVE' &&
					<img 
						data-atropos-offset="-1" 
						className={clsx("card-img", sizedClasses.cardImg)}
						src={CardBgImg} 
						alt="Card Bg" />
				}
				<img 
					data-atropos-offset="1" 
					className={clsx(
						"card-img",
						sizedClasses.cardImg,
						sizedClasses.fighterImg)} 
					src={props.card?.playerImage?.webp?.url} 
					alt="Card Fighter" />
				<img 
					data-atropos-offset="-1" 
					className={clsx(
						"card-img", 
						classes.frameImg,
						sizedClasses.cardImg)} 
					src={CardFrameImg} 
					alt="Card Frame" />
				<div className={clsx(classes.logoBox, sizedClasses.logoBox)} data-atropos-offset="4">
					<img 
						src={rarity === 'SPECIAL' ? MartialCardsLogoSpecialImg : MartialCardsLogoImg} 
						className={sizedClasses.mdLogo} 
						alt="Martial Cards logo" 
						data-atropos-offset="4" />
					<img 
						src={
							rarity === 'SPECIAL' ? PunchDownLogoSpecialImg : 
							rarity === 'LEGENDARY' ? PunchDownLogoLegendaryImg : PunchDownLogoImg} 
						className={sizedClasses.pd4Logo} 
						alt="PD4 logo" 
						data-atropos-offset="4" />
				</div>
				<div className={clsx(classes.numberBox, sizedClasses.numberBox)} data-atropos-offset="3">
					<div 
						className={rarity === 'EXCLUSIVE' ? clsx(classes.holoNumberBox, sizedClasses.holoNumberBox) : ''} 
						style={{ backgroundPosition: mousePosition }}>
						<p className={clsx(classes.number, sizedClasses.number)}>{props.card?.number || '00'}</p>
					</div>
					<p className={clsx(classes.numberCategory, sizedClasses.numberCategory)}>{props.card?.cardSkill?.name}</p>
				</div>
				<div className={clsx(classes.nameBox, sizedClasses.nameBox)}>
					<img src={props.card?.nicknameImage?.url} alt="Nickname" className={classes.nickname} data-atropos-offset="3" />
					{rarity === 'EXCLUSIVE' ? 
						<div 
							className={clsx(classes.nameHolo, sizedClasses.nameHolo)} 
							data-atropos-offset="5"
							style={{ backgroundPosition: mousePosition }} />
						:
						<img src={props.card?.nameImage?.url} alt="Name" className={clsx(classes.name, sizedClasses.name)} data-atropos-offset="5" />
					}
				</div>
				<div className={clsx(classes.infoBox, sizedClasses.infoBox)} data-atropos-offset="2">
					<Grid container item xs={12} alignItems="center" className={sizedClasses.bioBox}>
						<Grid item xs={5} className={clsx("display-flex align-items-center", sizedClasses.bioBox)}>
							{abilityImg}
							<p className={clsx(classes.ability, sizedClasses.ability)}>{props.card?.ability}</p>
							</Grid>
						<Grid item xs={2} className={clsx("display-flex align-items-center justify-content-center", sizedClasses.bioBox)}>
							<p className={clsx(classes.bio, sizedClasses.bio)}>BIO</p>
						</Grid>
						<Grid item xs={5} className={clsx("display-flex align-items-center justify-content-end", sizedClasses.bioBox)}>
							{stars}
							{rarityImg}
						</Grid>
					</Grid>
					<p className={clsx(classes.description, sizedClasses.description)}>{props.card?.description}</p>
					<div className={clsx("display-flex justify-content-center", sizedClasses.bottomNumbers)}>
						<div>
							<p className={clsx(classes.smallTitle, sizedClasses.smallTitle)}>POWER</p>
							<p 
								className={clsx(
									classes.value, 
									sizedClasses.value, 
									rarity === 'EXCLUSIVE' ? classes.valueHolo : ''
								)} 
								data-atropos-offset="3"
								style={{ backgroundPosition: rarity === 'EXCLUSIVE' ? mousePosition : '' }}
							>{props.card?.power}</p>
						</div>
						<Divider className={clsx(classes.divider, sizedClasses.divider)} />
						<div>
							<p className={clsx(classes.smallTitle, sizedClasses.smallTitle)}>ATTACK</p>
							<p 
								className={clsx(
									classes.value, 
									sizedClasses.value, 
									rarity === 'EXCLUSIVE' ? classes.valueHolo : ''
								)} 
								data-atropos-offset="3"
								style={{ backgroundPosition: rarity === 'EXCLUSIVE' ? mousePosition : '' }}
							>{props.card?.attack}</p>
						</div>
						<div className="flex-grow-full" />
						<img src={RareImg} alt="Rare img" className={sizedClasses.rareImg} />
						<div className="flex-grow-full" />
						<div>
							<p className={clsx(classes.smallTitle, sizedClasses.smallTitle)}>DEFENSE</p>
							<p 
								className={clsx(
									classes.value, 
									sizedClasses.value, 
									rarity === 'EXCLUSIVE' ? classes.valueHolo : ''
								)} 
								data-atropos-offset="3"
								style={{ backgroundPosition: rarity === 'EXCLUSIVE' ? mousePosition : '' }}
							>{props.card?.defence}</p>
						</div>
						<Divider className={clsx(classes.divider, sizedClasses.divider)} />
						<div>
							<p className={clsx(classes.smallTitle, sizedClasses.smallTitle)}>SPEED</p>
							<p 
								className={clsx(
									classes.value, 
									sizedClasses.value, 
									rarity === 'EXCLUSIVE' ? classes.valueHolo : ''
								)} 
								data-atropos-offset="3"
								style={{ backgroundPosition: rarity === 'EXCLUSIVE' ? mousePosition : '' }}
							>{props.card?.speed}</p>
						</div>
					</div>
				</div>
				<p 
					className={clsx(classes.footer, sizedClasses.footer)} 
					data-atropos-offset="-1"
					>2021 &#8212; CARD DESIGN &#8212; Peanut Loop</p>
			</Atropos>
		</div>
	);
};

export default AnimatedCard;