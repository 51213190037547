import { Divider, Grid } from '@material-ui/core';
import Countdown from 'react-countdown';
import { useStyles } from './TimeCountDownStyle';

const TimeCountdown = () => {
	const classes = useStyles();

	const renderer = ({ days, hours, minutes, seconds, completed }) => {
		return (
			<Grid item>
				<Grid container>
					<Grid item>
						<div className={classes.fontCount}>{days}</div>
						<div className={classes.fontDescription}>DAY</div>
					</Grid>
					<Grid item>
						<Divider orientation="vertical" className={classes.divider} flexItem />
					</Grid>
					<Grid item>
						<div className={classes.fontCount}>{hours}</div>
						<div className={classes.fontDescription}>HOUR</div>
					</Grid>
					<Grid item>
						<Divider orientation="vertical" className={classes.divider} flexItem />
					</Grid>
					<Grid item>
						<div className={classes.fontCount}>{minutes}</div>
						<div className={classes.fontDescription}>MIN</div>
					</Grid>
					<Grid item>
						<Divider orientation="vertical" className={classes.divider} flexItem />
					</Grid>
					<Grid item>
						<div className={classes.fontCount}>{seconds}</div>
						<div className={classes.fontDescription}>SEC</div>
					</Grid>
				</Grid>
			</Grid>
		);
	};

	return <Countdown date={'2021-10-23T00:00:00'} renderer={renderer} />;
}

export default TimeCountdown;