import { makeStyles } from '@material-ui/core/styles';
import OpenPackBg from '../../../assets/img/open-pack-bg.svg';
import OpenPackCardsBg from '../../../assets/img/open-pack-cards-bg.svg';

const useStyles = makeStyles((theme) => ({
	openPackBox: {
		backgroundRepeat: 'no-repeat',
		backgroundSize: 'cover',
		margin: `-${theme.spacing(3)}px`,
		minHeight: `calc(100vh - 85px)`,
		maxWidth: 'calc(100vw - 123px)',
		width: 'calc(100vw - 123px)',
		flexGrow: 1,
		fontFamily: 'Klavika',
		
		[theme.breakpoints.down("sm")]: {
			minHeight: `calc(100vh - 60px)`,
			maxWidth: '100vw',
			width: '100vw',			
		}
	},
	packBackground: {
		backgroundImage: `url(${OpenPackBg}), linear-gradient(180deg, #070A14 0%, #142048 100%)`,
		backgroundPosition: 'center',
	},
	cardsBackground: {
		backgroundImage: `url(${OpenPackCardsBg})`,
		backgroundAttachment: 'fixed',
		backgroundPosition: 'fixed',
		padding: theme.spacing(3)
	},
	name: {
		fontSize: '48px',
		marginBottom: 0
	},
	packImg: {
		height: '400px',
		padding: theme.spacing(3),
		borderRadius: '30px',
		animation: 'shake 0.8s infinite'
	},
	closeButton: {
		marginTop: theme.spacing(2),
		marginBottom: theme.spacing(2),
		background: theme.palette.border.main,
		border: `1px solid ${theme.palette.icons.main}`,

		'&:hover': {
			background: theme.palette.border.main,
			border: `1px solid ${theme.palette.icons.main}`
		}
	},
	cardName: {
		fontSize: '18px',
		margin: 0,
		textTransform: 'uppercase'
	},
	rarity: {
		fontSize: '12px',
		textTransform: 'uppercase'
	},
	collection: {
		fontSize: '12px',
		color: theme.palette.text.lightOn
	},
	questIcon: {
		marginRight: theme.spacing(1),
		fill: theme.palette.primary.main,
	},
	questBox: {
		margin: theme.spacing(2),
		fontSize: '18px',

		'& span': {
			color: theme.palette.auxiliary.warning
		}
	},
	cardsButton: {
		margin: theme.spacing(1),
		minWidth: '350px',

		[theme.breakpoints.down("sm")]: {
			margin: `${theme.spacing(1)}px 0`,
			width: '100%',
			minWidth: '100%'
		}
	}
}));

export { useStyles };