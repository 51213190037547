import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
	lightOnOutlined: {
		borderRadius: '3px',
		height: '44px',
		textTransform: 'none',
		fontWeight: 600,
		fontSize: '13px',
		lineHeight: '16px',
		fontFamily: 'Montserrat',
		color: theme.palette.text.lightOn,
		border: `1px solid ${theme.palette.text.lightOn}`,

		'&:hover': {
			border: `1px solid ${theme.palette.text.lightOn}`,
		},
	}
}));

export { useStyles };