import { Box, CircularProgress, Typography } from "@material-ui/core";
import { useStyles } from "./CircleProgressBarStyle";

const CircleProgressBar = props => {
    const classes = useStyles(props);

    return (
        <Box position="relative" display="inline-flex" className={classes.circularProgres}>
			<CircularProgress 
                variant="determinate" 
                className={classes.circleBottom} 
                size={props.size} 
                value={100} 
                thickness={props.thickness} />
			<CircularProgress 
                variant="determinate" 
                className={classes.circleTop} 
                size={props.size} 
                value={props.value} 
                thickness={props.thickness} />
			<Box
				top={0}
				left={0}
				bottom={0}
				right={0}
				position="absolute"
				display="flex"
				alignItems="center"
				justifyContent="center"
			>
				<Typography variant="caption" component="div">
					{`${Math.round(props.value)}%`}
				</Typography>
			</Box>
		</Box>
    );
}

export default CircleProgressBar;