import { Button } from '@material-ui/core';
import { useState } from 'react';
import CoinsImg from '../../../assets/img/coins.svg';
import { ReactComponent as StoreIcon } from '../../../assets/img/shopping-bag.svg';
import BadgeGreen from './../../UI/Badges/BadgeGreen/BadgeGreen';
import { useStyles } from './ProductStyle';
import BuyProductDialogContent from '../BuyProductDialogContent/BuyProductDialogContent';
import CustomDialog from './../../UI/Dialogs/CustomDialog/CustomDialog';

const Product = (props) => {
	const classes = useStyles();

	const [openBuyProductDialog, setOpenBuyProductDialog] = useState(false);

	return (
		<div className={classes.productBox}>
			<div className={classes.product}>
				<BadgeGreen className={classes.bedgeGreen}>BESTSELLER</BadgeGreen>
				<br /><br />
				<img src={CoinsImg} alt="Coins" className={classes.coinsImg} />
				<p className={classes.name}>4000 Martial Coins</p>
				<p className={classes.price}>45.00 PLN</p>
			</div>
			<Button fullWidth className={classes.buyNow} onClick={() => setOpenBuyProductDialog(true)}>
				<StoreIcon /><p>BUY NOW</p>
			</Button>
			<CustomDialog
				title="Confirmation" 
				maxWidth="md"
				open={openBuyProductDialog}
				handleClose={() => setOpenBuyProductDialog(false)}>
				<BuyProductDialogContent onClose={() => setOpenBuyProductDialog(false)} />
			</CustomDialog>
		</div>
	);
}

export default Product;