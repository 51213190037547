import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
	buttonDanger: {
		borderRadius: '3px',
		height: '44px',
		textTransform: 'none',
		color: theme.palette.auxiliary.danger,
		fontWeight: 600,
		fontSize: '13px',
		lineHeight: '16px',
		border: `1px solid ${theme.palette.auxiliary.danger}`,
		fontFamily: 'Montserrat'
	}
}));

export { useStyles };