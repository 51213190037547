import { CircularProgress, Grid, Hidden } from "@material-ui/core";
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchDashboardCards } from "../../store/slices/dashboard/DashboardCardsSlice";
import CompleteCollectionBox from './CompleteCollectionBox/CompleteCollectionBox';
import DiamondPackBox from './DiamondPackBox/DiamondPackBox';
import EventBox from './EventBox/EventBox';
import FreePackBox from './FreePackBox/FreePackBox';
import MarketplaceBox from './MarketplaceBox/MarketplaceBox';
import SliderBox from "./SliderBox/SliderBox";
import UserBox from './UserBox/UserBox';

const Dashboard = () => {
	const dispatch = useDispatch();
	const dashboardCardsStatus = useSelector((state) => state.dashboardCards.status);

	useEffect(() => {
		if (dashboardCardsStatus === 'init') {
			dispatch(fetchDashboardCards());
		}
	}, [dashboardCardsStatus, dispatch]);

	return (
		<Grid container spacing={2}>
			<Grid item xs={12}>
				<EventBox />
			</Grid>
			<Grid item xs={12} lg={3}>
				<UserBox />
				<Hidden xsDown>
					<SliderBox />
				</Hidden>
			</Grid>
			<Grid item xs={12} lg={9}>
				<Grid container item xs={12}>
					<Grid item xs={12} sm={6}>
						<FreePackBox />
					</Grid>
					<Grid item xs={12} sm={6}>
						<DiamondPackBox />
					</Grid>
					{(dashboardCardsStatus === 'init' || dashboardCardsStatus === 'pending') && <div className="text-align-center"><CircularProgress /></div>}
					{dashboardCardsStatus === 'fulfilled' && (
						<>
							<Grid item xs={12}>
								<CompleteCollectionBox />
							</Grid>
							<Grid item xs={12}>
								<MarketplaceBox />
							</Grid>
						</>
					)}
				</Grid>
			</Grid>
		</Grid>
	);
}
  
export default Dashboard;