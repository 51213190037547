import { Grid } from "@material-ui/core";
import { useSelector } from 'react-redux';
import CardBox from "../../CardBox/CardBox";
import SectionHeader from "../../UI/SectionHeader/SectionHeader";
import { useStyles } from "./CompleteCollectionBoxStyle";

const CompleteCollectionBox = () => {
	const classes = useStyles();

	const completeCollectionCards = useSelector((state) => state.dashboardCards.data?.completeCollection);

	return (
		<>
			<Grid item xs={12}>
				<SectionHeader title="COMPLETE YOUR COLLECTION" subtitle="You are close to a complete collection" />
			</Grid>
			{completeCollectionCards?.length && (
				<div className={classes.cardsBox}>
					{completeCollectionCards.map(card => (
						<CardBox 
							key={card.id}
							card={card}
							tag={card.tag} />
					))}
				</div>)
			}
		</>
	);
}

export default CompleteCollectionBox;