import { CircularProgress, Grid } from '@material-ui/core';
import { useSelector } from 'react-redux';
import CardBox from './../../../CardBox/CardBox';
import { useStyles } from './AllYourCardsViewStyle';
import DoneImg from '../../../../assets/img/done.svg';

const AllYourCardsView = props => {
	const classes = useStyles();
	const allYourCardsStatus = useSelector((state) => state.allYourCards.status);
	const allYourCards = useSelector((state) => state.allYourCards.data);

	const checkIfCardIsSelected = card => {
		return props.selectedCards.some(selectedCard => selectedCard.id === card.id);
	};

	const handleCardClick = card => {
		const clickedCard = { ...card, sellQuantity: 1, sellPrice: '' };
		if (checkIfCardIsSelected(clickedCard)) {
			const filteredSelectedCards = props.selectedCards.filter(selectedCard => selectedCard.id !== clickedCard.id);
			props.setSelectedCards(filteredSelectedCards);
		} else {
			props.setSelectedCards([...props.selectedCards, clickedCard]);
		}
	};

	return (
		<Grid container item xs={12} justifyContent="center">
			{(allYourCardsStatus === 'init' || allYourCardsStatus === 'pending') && <div className="text-align-center"><CircularProgress /></div>}
			{(allYourCardsStatus === 'fulfilled' || allYourCardsStatus === 'rejected') && (!allYourCards?.length ? 
				<p className="text-align-center">No data :(</p> : 
				allYourCards.map(card => (
					<div key={card.id} className={classes.cardBox} onClick={() => handleCardClick(card)}>
						<CardBox 
							card={card}
							shortInfo 
							minWidth={190}
							cardSize="xs"
							quantity={card?.quantity}
							className={checkIfCardIsSelected(card) && classes.selectedCard}>
						</CardBox>
						{checkIfCardIsSelected(card) && (
							<div className={classes.checkCircleIcon}>
								<img src={DoneImg} alt="Done" />
							</div>
						)}
					</div>
				))	
			)}
		</Grid>
	);
}

export default AllYourCardsView;