import { Avatar, Dialog, Slide, Typography } from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import IconButton from "@material-ui/core/IconButton";
import Toolbar from "@material-ui/core/Toolbar";
import CloseIcon from '@material-ui/icons/Close';
import MenuIcon from "@material-ui/icons/Menu";
import clsx from 'clsx';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, NavLink } from "react-router-dom";
import logo from '../../../../assets/img/logo.svg';
import ButtonPrimary from "../../Buttons/ButtonPrimary/ButtonPrimary";
import MenuItems from "../NavbarSharedElements/MenuItems/MenuItems";
import NotificationsButton from "../NavbarSharedElements/NotificationsButton/NotificationsButton";
import UserMenuItems from "../NavbarSharedElements/UserMenuItems/UserMenuItems";
import CoinIcon from './../../Icons/CoinIcon/CoinIcon';
import WalletGreenIcon from './../../Icons/WalletGreenIcon/WalletGreenIcon';
import { useStyles } from "./NavbarMobileStyle";

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

const NavbarMobile = () => {
	const classes = useStyles();

	const user = useSelector((state) => state.user.data);

	const [openMenu, setOpenMenu] = useState(false);
	const [openUserMenu, setOpenUserMenu] = useState(false);

	const handleOpenMenu = () => {
		setOpenMenu(true);
	};
  
	const handleCloseMenu = () => {
		setOpenMenu(false);
	};

	const handleOpenUserMenu = () => {
		setOpenUserMenu(true);
	};
	
	const handleCloseUserMenu = () => {
		setOpenUserMenu(false);
	};

	return (
		<AppBar position="fixed">
			<Toolbar className={classes.appBar}>
				<NavLink to="/">
					<img src={logo} className={classes.logo} alt="logo" />
				</NavLink>
				<div className={classes.flexGrow}></div>
				<NavLink to="/notifications">
					<NotificationsButton className={classes.notificationsButton} />
				</NavLink>
				<Avatar src={user?.avatar?.url} className={classes.avatar} alt="Avatar" onClick={handleOpenUserMenu} />
				<Dialog fullScreen open={openUserMenu} onClose={handleCloseUserMenu} TransitionComponent={Transition}>
					<AppBar className={classes.appBar}>
						<Toolbar>
							<Typography variant="h6" className={classes.title}>PROFILE MENU</Typography>
							<div className={classes.flexGrow}></div>
							<IconButton edge="start" color="inherit" onClick={handleCloseUserMenu} aria-label="close">
								<CloseIcon className={classes.menuButton} />
							</IconButton>
						</Toolbar>
					</AppBar>
					<div className={classes.dialogContent}>
						<div className={clsx(classes.displayFlex, classes.alignItemsCenter)}>
							<NavLink to="/user-profile" onClick={handleCloseUserMenu} className={classes.navLink}>
								<Avatar src={user?.avatar?.url} className={classes.avatar} alt="Avatar" />
								<p className={clsx(classes.avatarName, classes.fontWhite)}>{user?.nickname}</p>
							</NavLink>
							<div className={classes.flexGrow}></div>
							<WalletGreenIcon />
							<div className={classes.walletInfo}>
								<p className={clsx(classes.fontWhite, classes.walletAmount)}>&nbsp;{Number(user?.userWallet?.cash || 0).toFixed(2)} PLN</p>
								<Link to="/buy-credit" className={classes.walletLink} onClick={handleCloseUserMenu}>BUY BALANCE</Link>
							</div>
						</div>
						<div className={clsx(classes.displayFlex, classes.fontWhite, classes.buyBalance, classes.alignItemsCenter)}>
							<CoinIcon />&nbsp;
							<p>{user?.userWallet?.coins || 0}</p>
							<div className={classes.flexGrow}></div>
							<ButtonPrimary onClick={handleCloseUserMenu} component={Link} to="/buy-credit">Buy balance</ButtonPrimary>
						</div>
						<UserMenuItems handleUserMenuClose={handleCloseUserMenu} />
					</div>
				</Dialog>
				<IconButton color="inherit" aria-label="open menu" onClick={handleOpenMenu} className={classes.menuButton}>
					<MenuIcon />
				</IconButton>
				<Dialog fullScreen open={openMenu} onClose={handleCloseMenu} TransitionComponent={Transition}>
					<AppBar className={classes.appBar}>
						<Toolbar>
							<Typography variant="h6" className={classes.title}>MENU</Typography>
							<div className={classes.flexGrow}></div>
							<IconButton edge="start" color="inherit" onClick={handleCloseMenu} aria-label="close">
								<CloseIcon className={classes.menuButton} />
							</IconButton>
						</Toolbar>
					</AppBar>
					<div className={classes.dialogContent}>
						<MenuItems isMobile handleCloseMenu={handleCloseMenu} />
					</div>
				</Dialog>
			</Toolbar>
		</AppBar>
	);
}

export default NavbarMobile;