import { Grid } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { ReactComponent as BackpackIcon } from '../../assets/img/backpack.svg';
import { fetchLibraryCards } from '../../store/slices/library/LibraryCardsSlice';
import { fetchLibraryYourCards } from '../../store/slices/library/LibraryYourCardsSlice';
import { fetchCardOffers } from './../../store/slices/card-details/CardOffersSlice';
import { fetchLibraryCollections } from './../../store/slices/library/LibraryCollectionsSlice';
import { fetchLibraryOrganizations } from './../../store/slices/library/LibraryOrganizationsSlice';
import ButtonPrimary from './../UI/Buttons/ButtonPrimary/ButtonPrimary';
import PageHeader from './../UI/PageHeader/PageHeader';
import CardsView from './CardsView/CardsView';
import CardView from './CardView/CardView';
import LibrarySlider from './LibrarySlider/LibrarySlider';
import { useStyles } from './LibraryStyle';

const Library = () => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const organizationsStatus = useSelector((state) => state.libraryOrganizations.status);
	const selectedOrganization = useSelector(state => state.libraryOrganizations.selectedOrganization);
	const collectionsStatus = useSelector((state) => state.libraryCollections.status);
	const collections = useSelector(state => state.libraryCollections.data);
	const selectedCollection = useSelector(state => state.libraryCollections.selectedCollection);
	const selectedCard = useSelector(state => state.libraryYourCards.selectedCard);
	const cardOffers = useSelector(state => state.cardOffers.data);
	const [collectionsPerOrganization, setCollectionsPerOrganization] = useState([]);
	
	useEffect(() => {
		if (organizationsStatus === 'init') {
			dispatch(fetchLibraryOrganizations());
		}
	}, [organizationsStatus, dispatch]);
	
	useEffect(() => {
		if (collectionsStatus === 'init') {
			dispatch(fetchLibraryCollections());
		}
	}, [collectionsStatus, dispatch]);

	useEffect(() => {
		if (selectedOrganization?.id) {
			const filteredCollections = collections.filter(collection => collection.organisation?.id === selectedOrganization.id);
			setCollectionsPerOrganization(filteredCollections);
		};
	}, [selectedOrganization, collections]);

	useEffect(() => {
		if (selectedOrganization && selectedCollection && !selectedCard) {
			dispatch(fetchLibraryYourCards({
				organizationId: selectedOrganization?.id, 
				collectionId: selectedCollection?.id, 
			}));
			dispatch(fetchLibraryCards({
				organizationId: selectedOrganization?.id, 
				collectionId: selectedCollection?.id, 
			}));
		}
	}, [selectedOrganization, selectedCollection, selectedCard, dispatch]);

	useEffect(() => {
		if (selectedCard) {
			dispatch(fetchCardOffers({ cardId: selectedCard.id }));
		}
	}, [selectedCard, dispatch]);

	return (
		<>
			<PageHeader title="LIBRARY" icon={<BackpackIcon />} className={classes.headerFixed}>
				<ButtonPrimary className={classes.showMyCardsButton} component={Link} to="/library/your-items">SHOW MY CARDS</ButtonPrimary>
			</PageHeader>
			<Grid container item className={classes.library}>
				<LibrarySlider collectionsPerOrganization={collectionsPerOrganization} />
				{selectedOrganization && selectedCollection && !selectedCard && (
					<CardsView 
						organizationName={selectedOrganization.name}
						collection={selectedCollection} />
				)}
				{selectedCard && (
					<CardView 
						card={selectedCard}
						cardOffers={cardOffers} />
				)}
			</Grid>
		</>
	);
}

export default Library;