import { Grid } from "@material-ui/core";
import clsx from "clsx";
import { Link } from "react-router-dom";
import CardsImg from '../../../assets/img/cards.svg';
import ButtonPrimary from "../../UI/Buttons/ButtonPrimary/ButtonPrimary";
import TimeCountdown from "../../UI/TimeCountdown/TimeCountdown";
import { useStyles } from "./FreePackBoxStyle";

const FreePackBox = () => {
	const classes = useStyles();

	return (
        <Grid item className={clsx(classes.freePackBox, classes.cardsBackground)} xs={12}>
			<Grid item container spacing={2} className={classes.columnReverse}>
				<Grid item xs={12} lg={6}>
					<h3 className={classes.title}>IEM Cologne: Contenders</h3>
					<p className={classes.subtitle}>FREE PACK</p>
					<div className={classes.countdown}>
						<TimeCountdown />
					</div>
					<ButtonPrimary component={Link} to={`/open-pack/1`} className={classes.openFreeButton}>OPEN FREE</ButtonPrimary>
					{/* <ButtonPrimary component={Link} to={`/open-pack/${props.packId}`} className={classes.openFreeButton}>OPEN FREE</ButtonPrimary> */}
				</Grid>
				<Grid item xs={12} lg={6} className={classes.cardsBox}>
					<img src={CardsImg} className={classes.cardsImg} alt="Cards" />
				</Grid>
			</Grid>
		</Grid>
	);
}

export default FreePackBox;