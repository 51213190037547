import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
	cardsBox: {
		display: 'flex',
    	overflowX: 'auto',

		[theme.breakpoints.down('md')]: {
			width: '85vw'
		},
		[theme.breakpoints.only('md')]: {
			width: 'calc(95vw - 130px)'
		}
	}
}));

export {useStyles };