import MarketplaceItemBgBigImg from '../../../assets/img/marketplace-item-bg-big.svg';
import MarketplaceItemBgSmallImg from '../../../assets/img/marketplace-item-bg-small.svg';
import LineGreyImg from '../../../assets/img/line-grey.svg';
import { makeStyles } from '@material-ui/core/styles';
import { getRarityColor } from '../../../utils/rarityColor';
// import HoloBgImg from '../../../assets/img/card/holo-bg.webp';

const useStyles = makeStyles((theme) => ({
	halfCircle: {
		width: 400,
		height: 200,
		backgroundColor: props => getRarityColor(props.card?.cardType?.name),
		// backgroundColor: props => props.card?.rarity !== 'EXCLUSIVE' ? props.card?.color : '',
		// backgroundImage: props => props.card?.rarity === 'EXCLUSIVE' ? `url(${HoloBgImg})` : '',
		// backgroundSize: 'cover',
		backgroundBlendMode: 'lighten',
		borderTopLeftRadius: '100% 200%',
		borderTopRightRadius: '100% 200%',
		WebkitBoxSizing: 'border-box',
		MozkitBoxSizing: 'border-box',
		boxSizing: 'border-box',
		display: 'flex',
		alignItems: 'flex-end',

		[theme.breakpoints.up('lg')]: {
			width: props => props.libraryView && 330,
			height: props => props.libraryView && 165,
		}
	},
	marketplaceItemBox: {
		backgroundImage: `url(${MarketplaceItemBgBigImg}), linear-gradient(169.5deg, rgba(10, 15, 29, 0) -33.62%, ${theme.palette.border.panel} 125.58%)`,
		backgroundRepeat: 'no-repeat',
		backgroundSize: '100% 100%',
		backgroundBlendMode: 'luminosity',
		fontFamily: 'Klavika',
		marginTop: `-${theme.spacing(3)}px`,
		marginLeft: `-${theme.spacing(3)}px`,
		marginRight: `-${theme.spacing(3)}px`,

		[theme.breakpoints.down('md')]: {
			backgroundSize: '100% 200px, 100% 401px',
			backgroundImage: `url(${MarketplaceItemBgBigImg}), url(${LineGreyImg}), linear-gradient(169.5deg, rgba(10, 15, 29, 0) -33.62%, ${theme.palette.border.panel} 125.58%)`,
			backgroundRepeat: 'no-repeat',
		},
		[theme.breakpoints.down('xs')]: {
			backgroundImage: `url(${MarketplaceItemBgSmallImg}), url(${LineGreyImg}), linear-gradient(169.5deg, rgba(10, 15, 29, 0) -33.62%, ${theme.palette.border.panel} 125.58%)`,
		}
	},
	titleDescription: {
		fontSize: '14px',
		lineHeight: '17px',
		color: theme.palette.text.lightOn,
	},
	title: {
		fontSize: '18px',
		lineHeight: '22px',
		color: theme.palette.text.trueWhite,
		marginTop: 0,
		textTransform: 'uppercase',

		[theme.breakpoints.down('md')]: {
			margin: `${theme.spacing(2)}px 0`,
			fontSize: '16px',
			textAlign: 'right'
		}
	},
	titleBig: {
		fontSize: 30,
		color: theme.palette.text.trueWhite,
		marginTop: 0,
		
		[theme.breakpoints.up('md')]: {
			fontSize: 35,
			whiteSpace: 'nowrap'
		},
		[theme.breakpoints.up('xl')]: {
			fontSize: 50,
		},
		[theme.breakpoints.down('md')]: {
			textAlign: 'center'
		}
	},
	titleDescriptionBig: {
		[theme.breakpoints.down('md')]: {
			textAlign: 'center'
		}
	},
	cardRarityFont: {
		color: props => getRarityColor(props.card?.cardType?.name)
	},
	marketplaceItem: {
		paddingBottom: 0,

		[theme.breakpoints.up('lg')]: {
			height: 400
		}
	},
	cardInfoBox: {
		[theme.breakpoints.up('lg')]: {
			height: 400,
			alignItems: 'center'
		}
	},
	marketplacePlayerImg: {
		height: 280,
		width: 330,
		objectFit: 'cover',
		objectPosition: 'top',
		marginBottom: '-6px',
		
		[theme.breakpoints.only('xl')]: {
			height: props => !props.libraryView && 300,
			width: props => !props.libraryView && 362,
		}
	},
	bottomInfoBox: {
		background: theme.palette.background.panel,
		borderTop: `1px solid ${theme.palette.border.main}`,
		borderTopLeftRadius: '100px',
		padding: theme.spacing(1),
		paddingLeft: '50px',
		display: 'flex',
		alignItems: 'center',
		position: 'relative',
		zIndex: 2
	},
	cardInfo: {
		padding: theme.spacing(3),

		[theme.breakpoints.up("lg")]: {
			padding: props => props.libraryView ? 0 : theme.spacing(3)
		}
	},
	divider: {
		height: '70%',
		margin: '15px !important',
		border: `0.25px solid ${theme.palette.text.trueWhite} !important`,
		opacity: 0.2,
	},
	dividerHorizontalMobile: {
		width: `calc(100% + ${theme.spacing(3)}px + ${theme.spacing(3)}px)`,
		margin: '0 !important',
		marginLeft: `-${theme.spacing(3)}px !important`,
		border: `0.25px solid ${theme.palette.text.trueWhite} !important`,
		opacity: 0.2,
	},
	badge: {
		[theme.breakpoints.down('md')]: {
			margin: `${theme.spacing(2)}px 0`,
			fontSize: '16px',
			textAlign: 'right'
		}
	},
	cardCollection: {
		fontSize: '13px'
	},
	playerImgBox: {
		height: props => props.libraryView ? 335 : 350
	},
	marketplaceIcon: {
		borderRadius: '50%',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		height: '48px',
		minWidth: '48px',
		marginRight: '10px',
		border: `1px solid ${theme.palette.primary.main}`,

		'& svg': {
			height: '19px',
			width: '19px',
			fill: theme.palette.primary.main
		},
		[theme.breakpoints.down("md")]: {
			margin: theme.spacing(1)
		}
	},
	sellCard: {
		fontSize: 16
	},
	sellCardButton: {
		[theme.breakpoints.only("xl")]: {
			marginRight: theme.spacing(3),
			width: 320
		}
	}
}));

export { useStyles };