const Notifications = () => {
	return (
	  <div>
		<header>
			<h1>Notifications</h1>
		</header>
	  </div>
	);
}
  
export default Notifications;