import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    userMenuListItem: {
        fontFamily: 'Montserrat',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '12px',
        fill: '#626A70',
        '&:hover': {
            color: theme.palette.text.trueWhite,
        },
        '&:hover svg': {
            fill: theme.palette.text.trueWhite
        }
    },
    navLink: {
        display: 'flex',
        color: 'inherit',
        textDecoration: 'none',
        flexGrow: 1
    }
}));

export { useStyles };