import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../../shared/axios-client';

export const fetchCard = createAsyncThunk(
	'card/fetch',
	async ({id}) => {
		const response = await axios.get(`cards/${id}`, { params : { view: 'full_nested' }});
		return response.data.data;
	}
);

export const CardSlice = createSlice({
	name: 'card',
	initialState: {
		status: "init",
		error: null,
		data: {}
	},
	reducers: {},
	extraReducers: {
		[fetchCard.pending.type]: (state, action) => {
			state.status = "pending";
			state.error = null;
			state.data = {};
		},
		[fetchCard.fulfilled.type]: (state, action) => {
			state.status = "fulfilled";
			state.error = null;
			state.data = action.payload;
		},
		[fetchCard.rejected.type]: (state, action) => {
			state.status = "rejected";
			state.error = action.error.message;
			state.data = {};
		}
	}
});

export default CardSlice.reducer;