import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
	buyCardDialog: {
		padding: theme.spacing(3),
		textAlign: 'center',
		color: theme.palette.text.trueWhite,
		fontSize: '24px'
	},
	cancelButton: {
		marginRight: theme.spacing(2),
		
		[theme.breakpoints.down("xs")]: {
			marginBottom: theme.spacing(2)
		}
	},
	question: {
		marginBottom: theme.spacing(1)
	},
	description: {
		fontSize: '16px',
		margin: 0
	},
	cardImg: {
		height: '100%',
		width: '100%',
		maxHeight: '250px',

		[theme.breakpoints.down("xs")]: {
			maxHeight: '200px'
		}
	}
}));

export { useStyles };